import React, { useContext } from 'react';
import MList from '../@material-extend/MList';
import { routeConfig } from '../../configs';
import { MCard } from '../@material-extend';
import CustomerAdminContext from '../../contexts/CustomerAdminContext';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigateTo } from '../../helpers';
import { useAuth0 } from '@auth0/auth0-react';

BusinessSiteList.propTypes = {
  filterItems: PropTypes.func,
}

/**
 * All Business Sites.
 * Business Site Fields:
 * - created_at: "2022-01-31T22:22:10.716192"
 * - site_name: "parent company one - UPDATED"
 * - site_uuid: "ff9121c5-5a81-48bf-b7e6-b96de9737801"
 * - updated_at: "2022-01-31T22:22:43.359807"
 */
export function BusinessSiteList(props) {
  const context = useContext(CustomerAdminContext);
  const [, navigateTo] = useNavigateTo();
  const { getAccessTokenSilently } = useAuth0();

  const handleClickItem = (item) => {
    const params = context.getHierarchyParams(item);
    navigateTo(routeConfig.site_view, params)
  }

  const items = props.filterItems ? context.entities.site.filter(props.filterItems) : context.entities.site;

  return (
    <MCard
      title="Sites"
      // subtitle={`Count: ${items.length}`}
     
    >
      <MList
        dense
        onClick={handleClickItem}
        items={items.map(item => ({
          ...item,
          data: item,
          site_uuid: item.site_uuid,
          site_name: item.site_name,
          business_unit_uuid: item.business_unit_uuid,
          id: item.site_uuid,
          title: item.site_name,
          selected: context.params.site_uuid === item.site_uuid,
          secondaryAction: context.dev_control.show_delete_icon_on_lists && <IconButton
          onClick={async (item) => {
            const token = await getAccessTokenSilently();
            context.deleteEntity(token, item);}}
          children={<DeleteIcon />}
          />,
        }))}
      />
    </MCard>
  );
}