import React from 'react';
import { routeConfig } from '../../../../configs';
import PageSection from '../../../templates/PageSection';
import { Grid } from '@mui/material';
import { MButton, MDivider } from '../../../@material-extend';
import RepresentativeAssignmentList from '../../../lists/RepresentativeAssignmentList';
import { useNavigateTo } from '../../../../helpers';
import Restricted from '../../../Restricted';
import { permissions } from '../../../../configs/permissionConstants';

export default function SiteRepresentativeSection({ isLoading }) {
  const [, navigateTo] = useNavigateTo();

  const handleAddSiteRepresentative = () => {
    navigateTo(routeConfig.representative_assignment_add);
  }

  return (<PageSection
    title="Site Representative"
    justifyContent='center'
    direction={"row"}
    collapsible
    endActions={
      <Restricted to={[permissions.WRITE_REPRESENTATIVE_OPS, permissions.WRITE_REPRESENTATIVE_SALES]}>
        <MButton
          GridProps
          collapseXs
          disabled={isLoading}
          size="small"
          icon="add"
          variant="outlined"
          color="primary"
          onClick={handleAddSiteRepresentative}
          children="Add Representative Assignment"
        />
      </Restricted>
    }
  >
    <Grid
      item
      xs={12}
      md={12}
      container
      direction="column"
      sx={{ px: 1 }}
    >
      <MDivider above={{ title: "Representative Assignment" }} sx={{ mb: 1 }} />
      <RepresentativeAssignmentList />
    </Grid>
  </PageSection>
  );
}