import { Grid } from '@mui/material';
import { Cascader } from 'antd';
import PropTypes from 'prop-types';

/**
 * Cascading options. [See example](https://ant.design/components/cascader/).
 * @param {*} props 
 * @returns 
 */
function ACascader(props) {

    const { options, value, displayRender, onChange, size, placeholder, pageState, ...rest } = props

    function filter(inputValue, path) {
        return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    }

    return (
    <Grid item {...rest}>
        <Cascader
            options={options}
            onChange={onChange}
            style={{ width: "100%" }}
            showSearch={{ filter }}
            size={size}
            value={value}
            displayRender={displayRender}
            changeOnSelect
            expandTrigger="hover"
            placeholder={placeholder} />
    </Grid>);
}

ACascader.propTypes = {
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    value: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func.isRequired,
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
}

export default ACascader;