import { fetchWrapper } from '../helpers/fetchWrapper';

export const defaultSupplier = {
  value: "",
  label: "",
  currency_code: "USD",
  erp_supplier_id: "",
  language_used: "ENGLISH",
  supplier_name: "No Default Supplier",
  supplier_uuid: "",
  third_party: false,
  unit_of_measure: "Gal",
};

export const supplierService = {
  getAll,
  getById,
  create,
  update,
  delete: _delete
};

const baseUrl = `${process.env.REACT_APP_SERVER_URL}/api/supplier`;

function getAll(token) {
  return fetchWrapper.get(token, baseUrl);
}

function getById(token, id) {
  return fetchWrapper.get(token, `${baseUrl}/${id}`);
}

function create(token, params) {
  return fetchWrapper.post(token, baseUrl, params);
}

function update(token, id, params) {
  return fetchWrapper.put(token, `${baseUrl}/${id}`, params);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(token, id) {

  return fetchWrapper.delete(token, `${baseUrl}/${id}`);
} 