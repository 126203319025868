/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import Page from '../../templates/Page';
import { SupplierContext } from '../../../contexts/SupplierContext';
import { routeConfig } from '../../../configs';
import SupplierList from '../../lists/SupplierList';
import { MButton } from '../../@material-extend'
import { useNavigateTo } from '../../../helpers';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Restricted from '../../Restricted';
import { permissions } from '../../../configs/permissionConstants';

export default function Suppliers() {

  const supplierContext = useContext(SupplierContext);
  const [, navigateTo] = useNavigateTo();

  const navigateToSupplier = (supplierUuid) => {
    navigateTo(routeConfig.supplier_edit, { supplier_uuid: supplierUuid })
  };

  const navigateToAddSupplier = () => {
    navigateTo(routeConfig.supplier_add);
  }

  useEffect(() => {
    supplierContext.queryHandler.fetch();
  }, []);

  return (
    <Page title="All Suppliers"
      onBack={() => navigateTo(routeConfig.dashboard)}
      endActions={
        <Restricted to={[permissions.WRITE_SUPPLIER_DETAILS]}>
          <MButton
            startIcon={<AddCircleIcon />}
            variant="contained"
            onClick={navigateToAddSupplier}
            children="Add Supplier"
          />
        </Restricted>
      }
    >
      <SupplierList
        navigateToSupplier={navigateToSupplier}
        suppliers={supplierContext.entities}
      />
    </Page>
  );
}
