import { useEffect, useContext, useState } from 'react';
import { useForm } from "react-hook-form";
import { MForm } from '../../@material-extend';
import { LayoutContext, useSupplierContractContext, LookupContext } from '../../../contexts';
import { dateHelper } from '../../../helpers';
import { Backdrop, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { MFormTextField, MFormSelect, MFormDatePicker } from '../../@material-extend';
import MDividerSection from '../../@material-extend/MDividerSection';
import { routeConfig } from '../../../configs';
import { useNavigateTo } from '../../../helpers';
import Restricted from '../../Restricted';
import { permissions } from '../../../configs/permissionConstants';


SiteSupplierContractForm.propTypes = {
  form_data: PropTypes.object,
}

export function SiteSupplierContractForm(props) {
  const supplierContractContext = useSupplierContractContext();
  const [showBackdrop, setShowBackdrop] = useState(false);
  const { form_data } = props
  const lookup = useContext(LookupContext);
  const [params, navigateTo] = useNavigateTo();
  const layout = useContext(LayoutContext);

  const form = useForm({
    defaultValues: supplierContractContext.defaultValues,
  });

  useEffect(() => {
    if (params.site_uuid && !form_data?.site_uuid) {
      form.setValue("site_uuid", params.site_uuid);
    }
  }, [form, params.site_uuid, form_data])

  useEffect(() => {
    if (params.site_uuid && !form_data?.site_uuid) {
      form.setValue("site_uuid", params.site_uuid);
    }
  }, [form, params.site_uuid, form_data])

  useEffect(() => {
    if (form_data) {
      if (form_data.first_fill_date) {
        form_data.first_fill_date = new Date(form_data.first_fill_date.replace(/-/g, '/'));
      }
      if (form_data.supplier_contract_execution_date) {
        form_data.supplier_contract_execution_date = new Date(form_data.supplier_contract_execution_date.replace(/-/g, '/'));
      }
      form.reset(form_data);
    }
  }, [form_data, form]);

  const handleValidateForm = (data) => {
    let errorMessage = null;
    if (!data.supplier_contract_description) {
      data.supplier_contract_description = null;
      errorMessage = "Provide a contract description.";
    }
    else if (!data.supplier_contract_status) {
      data.supplier_contract_status = null;
      errorMessage = "Choose a contract status.";
    }
    else if (!data.supplier_uuid) {
      data.supplier_uuid = null;
      errorMessage = "Choose a supplier.";
    }
    else if (!data.supplier_contract_execution_date) {
      data.supplier_contract_exwcution_date = null;
      errorMessage = "Select a contract execution date.";
    }
    else if (!data.supplier_contract_term_length) {
      data.supplier_contract_term_length = null;
      errorMessage = "Contract term length is required.";
    }

    data.supplier_contract_execution_date = dateHelper.formatDate(data.supplier_contract_execution_date);

    if (data.first_fill_date) {
      data.first_fill_date = dateHelper.formatDate(data.first_fill_date);
    }

    if (errorMessage) {
      layout.handleError(errorMessage);
      return false;
    }
    return true;
  }

  function onSubmit(data) {
    const isValidForm = handleValidateForm(data);
    if (!isValidForm) {
      return;
    }
    return supplierContractContext.upsertEntity(data)
      .then(res => {
        setShowBackdrop(false);
        navigateTo(routeConfig.site_view);
      });
  }

  return (<MForm
    form={form}
    defaultValues={supplierContractContext.defaultValues}
    onSubmit={onSubmit}
    submitText={"Save Contract"}
  >
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={showBackdrop}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <MDividerSection
      position="form"
      above={{ title: "Supplier Contract" }}
    >
      <Restricted to={[permissions.WRITE_SUPPLIER_CONTRACTS]}>
        <MFormTextField
          onControlEnter={form.handleSubmit(onSubmit)}
          control={form.control}
          minRows={2}
          name="supplier_contract_description"
          label="Contract Description"
          multiline
          fullWidth
          required
        />
        <MFormSelect
          autoFocus
          onControlEnter={form.handleSubmit(onSubmit)}
          control={form.control}
          GridProps={{
            xs: 6,
          }}
          name="supplier_contract_status"
          includeAll="-- Unselect --"
          options={lookup.values.supplier_contract_status}
          label="Contract Status"
          required
        />
        <MFormSelect
          autoFocus
          onControlEnter={form.handleSubmit(onSubmit)}
          control={form.control}
          GridProps={{
            xs: 6,
          }}
          name="supplier_uuid"
          includeAll="-- Unselect --"
          options={lookup.values.supplier}
          label="Supplier"
          required
        />
        <MFormDatePicker
          name="supplier_contract_execution_date"
          label="Contract Execution Date"
          clearable
          required
          onControlEnter={form.handleSubmit(onSubmit)}
          control={form.control}
          GridProps={{
            xs: 12,
            md: 6,
          }}
        />
        <MFormTextField
          name="supplier_contract_number"
          label="Contract Number"
          onControlEnter={form.handleSubmit(onSubmit)}
          control={form.control}
          GridProps={{
            xs: 12,
            md: 6,
          }}
        />
        <MFormTextField
          name="supplier_contract_term_length"
          label="Contract Term Length (Months)"
          required
          onControlEnter={form.handleSubmit(onSubmit)}
          control={form.control}
          type="number"
          GridProps={{
            xs: 12,
            md: 6,
          }}
        />
        <MFormDatePicker
          name="first_fill_date"
          label="First Fill Date"
          clearable
          onControlEnter={form.handleSubmit(onSubmit)}
          control={form.control}
          GridProps={{
            xs: 12,
            md: 6,
          }}
        />
      </Restricted>
      <Restricted to={[permissions.WRITE_SUPPLIER_CONTRACTS, permissions.WRITE_SUPPLIER_CONTRACT_ACCOUNT_NUMBER]}>
        <MFormTextField
          name="account_number"
          label="Account Number"
          onControlEnter={form.handleSubmit(onSubmit)}
          control={form.control}
          GridProps={{
            xs: 12,
            md: 6,
          }}
        />
      </Restricted>
    </MDividerSection>
  </MForm>
  );
}