import { Dashboard } from './components/pages/dashboard/Dashboard';
import { Navigate, Route, Routes as ReactRouterRoutes } from "react-router-dom";
import { routeConfig } from "./configs/routeConfig";
import AddBusinessSite from "./components/pages/site/AddSite";
import AddBusinessUnit from "./components/pages/business/AddBusinessUnit";
import UpsertBusinessContact from "./components/pages/business/UpsertBusinessContact";
import EditBusinessUnit from "./components/pages/business/EditBusinessUnit";
import AddParentCompany from "./components/pages/parent/AddParentCompany";
import AddRegionalCompany from "./components/pages/region/AddRegionalCompany";
import CustomerAdmin from "./components/pages/CustomerAdmin";
import CustomerAdminLayout from './components/layouts/customerAdmin';
import EditBusinessSite from "./components/pages/site/EditSite";
import UpsertSiteContact from "./components/pages/site/UpsertSiteContact";
import EditParentCompany from "./components/pages/parent/EditParentCompany";
import Logout from "./components/pages/Logout";
import EditRegionalCompany from "./components/pages/region/EditRegionalCompany";
import LogoOnlyLayout from './components/pages/LogoOnlyLayout';
import NotFound from './components/pages/Page404';
import ScheduleApp from "./components/pages/ScheduleApp";
import BusinessSite from "./components/pages/site/Site";
import BusinessUnit from "./components/pages/business/BusinessUnit";
import ParentCompany from "./components/pages/parent/ParentCompany";
import RegionalCompany from "./components/pages/region/RegionalCompany";
import MainLayout from './components/layouts/main/MainLayout';
import SuppliersPage from "./components/pages/supplier/Suppliers";
import EditSupplierPage from './components/pages/supplier/EditSupplier';
import AddSupplier from './components/pages/supplier/AddSupplier';
import UpsertCustomerContract from './components/pages/site/Site/UpsertCustomerContract';
import UpsertRepresentativeAssignment from './components/pages/site/Site/UpsertRepresentativeAssignment';
import TankDeliveryTable from './components/pages/tankDelivery/TankDeliveryTable';
import TankDeliveryEvent from './components/pages/tankDelivery/TankDeliveryEvent';
import TankDeliveryTableRefresh from './components/pages/tankDelivery/TankDeliveryTableRefresh';
import AddTankDeliveryEvent from './components/pages/tankDelivery/AddTankDeliveryEvent';
import AddPricingMinimum from './components/pages/site/Site/AddPricingMiniimum';
import AddPricingFee from './components/pages/site/Site/AddPricingFee';
import AddPricingRate from './components/pages/site/Site/AddPricingRate';
import UpsertSupplierContract from './components/pages/site/Site/UpsertSupplierContract';
import AddSupplierContractRate from './components/pages/site/Site/AddSupplierContractRate';
import AddSupplierTakeOrPayTerm from './components/pages/site/Site/AddSupplierTakeOrPayTerm';
import AddOperationComment from './components/pages/operationcomment/AddOperationComment';
import DeliveryTicketTable from './components/pages/deliveryTictet/DeliveryTicketTable';
import DeliveryTicketTableRefresh from './components/pages/deliveryTictet/DeliveryTicketTableRefresh';
import DeliveryTicket from './components/pages/deliveryTictet/DeliveryTicket';
import UsageEventTable from './components/pages/usage/UsageEventTable';
import UsageEvent from './components/pages/usage/UsageEvent';
import UsageEventTableRefresh from './components/pages/usage/UsageEventTableRefresh';
import AddUsageEvent from './components/pages/usage/AddUsageEvent';
import RepresentativeTable from './components/pages/representative/RepresentativeTable';
import Representative from './components/pages/representative/Representative';
import AddRepresentative from './components/pages/representative/AddRepresentative';
import EditTankDeliveryCalibrations from './components/pages/tankDelivery/EditTankDeliveryCalibrations';
import AddUsageComment from './components/pages/usagecomment/AddUsageComment';
export default function MainRoutes() {

  return (<ReactRouterRoutes>
    <Route path="/logout" element={<Logout />} />
    <Route path="/dashboard" element={<MainLayout />}>
      <Route path={routeConfig.dashboard.path} element={<Dashboard />} />
      <Route element={<Navigate to={routeConfig.dashboard.fullPath} replace />} />

      {/* dashboard/supplier */}
      <Route path={routeConfig.supplier.path} >
        <Route index element={<SuppliersPage />} />
        <Route path={routeConfig.supplier_add.path} element={<AddSupplier />} />
        <Route path={routeConfig.supplier_view.path} >
          <Route path={routeConfig.supplier_edit.path} element={<EditSupplierPage />} />
        </Route>
      </Route>


      {/* dashboard/tank_fills */}
      <Route path={routeConfig.tank_fill_table.path} >
        <Route index element={<TankDeliveryTable />} />
        <Route path={`${routeConfig.tank_fill_manual_entry.path}/*`} element={<AddTankDeliveryEvent />} />
        <Route path={`${routeConfig.tank_fill_table_refresh.path}`} element={<TankDeliveryTableRefresh />} />

        <Route path={routeConfig.tank_fill_event_view.path}>
          <Route index element={<TankDeliveryEvent />} />
          <Route path={`${routeConfig.tank_fill_edit_calibration.path}`} element={<EditTankDeliveryCalibrations />} />
        </Route>
      </Route>

      {/* dashboard/usages */}
      <Route path={routeConfig.usage_table.path} >
        <Route index element={<UsageEventTable />} />
        <Route path={`${routeConfig.usage_manual_entry.path}/*`} element={<AddUsageEvent />} />
        <Route path={`${routeConfig.usage_table_refresh.path}`} element={<UsageEventTableRefresh />} />
        <Route path={routeConfig.usage_view.path} >
          <Route index element={<UsageEvent />} />
        </Route>
      </Route>

      {/* dashboard/representatives */}
      <Route path={routeConfig.representative_table.path} >
        <Route index element={<RepresentativeTable />} />
        <Route path={`${routeConfig.representative_manual_entry.path}/*`} element={<AddRepresentative />} />
        <Route path={routeConfig.representative_view.path} >
          <Route index element={<Representative />} />
        </Route>
      </Route>

      {/* dashboard/delivery_tickets */}
      <Route path={routeConfig.delivery_ticket_table.path} >
        <Route index element={<DeliveryTicketTable />} />
        <Route path={`${routeConfig.delivery_ticket_table_refresh.path}`} element={<DeliveryTicketTableRefresh />} />
        <Route path={routeConfig.delivery_ticket_view.path} >
          <Route index element={<DeliveryTicket />} />
        </Route>
      </Route>


      {/* dashboard/operation_comments */}
      <Route path={routeConfig.operations_comment.path} >
        <Route index element={<AddOperationComment />} />
      </Route>

      {/* dashboard/usage_comments */}
      <Route path={routeConfig.usage_comment.path} >
        <Route index element={<AddUsageComment />} />
      </Route>

      {/* dashboard/customeradmin */}
      <Route path={routeConfig.site_admin.path} element={<CustomerAdminLayout />} >
        <Route index element={<CustomerAdmin />} />
        {/* Parent - dashboard/site_admin/parent --- */}
        <Route path={routeConfig.parent.path}>
          {/* Add Parent - dashboard/site_admin/parent/add --- */}
          <Route path={routeConfig.parent_add.path} element={<AddParentCompany />} />
          {/* View Parent by UUID - dashboard/site_admin/parent/:pid --- */}
          <Route path={routeConfig.parent_view.path}>
            <Route index element={<ParentCompany />} />
            {/* Edit Parent by UUID - dashboard/site_admin/parent/:pid/edit --- */}
            <Route path={routeConfig.parent_edit.path} element={<EditParentCompany />} />
            {/* Parent Regions - dashboard/site_admin/parent/:pid/region --- */}
            <Route path={routeConfig.region.path}>
              {/* Add Region to Parent - dashboard/site_admin/parent/:pid/region/add --- */}
              <Route path={routeConfig.region_add.path} element={<AddRegionalCompany />} />
              {/* View Region by UUID - dashboard/site_admin/parent/:pid/region/:rid --- */}
              <Route path={routeConfig.region_view.path}>
                <Route index element={<RegionalCompany />} />
                {/* Edit Region by UUID - dashboard/site_admin/parent/:pid/region/:rid/edit --- */}
                <Route path={routeConfig.region_edit.path} element={<EditRegionalCompany />} />
                {/* Region Businesses - dashboard/site_admin/parent/:pid/region/:rid/business --- */}
                <Route path={routeConfig.business.path}>
                  {/* Add Business - dashboard/site_admin/parent/:pid/region/:rid/business/add --- */}
                  <Route path={routeConfig.region_add.path} element={<AddBusinessUnit />} />
                  {/* View Business by UUID - dashboard/site_admin/parent/:pid/region/:rid/business/:bid --- */}
                  <Route path={routeConfig.business_view.path}>
                    <Route index element={<BusinessUnit />} />
                    {/* Edit Business by UUID - dashboard/site_admin/parent/:pid/region/:rid/business/:bid/edit --- */}
                    <Route path={routeConfig.business_edit.path} element={<EditBusinessUnit />} />
                    {/* Business Contacts - dashboard/site_admin/parent/:pid/region/:rid/business/:bid/contacts --- */}
                    <Route path={routeConfig.business_contacts.path}>
                      {/* Add Business Contact - dashboard/site_admin/parent/:pid/region/:rid/business/:bid/contacts/add --- */}
                      <Route path={routeConfig.business_contact_add.path} element={<UpsertBusinessContact />} />
                      {/* Edit Business Contact - dashboard/site_admin/parent/:pid/region/:rid/business/:bid/contacts/:cid --- */}
                      <Route path={routeConfig.business_contact_edit.path} element={<UpsertBusinessContact />} />
                    </Route>


                    {/* Business Sites - dashboard/site_admin/parent/:pid/region/:rid/business/:bid/site --- */}
                    <Route path={routeConfig.site.path}>
                      <Route path={routeConfig.site_add.path} element={<AddBusinessSite />} />
                      {/* View Site by UUID - dashboard/site_admin/parent/:pid/region/:rid/business/:bid/site/:sid --- */}
                      <Route path={routeConfig.site_view.path}>
                        <Route index element={<BusinessSite />} />
                        {/* Edit Site by UUID - dashboard/site_admin/parent/:pid/region/:rid/business/:bid/site/:sid/edit --- */}
                        <Route path={routeConfig.site_edit.path} element={<EditBusinessSite />} />
                        {/* Site Contacts - dashboard/site_admin/parent/:pid/region/:rid/business/:bid/site/:sid/contacts/ --- */}
                        <Route path={routeConfig.site_contacts.path}>
                          {/* Add Site Contact - dashboard/site_admin/parent/:pid/region/:rid/business/:bid/site/:sid/contacts/add --- */}
                          <Route path={routeConfig.site_contact_add.path} element={<UpsertSiteContact />} />
                          {/* Edit Site Contact - dashboard/site_admin/parent/:pid/region/:rid/business/:bid/site/:sid/contacts/:cid --- */}
                          <Route path={routeConfig.site_contact_edit.path} element={<UpsertSiteContact />} />
                        </Route>
                        {/* Site Representatives - dashboard/site_admin/parent/:pid/region/:rid/business/:bid/site/:sid/representative_assignments/ --- */}
                        <Route path={routeConfig.representative_assignments.path}>
                          {/* Add Site Representative - dashboard/site_admin/parent/:pid/region/:rid/business/:bid/site/:sid/representative_assignments/add --- */}
                          <Route path={routeConfig.representative_assignment_add.path} element={<UpsertRepresentativeAssignment />} />
                          {/* Edit Site Representative - dashboard/site_admin/parent/:pid/region/:rid/business/:bid/site/:sid/representative_assignments/:rid --- */}
                          <Route path={routeConfig.representative_assignment_edit.path} element={<UpsertRepresentativeAssignment />} />
                        </Route>
                        {/* Customer Contracts - dashboard/site_admin/parent/:pid/region/:rid/business/:bid/site/:sid/customer_contract/ --- */}
                        <Route path={routeConfig.customer_contract.path}>
                          {/* Add Customer Contract - dashboard/site_admin/parent/:pid/region/:rid/business/:bid/site/:sid/customer_contract/add --- */}
                          <Route path={routeConfig.customer_contract_add.path} element={<UpsertCustomerContract />} />
                          {/* Edit Customer Contract - dashboard/site_admin/parent/:pid/region/:rid/business/:bid/site/:sid/customer_contract/:cid/edit --- */}
                          <Route path={routeConfig.customer_contract_edit.path} element={<UpsertCustomerContract />} />
                          {/* Add Customer Contract Minimum - dashboard/site_admin/parent/:pid/region/:rid/business/:bid/site/:sid/customer_contract/:cid/add/minimum --- */}
                          <Route path={routeConfig.customer_contract_add_minimum.path} element={<AddPricingMinimum />} />
                          {/* Add Customer Contract Fee - dashboard/site_admin/parent/:pid/region/:rid/business/:bid/site/:sid/customer_contract/:cid/add/fee --- */}
                          <Route path={routeConfig.customer_contract_add_fee.path} element={<AddPricingFee />} />
                          {/* Add Customer Contract Rate - dashboard/site_admin/parent/:pid/region/:rid/business/:bid/site/:sid/customer_contract/:cid/add/rate --- */}
                          <Route path={routeConfig.customer_contract_add_rate.path} element={<AddPricingRate />} />
                        </Route>
                        {/* Supplier Contracts - dashboard/site_admin/parent/:pid/region/:rid/business/:bid/site/:sid/supplier_contract/ --- */}
                        <Route path={routeConfig.supplier_contract.path}>
                          {/* Add Supplier Contract - dashboard/site_admin/parent/:pid/region/:rid/business/:bid/site/:sid/supplier_contract/add --- */}
                          <Route path={routeConfig.supplier_contract_add.path} element={<UpsertSupplierContract />} />
                          {/* Edit Supplier Contract - dashboard/site_admin/parent/:pid/region/:rid/business/:bid/site/:sid/supplier_contract/:spid/edit --- */}
                          <Route path={routeConfig.supplier_contract_edit.path} element={<UpsertSupplierContract />} />
                          {/* Add Supplier Contract Minimum - dashboard/site_admin/parent/:pid/region/:rid/business/:bid/site/:sid/supplier_contract/:spid/add/take_or_pay_term --- */}
                          <Route path={routeConfig.supplier_contract_add_take_or_pay_term.path} element={<AddSupplierTakeOrPayTerm />} />
                          {/* Add Supplier Contract Rate - dashboard/site_admin/parent/:pid/region/:rid/business/:bid/site/:sid/supplier_contract/:spid/add/rate --- */}
                          <Route path={routeConfig.supplier_contract_add_rate.path} element={<AddSupplierContractRate />} />
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>


      <Route path={"schedule"} element={<ScheduleApp />} />
    </Route>

    <Route path="/" element={<LogoOnlyLayout />}>
      <Route path="404" element={<NotFound />} />
      <Route path="/" element={<Navigate to={routeConfig.dashboard.fullPath} />} />
      <Route path="*" element={<Navigate to={"/404"} replace />} />
    </Route>
  </ReactRouterRoutes >);
}