import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

MCheckbox.propTypes = {
    title: PropTypes.string,
    helperText: PropTypes.string,
    emptyText: PropTypes.string,
    GridProps: PropTypes.object,
    label: PropTypes.string.isRequired,
    value: PropTypes.any,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    labelPlacement: PropTypes.string,
}
const useStyles = makeStyles({
    input: {
        height: 20,
        width: 20,
        // padding: 0,
        // margin: 0,
        boxSizing: "border-box" // <-- add this
    }
});

export default function MCheckbox(props) {

    const { label,
        dense,
        // value,
        checked,
        disabled,
        // emptyText,
        labelPlacement,
        GridProps = { xs: 12 },
        // onChange,
        // ...groupProps
    } = props;
    const handleChange = (event) => {
        if (props.onChange) {
            props.onChange(event);
        }
    };
    const classes = useStyles();
    const result = (
        <FormControlLabel
            control={<Checkbox
                disableFocusRipple
                classes={dense && { root: classes.input }}
                checked={checked || false}
                disabled={disabled}
                onChange={handleChange}
            />}

            labelPlacement={labelPlacement}
            label={label}
        />
    );

    return <Grid item {...GridProps} container alignItems="center">
        <Grid item sx={GridProps.sx}>
            {result}
        </Grid>
    </Grid>;

}