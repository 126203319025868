import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import { Typography } from '@mui/material';
import MDividerSection from "./MDividerSection";

MDivider.propTypes = {
    GridProps: PropTypes.object,
    children: PropTypes.node,
    above: PropTypes.object,
    direction: PropTypes.oneOf(["row", "column"]),
    below: PropTypes.object,
    xs: PropTypes.number,
    color: PropTypes.string,
    active: PropTypes.bool,
    variant: PropTypes.oneOf(['form', 'list-middle', 'list-first', 'standard']),
}

/**
 * 
 * @param {{
 * GridProps: {},
 * above: {},
 * direction: "row" | "column",
 * below: {},
 * xs: number,
 * color: string,
 * active: boolean,
 * variant: "form" | "list-middle" | "list-first" | "standard",
 * }} props 
 * @returns 
 */
function MDivider(props) {
    return <MDividerSection {...props}
        position={props.variant}
        variant="section"
    />

    // const {
    //     GridProps,
    //     above,
    //     below,
    //     color = props.active ? "primary.main" : "secondary.dark",
    //     active,
    //     children,
    //     variant = "standard",
    //     xs,
    //     ...rest
    // } = props;

    // const variantTextSx = variant === "list-first" ? {
    //     color: color,
    // }
    //     : variant === "list-middle" ? {
    //         color: color,
    //     }
    //         : variant === "form" ? {
    //             color: "secondary.dark",
    //             fontWeight: "bold",
    //         } : {}
    // const variantGridSx = variant === "form" ? { mt: 3, mb: 1, mx: 1 }
    //     : variant === "list-first" ? { mt: 2, mb: .5, }
    //         : variant === "list-middle" ? { mt: 4, mb: .5, }
    //             : {};

    // const direction = props.direction || variant === "form" ? "row" : "column";
    // const directionXs = direction === "row" ? 12 : undefined;

    // return (<Grid
    //     item
    //     sx={{ ...variantGridSx, ...GridProps?.sx, ...props.sx }}
    //     xs={xs || GridProps?.xs || directionXs}
    //     {...GridProps}
    // >
    //     {above && <ItemColumn
    //         {...above}
    //         variant="h5"
    //         textSx={variantTextSx}
    //         sx={above.sx}
    //     />}
    //     <Divider
    //         {...rest}
    //     />
    //     {below && <ItemColumn
    //         {...below}
    //         textSx={variantTextSx}
    //         sx={below.sx}
    //     />}
    // </Grid>

    // );
}

ItemColumn.propTypes = {
    GridProps: PropTypes.object,
    title: PropTypes.string,
    variant: PropTypes.string,
    subtitles: PropTypes.array,
    textSx: PropTypes.object,
    sx: PropTypes.object,
}
function ItemColumn({ GridProps, variant = "h6", ...props }) {

    return <Grid item xs={12} sx={props.sx} container direction="column" {...GridProps}>
        {props.title && <Typography variant={variant} sx={{ color: "inherit", ...props.textSx }} children={props.title} />}
        {props.subtitles?.map((subtitle, index) => {
            return (
                <Typography variant="caption" key={index} sx={props.textSx} color="text.secondary" children={subtitle} />
            )
        })}
    </Grid>
}

export default MDivider;