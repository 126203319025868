/* eslint-disable react-hooks/exhaustive-deps */
import Page from '../../templates/Page';
import CustomerAdminContext from '../../../contexts/CustomerAdminContext';
import DeleteIcon from '@mui/icons-material/Delete';
import { routeConfig, entityConfig } from '../../../configs';
import { useContext, useEffect, useState } from 'react';
import PageSection from '../../templates/PageSection';
import PageText from '../../templates/PageText';
import { Grid } from '@mui/material';
import { BusinessUnitList, BusinessSiteList } from '../../lists';
import { MButton, MBreadcrumbs, MIconButton } from '../../@material-extend';
import MoreMenu from '../../MoreMenu';
import { useNavigateTo } from '../../../helpers';
import { useRegionalCompanyContext } from '../../../contexts';
import { useAuth0 } from '@auth0/auth0-react';
import Restricted from '../../Restricted';
import { permissions } from '../../../configs/permissionConstants';
import { confirmDialog } from '../../ConfirmDialog';

export default function RegionalCompany(props) {
  const { entities, deleteEntity, getChildUuids, navigateTo } = useContext(CustomerAdminContext);
  const regionalCompanyContext = useRegionalCompanyContext();
  const [params] = useNavigateTo();
  const [routeTitle, setRouteTitle] = useState("Loading");
  const [childUuids, setChildUuids] = useState([]);
  const { getAccessTokenSilently } = useAuth0();


  const handleAddBusinessUnit = () => {
    navigateTo(routeConfig.business_add);
  }

  useEffect(() => {
    regionalCompanyContext.setActiveEntity(params.regional_company_uuid);
  }, [params.regional_company_uuid]);

  useEffect(() => {
    if (regionalCompanyContext.activeEntity) {
      setRouteTitle(regionalCompanyContext.activeEntity?.regional_company_name);
      const childIds = getChildUuids(regionalCompanyContext.activeEntity);
      setChildUuids(childIds);
    } else {
      setRouteTitle("Loading...");
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [regionalCompanyContext.activeEntity, entities]);

  const handleDeleteRegionalCompany = async () => {
    const token = await getAccessTokenSilently();
    deleteEntity(token, entityConfig.type.regional_company, entities.active_region?.id).then(() => {
      navigateTo(routeConfig.parent_view);
    });
  }

  return (
    <Page
      showPageNav
      route={routeConfig.region_view}
      title={routeTitle}
      collapsible
      subtitle={<MBreadcrumbs
        items={[
          { label: "Parent Company", onClick: () => navigateTo(routeConfig.parent_view) },
          { label: "Regional Company", onClick: () => navigateTo(routeConfig.region_view), disabled: true },
        ]}
      />}
      gap={2}
      subtitleEndActions={
        <Restricted to={[permissions.WRITE_SITE_HIERARCHY]}>
          <MoreMenu
            color="primary"
            options={[
              {
                label: 'Delete Regional Company',
                icon: <DeleteIcon />,
                onClick: () => confirmDialog("Do you want to delete the regional company?", () => handleDeleteRegionalCompany())
              },
            ]}
          />
        </Restricted>
      }
    >
      {/* ----------------------------- Details Section ---------------------------- */}
      <PageSection
        title="Details"
        collapsible
        endActions={
          <Restricted to={[permissions.WRITE_SITE_HIERARCHY]}>
            <MIconButton
              icon="edit"
              size="small"
              color="primary"
              onClick={() => navigateTo(routeConfig.region_edit)}
            />
          </Restricted>
        }
      >
        <PageText
          label="Regional Company Name"
          body={entities.active_region?.regional_company_name}
        />
      </PageSection>

      {/* ----------------------------- Hierarchy Section ---------------------------- */}
      <PageSection
        title="Company Hierarchy"
        collapsible
        direction="row"
        justifyContent="flex-start"
        endActions={
          <Restricted to={[permissions.WRITE_SITE_HIERARCHY]}>
            <MButton
              variant="contained"
              icon="add"
              size="small"
              onClick={handleAddBusinessUnit}
              children="Add Business Unit"
            />
          </Restricted>
        }
      >
        <Grid item xs={12} md={4} xl={3} sx={{ px: .5 }}>
          <BusinessUnitList
            filterItems={(i) => childUuids.some(uuid => uuid === i.id)}
          />
        </Grid>
        <Grid item xs={12} md={4} xl={3} sx={{ px: .5 }}>
          <BusinessSiteList
            filterItems={(i) => childUuids.some(uuid => uuid === i.id)}
          />
        </Grid>
      </PageSection>

    </Page >
  )
};