/* eslint-disable react-hooks/exhaustive-deps */
import Page from '../../templates/Page';
import { SiteGeneralForm } from '../../forms';
import { routeConfig } from '../../../configs';
import PageSection from '../../templates/PageSection';
import { useNavigateTo } from '../../../helpers';
import { useSiteContext } from '../../../contexts';

export default function AddBusinessSite(props) {
  const [, navigateTo] = useNavigateTo();
  const siteContext = useSiteContext();

  return (
    <Page route={routeConfig.site_add}
      onBack={() => navigateTo(routeConfig.business_view)}
    >
      <PageSection title="Enter Site Details">
        <SiteGeneralForm siteContext={siteContext} />
      </PageSection>
    </Page >
  );
}