/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { FormControlLabel, FormGroup, Switch, Typography } from '@mui/material';

import PageSection from '../../../templates/PageSection';
import { MButton, MIconButton, MSelect } from '../../../@material-extend';
import { Grid } from '@mui/material';
import { useNavigateTo } from '../../../../helpers';
import { routeConfig } from '../../../../configs';
import {
  useLookupContext,
  CustomerContractContext,
  usePricingMinimumContext,
  usePricingRateContext,
  usePricingFeeContext
} from '../../../../contexts';
import MDividerSection from '../../../@material-extend/MDividerSection';
import PageText from '../../../templates/PageText';
import PricingMinimumList from '../../../lists/PricingMinimumList';
import PricingFeeList from '../../../lists/PricingFeeList';
import PricingRateList from '../../../lists/PricingRateList';
import Restricted from '../../../Restricted';
import { permissions } from '../../../../configs/permissionConstants';
import { confirmDialog } from '../../../ConfirmDialog';
import Item from 'antd/lib/list/Item';
import { Space } from 'antd';

/**
 * @param {{
 *    isLoading: boolean;
 * }} props
 * @returns 
 */
export default function SiteCustomerContractSection({ isLoading }) {

  const [params, navigateTo] = useNavigateTo();
  const lookup = useLookupContext();
  const customerContractContext = useContext(CustomerContractContext);
  const pricingFeeContext = usePricingFeeContext();
  const pricingMinimumContext = usePricingMinimumContext();
  const pricingRateContext = usePricingRateContext();
  const [siteCustomerContracts, setSiteCustomerContracts] = useState([]);
  const [activeCustomerContract, setActiveCustomerContract] = useState(customerContractContext.defaultValues);
  const [activePricingFees, setActivePricingFees] = useState([]);
  const [activePricingMinimums, setActivePricingMinimums] = useState([]);
  const [activePricingRates, setActivePricingRates] = useState([]);
  const [selectedContractId, setSelectedContractId] = useState('');
  const [isLoadingContractData, setIsLoadingContractData] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const handleAddContract = () => {
    navigateTo(routeConfig.customer_contract_add);
  }

  const handleEditCustomerContract = () => {
    navigateTo(routeConfig.customer_contract_edit, { customer_contract_uuid: selectedContractId })
  }

  const handleAddPricingMinimum = () => {
    navigateTo(routeConfig.customer_contract_add_minimum, { customer_contract_uuid: selectedContractId })
  }

  const handleDeleteMinimum = async (data) => {
    await pricingMinimumContext.deleteEntity(data.uuid)
      .then(() => {
        fetchCustomerContracts();
        setIsLoadingContractData(false);
      })
  }

  const handleAddPricingFee = () => {
    navigateTo(routeConfig.customer_contract_add_fee, { customer_contract_uuid: selectedContractId })
  }

  const handleDeleteFee = async (data) => {
    await pricingFeeContext.deleteEntity(data.uuid)
      .then(() => {
        fetchCustomerContracts();
        setIsLoadingContractData(false);
      })
  }

  const handleAddPricingRate = () => {
    navigateTo(routeConfig.customer_contract_add_rate, { customer_contract_uuid: selectedContractId })
  }

  const handleDeleteRate = async (data) => {
    await pricingRateContext.deleteEntity(data.uuid)
      .then(() => {
        fetchCustomerContracts();
        setIsLoadingContractData(false);
      })
  }

  const handleSelectContract = (newId) => {
    setSelectedContractId(newId);
    setIsLoadingContractData(true);
    const nextActiveContract = siteCustomerContracts.find(contract => contract.value === newId);
    if (nextActiveContract) {
      setActiveCustomerContract(nextActiveContract);
    }
    if (!newId) {
      return;
    }

    // Fetch the pricing fees for the latest contract
    pricingFeeContext.queryHandler.fetch(`/${newId}`)
      .then(res => {
        setActivePricingFees(res.pricing_fee);
        setIsLoadingContractData(false);
      });
    pricingMinimumContext.queryHandler.fetch(`/${newId}`)
      .then(res => {
        const pricingMinimums = res.pricing_minimum;
        const sortedMinimums = pricingMinimums.sort((a, b) => new Date(b.annual_minimum_date) - new Date(a.annual_minimum_date));
        setActivePricingMinimums(sortedMinimums);
      });
    pricingRateContext.queryHandler.fetch(`/${newId}`)
      .then(res => {
        setActivePricingRates(res.pricing_rate);
        setIsLoadingContractData(false);
      });
  }

  const handleDeleteCustomerContract = async () => {
    setIsLoadingContractData(true);
    const deleteFeePromises = activePricingFees.map(fee => pricingFeeContext.deleteEntity(fee.pricing_fee_uuid));
    const deleteMinimumPromises = activePricingMinimums.map(minimum => pricingMinimumContext.deleteEntity(minimum.pricing_minimum_uuid));
    const deleteRatePromises = activePricingRates.map(rate => pricingRateContext.deleteEntity(rate.pricing_rate_uuid));

    await Promise.allSettled([...deleteFeePromises, ...deleteMinimumPromises, ...deleteRatePromises]).then(() => {
      customerContractContext.deleteEntity(selectedContractId)
        .then(() => {
          fetchCustomerContracts();
          setIsLoadingContractData(false);
        })
    })
  }

  const fetchCustomerContracts = () => {
    customerContractContext.queryHandler.fetch(`/${params.site_uuid}`).then(res => {
      const siteContracts = res.customer_contract;
      // sort from recent to oldest where recent is the first element
      const sortedContracts = siteContracts.sort((a, b) => new Date(b.customer_contract_effective_date) - new Date(a.customer_contract_effective_date));

      setSiteCustomerContracts(sortedContracts.map((customerContract, index) => {
        const result = {
          ...customerContract,
          label: customerContract.customer_contract_effective_date,
          id: customerContract.customer_contract_uuid,
          value: customerContract.customer_contract_uuid,
        };
        return result;
      }));

    })
  }

  // fetch customer contracts on mount
  useEffect(() => {
    if (!params.site_uuid) {
      return;
    }
    fetchCustomerContracts();
  }, [params.site_uuid]);

  // set a new active customer contract whenever customer contracts load
  useEffect(() => {
    if (siteCustomerContracts.length > 0) {
      handleSelectContract(siteCustomerContracts[0].customer_contract_uuid);
    } else {
      setActivePricingFees([]);
      setActivePricingMinimums([]);
      setActivePricingRates([]);
      setActiveCustomerContract(customerContractContext.defaultValues);
    }
  }, [siteCustomerContracts])

  const handleToggleShowAll = () => {
    setShowAll(!showAll);
  }

  return (<PageSection
    title="Customer Contract"
    omitContainer
    spacing={2}
    endActions={
      <Restricted to={[permissions.WRITE_CUSTOMER_CONTRACTS]}>
        <MButton
          GridProps
          collapseXs
          disabled={isLoading}
          size="small"
          icon="add"
          variant="outlined"
          color="primary"
          tooltip={{ title: "New Customer Contract" }}
          onClick={handleAddContract}
          children="Add Contract"
        />
      </Restricted>
    }
    collapsible
  >
    {/* ------ Contract Info Section -------- */}

    {(isLoadingContractData || siteCustomerContracts.length > 0)
      && <>
        <MDividerSection
          position='list-first'
          direction='row'
          variant='section'
          above={{
            title: "Contract",
          }} >
          <PageText
            label="Contract Description"
            GridProps={{ xs: 12, md: 6 }}
            isLoading={isLoadingContractData}
            body={activeCustomerContract?.customer_contract_description || "-"}
          />
          <Grid container item xs={12} md={6} alignItems="center" >
            <MSelect
              label="Effective Date"
              disabled={isLoadingContractData || siteCustomerContracts.length === 0}
              value={selectedContractId}
              onChange={e => handleSelectContract(e.target.value)}
              GridProps={{ xs: 7, }}
              options={siteCustomerContracts}
            />
            <Space size={15}>
              <Item />
              <Item />
              <Item />
              <Item>
                <Restricted to={[permissions.WRITE_CUSTOMER_CONTRACTS]}>
                  <MIconButton
                    size="small"
                    tooltip="Edit selected contract"
                    onClick={() => handleEditCustomerContract()}
                    icon="edit"
                    color="primary"
                  />
                </Restricted>
                <Restricted to={[permissions.DELETE_CUSTOMER_CONTRACTS]}>
                  <MIconButton
                    size="small"
                    tooltip="Delete selected contract"
                    onClick={() => {
                      confirmDialog("Do you want to delete the customer contract? It will also delete all the associated rates, minimums and fees.", () => handleDeleteCustomerContract())
                    }}
                    icon="delete"
                    color="primary"
                  />
                </Restricted>
              </Item>
            </Space>
          </Grid>

          <PageText
            label="Pricing Model"
            GridProps={{ xs: 6, md: 3 }}
            isLoading={isLoadingContractData}
            body={lookup.byValue("pricing_model", activeCustomerContract?.pricing_model).label || "-"}
          />
          <PageText
            label="Contract Status"
            GridProps={{ xs: 6, md: 3 }}
            isLoading={isLoadingContractData}
            body={lookup.byValue("customer_contract_status", activeCustomerContract?.customer_contract_status).label || "-"}
          />
          <PageText
            label="Contract Number"
            GridProps={{ xs: 6, md: 3 }}
            isLoading={isLoadingContractData}
            body={activeCustomerContract?.customer_contract_number || "-"}
          />
          <PageText
            label="Contract Rate Terms (days)"
            GridProps={{ xs: 6, md: 3 }}
            isLoading={isLoadingContractData}
            body={activeCustomerContract?.customer_contract_rate_terms || "-"}
          />

          <PageText
            label="Contract Length (months)"
            GridProps={{ xs: 6, md: 3 }}
            isLoading={isLoadingContractData}
            body={activeCustomerContract?.customer_contract_term_length || "-"}
          />
          <PageText
            label="First Fill Date"
            GridProps={{ xs: 6, md: 3 }}
            isLoading={isLoadingContractData}
            body={activeCustomerContract?.first_fill_date || "-"}
          />
          <PageText
            label="Contract End Date"
            GridProps={{ xs: 6, md: 3 }}
            isLoading={isLoadingContractData}
            body={activeCustomerContract?.customer_contract_end_date || "-"}
          />
        </MDividerSection>

        {/* ------ Pricing Rate Section -------- */}
        <Restricted to={[permissions.WRITE_CUSTOMER_CONTRACT_RATES, permissions.READ_CUSTOMER_CONTRACT_RATES]}>

          <MDividerSection
            GridProps={{ mt: 10 }}
            position='list-middle'
            direction='row'
            variant='section'
          >
            <Grid container item xs={12} md={12} mb={1} alignItems="right" justifyContent="space-between">
              <Typography
                align="left"
                children={"Pricing Rate"}
                variant={"h5"}
                sx={{ color: "secondary.dark" }}
              />
              <Restricted to={[permissions.WRITE_CUSTOMER_CONTRACT_RATES]}>
                <MButton
                  GridProps
                  collapseXs
                  disabled={isLoading}
                  size="small"
                  icon="add"
                  variant="outlined"
                  color="primary"
                  tooltip={{ title: "New Pricing Rate" }}
                  onClick={handleAddPricingRate}
                  children="Add Rate"
                />
              </Restricted>
            </Grid>
            <FormGroup>
                <FormControlLabel sx={{ pl: 3 }}
                  label={showAll ? "Filter effective rates" : "Show all rates"}
                  control={<Switch
                    color="primary"
                    checked={showAll}
                    onChange={handleToggleShowAll}
                  />}
                />
              </FormGroup>
          </MDividerSection>
          {!isLoadingContractData && activePricingRates &&
            <Grid
              item
              xs={12}
              md={12}
              container
              direction="column"
              sx={{ px: 1 }}
            >
              <PricingRateList
                items={activePricingRates}
                handleDeleteRate={handleDeleteRate}
                showEffective={!showAll}
              />
            </Grid>
          }
        </Restricted>

        {/* ------ Pricing Minimum Section -------- */}
        <Restricted to={[permissions.WRITE_CUSTOMER_CONTRACT_MINIMUMS, permissions.READ_CUSTOMER_CONTRACT_MINIMUMS]}>
          <MDividerSection
            GridProps={{ mt: 10 }}
            position='list-middle'
            direction='row'
            variant='section'
          >
            <Grid container item xs={12} md={12} mb={2} alignItems="right" justifyContent="space-between">
              <Typography
                align="left"
                children={"Pricing Minimum"}
                variant={"h5"}
                sx={{ color: "secondary.dark" }}
              />
              <Restricted to={[permissions.WRITE_CUSTOMER_CONTRACT_MINIMUMS]}>
                <MButton
                  GridProps
                  collapseXs
                  disabled={isLoading}
                  size="small"
                  icon="add"
                  variant="outlined"
                  color="primary"
                  tooltip={{ title: "New Pricing Minimum" }}
                  onClick={handleAddPricingMinimum}
                  children="Add Minimum"
                />
              </Restricted>
            </Grid>
          </MDividerSection>
          {!isLoadingContractData && activePricingMinimums &&
            <Grid
              item
              xs={12}
              md={12}
              container
              direction="column"
              sx={{ px: 1 }}
            >
              <PricingMinimumList
                items={activePricingMinimums}
                handleDeleteMinimum={handleDeleteMinimum}
              />
            </Grid>
          }
        </Restricted>
        {/* ------ Pricing Fee Section -------- */}
        <Restricted to={[permissions.WRITE_CUSTOMER_CONTRACT_PRICING_FEES, permissions.READ_CUSTOMER_CONTRACT_PRICING_FEES]}>
          <MDividerSection
            GridProps={{ mt: 10 }}
            position='list-middle'
            direction='row'
            variant='section'
          >
            <Grid container item xs={12} md={12} alignItems="right" justifyContent="space-between">
              <Typography
                align="left"
                children={"Pricing Fee"}
                variant={"h5"}
                sx={{ color: "secondary.dark" }}
              />
              <Restricted to={[permissions.WRITE_CUSTOMER_CONTRACT_PRICING_FEES]}>
                <MButton
                  GridProps
                  collapseXs
                  disabled={isLoading}
                  size="small"
                  icon="add"
                  variant="outlined"
                  color="primary"
                  tooltip={{ title: "New Pricing Fee" }}
                  onClick={handleAddPricingFee}
                  children="Add Fee"
                />
              </Restricted>
            </Grid>
          </MDividerSection>
          {!isLoadingContractData && activePricingFees &&
            <Grid
              item
              xs={12}
              md={12}
              container
              direction="column"
              sx={{ px: 1 }}
            >
              <PricingFeeList
                items={activePricingFees}
                handleDeleteFee={handleDeleteFee}
              />
            </Grid>
          }
        </Restricted>
      </>
    }
  </PageSection>
  )
}