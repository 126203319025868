/* eslint-disable react-hooks/exhaustive-deps */
import { dateHelper } from '../helpers';
import createEntityContext from '../helpers/createEntityContext';

const {
    Context: DeliveryTicketContext,
    Provider: DeliveryTicketContextProvider,
} = createEntityContext({
    entityType: "delivery_ticket",
    defaultEntity: {
      jira_ticket_number: null,
      delivery_ticket_summary: null,
      delivery_ticket_status: null,
      delivery_ticket_created_at: null,
      late: null,
      requested_delivery_window_start: null,
      requested_delivery_window_end: null,
      confirmed_delivery_window_start: "",
      confirmed_delivery_window_end: false,
      split_load_type: null,
      split_quantity: null,
      delivery_recognized_by_seeq: null,
      delivery_end_date_seeq: null,
      delivery_quantity_seeq: null,
      alternate_delivery_recognition_method: null,
    },
    queryOpts: {
        start_date: {
            defaultValue: dateHelper.getBackDays(new Date(), 2),
        },
        end_date: {
            defaultValue: "",
        },
        site_uuid: {
          defaultValue: "",
          options: [],
      }
    },
    includeQueryParams: true,
    queryValues: {
        start_date: dateHelper.getBackDays(new Date(), 2),
        end_date: dateHelper.getBackDays(dateHelper.getToday(), -1),
    },
    filterEntity: (entity, api) => {
        return true;
    }
})

export {
  DeliveryTicketContext,
  DeliveryTicketContextProvider,
}
export default DeliveryTicketContext;    