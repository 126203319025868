import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';

MSelect.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
    })),
    includeAll: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    GridProps: PropTypes.object,
}

/**
 * 
 * @param {{
 * onChange: (event, selectedOption?) => void, 
 * }} param0 
 * @returns 
 */
export default function MSelect({ options = [], label, includeAll, sx, inputProps, GridProps = { xs: 12 }, value, onChange, variant, ...props }) {
    const classes = useStyles();

    const handleChange = (event, optionTextField) => {
        // console.log("event", event);
        // console.log("rest", optionTextField);
        if (onChange) {
            const item = options.find(o => o.value === event.target?.value && o.label === optionTextField.props?.name);
            onChange(event, item);
        }
    };
    const defaultValue = typeof includeAll === "string" ? '' : " ";

    const items = includeAll ? [{ value: defaultValue, label: typeof includeAll === "string" ? includeAll : 'All' }, ...options] : options || [];


    return <Grid item {...GridProps} >
        <FormControl
            sx={{ width: '100%', mt: 1, }}
        >
            <InputLabel id={`select-label-${label}`}>{label}</InputLabel>
            <Select
                size={props.size}
                fullWidth
                variant={variant}
                sx={sx}
                inputProps={inputProps}
                value={value !== undefined && value !== null? value : defaultValue}
                label={label}
                className={classes.backgroundColorOnInnerBoxOfCustomIcon}

                disabled={props.disabled}
                labelId={`select-label-${label}`}
                onChange={handleChange}
            >
                {items?.map((option, index) =>
                    <MenuItem key={index}
                        name={option.label}
                        value={option.value}>{option.label}</MenuItem>
                )}
            </Select>
            {props?.helperText && <FormHelperText>{props?.helperText}</FormHelperText>}
        </FormControl>
    </Grid>;
}


const useStyles = makeStyles((theme) => ({
    backgroundColorOnInnerBoxOfCustomIcon: {
        '&.Mui-disabled': {
            pointerEvents: 'auto',
            '&:hover': {
                backgroundColor: 'transparent',
            },
            cursor: 'not-allowed',
            '& .MuiSvgIcon-root .innerBox': {
                fill: '#eee',
            },
        },
    },
    backgroundColorOnWholeIcon: {
        '&.Mui-disabled': {
            pointerEvents: 'auto',
            '&:hover': {
                backgroundColor: 'transparent',
            },
            cursor: 'not-allowed',
            '& .MuiSvgIcon-root': {
                backgroundColor: '#eee',
            },
        },
    },
}));