import React from 'react';
import PageSection from '../../../templates/PageSection';
import {
  MButton,
} from '../../../@material-extend';
import { useNavigateTo } from '../../../../helpers';
import PageText from '../../../templates/PageText';
import { routeConfig } from '../../../../configs';
import Restricted from '../../../Restricted';
import { permissions } from '../../../../configs/permissionConstants';

/**
 * @param {{
 *    isLoading: boolean;
 *    siteView: {};
 * }} props
 * @returns 
 */
export default function SitePlantDetailSection({ isLoading, siteView }) {
  const [, navigateTo] = useNavigateTo();

  const handleEditSection = () => {
    navigateTo(routeConfig.site_edit_plant_details);
  }

  return (<PageSection
    title="Plant Details"
    omitContainer
    endActions={
      <Restricted to={[permissions.WRITE_PLANT_DETAILS]}>
        <MButton
          GridProps
          collapseXs
          size="small"
          icon="edit"
          variant="outlined"
          color="primary"
          disabled={isLoading}
          tooltip={{ title: "Tank Details" }}
          onClick={handleEditSection}
          children="Edit Plant Details"
        />
      </Restricted>
    }
    spacing={2}
    collapsible
  >

    <PageText
      label="Plant Type"
      GridProps={{ xs: 6, }}
      body={siteView?.plant_type_description || "-"}
    />
    <PageText
      label="Piping Type"
      GridProps={{ xs: 6, }}
      body={siteView?.piping_type_description || "-"}
    />

    <PageText
      label="Application Type"
      GridProps={{ xs: 6, }}
      body={siteView?.application_description || "-"}
    />
    <PageText
      label="Number of Lanes"
      GridProps={{ xs: 6, }}
      body={siteView?.number_of_lanes || "-"}
    />
    <PageText
      label="Number of NAT Boxes"
      GridProps={{ xs: 6, }}
      body={siteView?.nat_box_count || "-"}
    />
  </PageSection>)
}
