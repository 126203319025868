import { useEffect, useContext, useState } from 'react';
import { useForm } from "react-hook-form";
import { MForm } from '../../@material-extend';
import { LayoutContext, useCustomerContractContext, LookupContext } from '../../../contexts';
import { dateHelper } from '../../../helpers';
import { Backdrop, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { MFormTextField, MFormSelect, MFormDatePicker } from '../../@material-extend';
import MDividerSection from '../../@material-extend/MDividerSection';
import { routeConfig } from '../../../configs';
import { useNavigateTo } from '../../../helpers';


SiteCustomerContractForm.propTypes = {
  form_data: PropTypes.object,
}

export function SiteCustomerContractForm(props) {
  const customerContractContext = useCustomerContractContext();
  const [showBackdrop, setShowBackdrop] = useState(false);
  const { form_data } = props
  const lookup = useContext(LookupContext);
  const [params, navigateTo] = useNavigateTo();
  const layout = useContext(LayoutContext);

  const form = useForm({
    defaultValues: customerContractContext.defaultValues,
  });

  useEffect(() => {
    if (params.site_uuid && !form_data?.site_uuid) {
      form.setValue("site_uuid", params.site_uuid);
    }
  }, [form, params.site_uuid, form_data])

  useEffect(() => {
    if (params.site_uuid && !form_data?.site_uuid) {
      form.setValue("site_uuid", params.site_uuid);
    }
  }, [form, params.site_uuid, form_data])

  useEffect(() => {
    if (form_data) {
      if (form_data.first_fill_date) {
        form_data.first_fill_date = new Date(form_data.first_fill_date.replace(/-/g, '/'));
      }
      if (form_data.customer_contract_effective_date) {
        form_data.customer_contract_effective_date = new Date(form_data.customer_contract_effective_date.replace(/-/g, '/'));
      }
      form.reset(form_data);
    }
  }, [form_data, form]);

  const handleValidateForm = (data) => {
    let errorMessage = null;
    if (!data.customer_contract_status) {
      data.customer_contract_status = null;
      errorMessage = "Choose a contract status.";
    }
    else if (!data.customer_contract_description) {
      data.customer_contract_description = null;
      errorMessage = "Provide a contract description.";
    }
    else if (!data.pricing_model) {
      data.pricing_model = null;
      errorMessage = "Pricing model is required.";
    }
    else if (!data.customer_contract_effective_date) {
      data.customer_contract_effective_date = null;
      errorMessage = "Select a contract effective date.";
    }
    else if (!data.customer_contract_term_length) {
      data.customer_contract_term_length = null;
      errorMessage = "Contract term length is required.";
    }    
    else if (!data.customer_contract_rate_terms) {
      data.customer_contract_rate_terms = null;
      errorMessage = "Contract rate terms is required.";
    }

    data.customer_contract_effective_date = dateHelper.formatDate(data.customer_contract_effective_date);
    if (data.first_fill_date) {
      data.first_fill_date = dateHelper.formatDate(data.first_fill_date);      
    }

    if (errorMessage) {
      layout.handleError(errorMessage);
      return false;
    }
    return true;
  }

  function onSubmit(data) {
    const isValidForm = handleValidateForm(data);
    if (!isValidForm) {
      return;
    }
    return customerContractContext.upsertEntity(data)
      .then(res => {
        console.log("Saved customer contract", res);
        setShowBackdrop(false);
          navigateTo(routeConfig.site_view);
      });
  }

  return (<MForm
    form={form}
    defaultValues={customerContractContext.defaultValues}
    onSubmit={onSubmit}
    submitText={"Save Contract"}
  >
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={showBackdrop}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <MDividerSection
      position="form"
      above={{ title: "Customer Contract" }}
    >
      <MFormSelect
        autoFocus
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        GridProps={{
          xs: 6,
        }}
        name="customer_contract_status"
        includeAll="-- Unselect --"
        options={lookup.values.customer_contract_status}
        label="Contract Status"
        required
      />
      <MFormTextField
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        minRows={2}
        name="customer_contract_description"
        label="Contract Description"
        multiline
        fullWidth
        required
      />
      <MFormSelect
        autoFocus
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        GridProps={{
          xs: 12,
        }}
        name="pricing_model"
        includeAll="-- Unselect --"
        options={lookup.values.pricing_model}
        label="Pricing Model"
        required
      />
      <MFormDatePicker
        name="customer_contract_effective_date"
        label="Contract Effective Date"
        clearable
        required
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        GridProps={{
          xs: 12,
          md: 6,
        }}
      />
      <MFormTextField
        name="customer_contract_term_length"
        label="Contract Term Length (Months)"
        required
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        type="number"
        GridProps={{
          xs: 12,
          md: 6,
        }}
      />
      <MFormTextField
        name="customer_contract_rate_terms"
        label="Contract Rate Terms (Days)"
        required
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        GridProps={{
          xs: 12,
          md: 6,
        }}
      />
      <MFormTextField
        name="customer_contract_number"
        label="Contract Number"
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        GridProps={{
          xs: 12,
          md: 6,
        }}
      />
      <MFormDatePicker
        name="first_fill_date"
        label="First Fill Date"
        clearable
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        GridProps={{
          xs: 12,
          md: 6,
        }}
      />
    </MDividerSection>
  </MForm>
  );
}