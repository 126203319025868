import { useController } from 'react-hook-form'
import React from 'react';
import PropTypes from 'prop-types';
import MCheckbox from './MCheckbox';

MFormCheckbox.propTypes = {
    GridProps: PropTypes.object,
    control: PropTypes.object.isRequired,
    sx: PropTypes.object,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    multiline: PropTypes.bool,
    minRows: PropTypes.number,
    required: PropTypes.bool,
    fullWidth: PropTypes.bool,
    labelPlacement: PropTypes.string,
    autoFocus: PropTypes.bool,
    onControlEnter: PropTypes.func,
    FormHelperTextProps: PropTypes.object,
}

function MFormCheckbox({ control, defaultValue, name, ...rest }) {

    const { field: { value, onChange, } } = useController({
        control,
        name,
        defaultValue,
    })


    function handleChange(event, ...other) {
        onChange(event)
    }


    return <MCheckbox
        {...rest}
        value={value}
        checked={value}
        onChange={handleChange}
    />;


};




export default MFormCheckbox