
export const MDisplay = {
    /**
     * Gets display text props from an array.
     * @param displayProps <T extends TDisplayProps>
     * @param type 
     */
    unwrap: (displayProps, type = "list-item") => {

        if (typeof displayProps.text === "string") {
            displayProps = {
                ...displayProps,
                text: displayProps.text || "",
            }
        }

        const { text, variants, ...rest } = displayProps;

        let result = {
            title: "",
            subtitle: undefined,
            label: undefined,
            variant: (variants || "body1"),
            gutterBottom: false,
            divider: false,
            color: "textPrimary",
            inline: false,
            align: "left",
            underline: false,
            multiline: false,
            ...rest,
        };

        if (!displayProps.text) {
            return result;
        }


        const textArray = Array.isArray(displayProps.text)
            ? displayProps.text
            : typeof displayProps.text === "string" ? [displayProps.text]
                : typeof displayProps.text.text === "string"
                    ? [displayProps.text.text]
                    : displayProps.text.text || [];

        switch (type) {
            case "list-item":
                result = {
                    ...result,
                    title: displayProps.title || textArray[0],
                    subtitle: displayProps.subtitle || textArray.length > 1 ? textArray[1] : undefined,
                    label: textArray.length > 2 ? textArray[2] : undefined,
                }
                break;
            default:
                result = {
                    ...result,
                    title: "",
                    subtitle: undefined,
                    label: undefined,
                }
                break;
        }
        return result;
    },


    /**
     * ## Get MUI Props 
     * Strips non-mui props from an object.
     * @param props 
     */
    mui: (props) => {
        const {
            viewId,
            pathType,
            isSelected,
            delivery_restricted,
            nitro_deliver,
            expanded,
            tank_rental,
            title,
            subtitle,
            disableButton,
            link,
            createdBy,
            createdOn,
            isLocked,
            updatedBy,
            updatedOn,
            partitionKey,
            noPaper,
            fieldProps,
            buttonProps,
            fieldKey,
            lookupKey,
            itemNamePlural,
            secondaryAction,
            itemNameSingle,
            business_unit,
            ...muiProps
        } = props;

        return muiProps;
    }
}