import * as React from 'react';
import MuiList from '@mui/material/List';
import { MListItem } from './MListItem';
import PropTypes from 'prop-types';

MList.propTypes = {
    selectedId: PropTypes.string,
}

/**
 * Dynamic, powerful, be-all and end-all, 'list to end all lists' list component.
 * @param props 
 * @returns 
 */
function MList({ items, sx, GridProps, selectedId, component: Component = MListItem, startElements, endElements, divider, injectedProps, onClick, ...rest }) {

    const [checked, setChecked] = React.useState([1]);

    const createHandleSelect = (item) => () => {
        const value = item.id;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        onClick && onClick(item);
        setChecked(newChecked);
    }

    return (
        <MuiList
            {...rest}
            sx={{ width: 1, my: 0, py: 0, ...sx }}
        >
            {startElements}
            {items.map((item, index) => {
                const itemOnClick = onClick ? () => onClick(item) : undefined;
                return <Component
                    {...injectedProps}
                    {...item}
                    selected={item.id === selectedId}
                    id={`list-` + item.id}
                    divider={divider}
                    key={`li-` + index}
                    isSelected={checked.indexOf(item.id) !== -1}
                    onClick={itemOnClick}
                    onSelect={createHandleSelect(item)}
                />
            })}
            {endElements}

        </MuiList>
    );
}

export default MList;