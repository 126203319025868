import React, { useState } from 'react';

const defaultValue = {
    /**
     * Queue of errors
     */
    errors: [

    ],
    /**
     * 
     * @param {{message: string} | string} err 
     */
    pushError: (err) => { },
    removeError: (errId) => { },
}
/**
 * # Error Context
 * Properties:
 * - errors: `[{ message: string, index: string|number }]`
 * - pushError: `({message: string}) => void`
 * - removeError: `(errorId) => void`;
 */
export const ErrorContext = React.createContext(defaultValue);

export const ErrorContextProvider = (props) => {

    const [errors, setErrors] = useState(defaultValue.errors);
    const [errorCount, setErrorCount] = useState(0);

    /**
     * Push errors with a `message`
     * @param {*} newError 
     */
    const pushError = (newErr) => {
        const error = typeof newErr === 'string' 
            ? {message: newErr}
            : newErr;
       
        if (!error.message) {
            console.error("Please provide an error message when calling `setErr()`: " + JSON.stringify(newErr));
        }
        setErrors(errors.concat({ ...error, id: errorCount }))
        setErrorCount(errorCount + 1);
    }

    const removeError = (errorId) => {
        setErrors(errors.filter(e => e.id !== errorId));
    }

    return <ErrorContext.Provider
        value={{
            errors: errors,
            pushError: pushError,
            removeError: removeError,
        }}
    >
        {props.children}
    </ErrorContext.Provider>
}

export default ErrorContext;