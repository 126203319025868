/* eslint-disable react-hooks/exhaustive-deps */
import createEntityContext from '../helpers/createEntityContext';

const EntityContext = createEntityContext({
    entityType: "pricing_minimum",
    defaultEntity: {
        annual_minimum_date: "",
        minimum_quantity: null,
        minimum_type: "",
        pricing_minimum_uuid: "",
        customer_contract_uuid: "",
        pricing_minimum_description: "",
        pricing_minimum_effective_date: "",
    },
});

export const PricingMinimumContext = EntityContext.Context;
export const PricingMinimumContextProvider = EntityContext.Provider;
export const usePricingMinimumContext = EntityContext.useContext;