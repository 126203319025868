import React from 'react';
import { Grid, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import { GridProps } from './MProps';
import BackIcon from '@mui/icons-material/NavigateBefore';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';

/**
 * @param {{
 *      icon: "back" | "edit" | "add" | "delete" | "expand" | "up" ,
 * }} props 
 * @returns 
 */
const MIconButton = styled((props) => {
    const { rotateDegrees, tooltip, GridProps, icon, ...other } = props;

    let iconChild = other.children;

    if (!!icon) {
        switch (icon) {
            case "back": {
                iconChild = <BackIcon fontSize="inherit" />;
                break;
            }
            case "add": {
                iconChild = <AddCircleIcon fontSize="inherit" />;
                break;
            }
            case "delete": {
                iconChild = <DeleteIcon fontSize="inherit" />;
                break;
            }
            case "edit": {
                iconChild = <EditIcon fontSize="inherit" />;
                break;
            }
            case "expand": {
                iconChild = <ExpandMoreIcon fontSize="inherit" />;
                break;
            }
            case "up": {
                iconChild = <ArrowCircleUpIcon fontSize="inherit" />;
                break;
            }
            default: {
                break;
            }
        }
    }

    let result = <IconButton {...other} children={iconChild} />;

    if (tooltip) {

        let tooltipProps = typeof tooltip === 'string' ?
            {
                placement: "top",
                title: tooltip
            } :
            {
                placement: "top",
                ...tooltip,
            };

        if (tooltipProps.tooltip) {
            delete tooltipProps.tooltip;
        }
        result = <Tooltip {...tooltipProps} children={result} />
    }

    if (GridProps) {
        result = <Grid {...GridProps} children={result} />;
    }
    return result;
})(({ theme, rotateDegrees }) => ({
    transform: rotateDegrees && `rotate(${rotateDegrees}deg)`,
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

MIconButton.propTypes = {
    icon: PropTypes.oneOf(["back", "add", "delete", "edit", "expand"]),
    rotateDegrees: PropTypes.number,
    tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    GridProps: GridProps,
}

export default MIconButton