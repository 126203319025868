import React, { useEffect, useState } from 'react';
import { MList, MListItemCustom } from '../@material-extend';
import { Paper, Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import MoreMenu from '../MoreMenu';
import { StringParser } from '../../helpers';
import Restricted from '../Restricted';
import { permissions } from '../../configs/permissionConstants';
import { confirmDialog } from '../ConfirmDialog';

PricingRateList.propTypes = {
  items: PropTypes.array,
  handleDeleteRate: PropTypes.func,
}

function PricingRateList(props) {
  const [pricingRates, setPricingRates] = useState([])

  useEffect(() => {
    if (props.items) {
      const pricingRates = props.items;

      // sort rates by effective date in descending order
      let sortedRates = pricingRates.sort((a, b) => {
        return a.effective_date > b.effective_date ? -1 : 1
      })

      // mark the effective rate
      let today = new Date().toISOString().slice(0, 10);
      let found_effective = false;
      for (const rate of sortedRates) {
        if (!found_effective && (rate.effective_date <= today)) {
          found_effective = true;
          rate.effective = true
        }
        else {
          rate.effective = false
        }
      }

      if (props.showEffective) {
        sortedRates = sortedRates.filter(x => x.effective === true);
      }

      const newPricingRates =
        sortedRates.map(rate => {
          const displayRate = {
            ...rate,
            effective_date: rate?.effective_date || "-",
            tank_delivery_rate: StringParser.formatCurrency(rate?.tank_delivery_rate) || "-",
            usage_rate: StringParser.formatCurrency(rate?.usage_rate) || "-",
          }
          return {
            id: displayRate.id,
            sx: { p: 5 },
            left: {
              title: displayRate.pricing_rate_description,
              subtitles: [
                (`Tank Fill Rate: ${displayRate.tank_delivery_rate}`),
                (`Usage Rate: ${displayRate.usage_rate}`),
                (`Effective Date: ${displayRate.effective_date}`),
              ]
            },
            right: {
              children: <Grid container justifyContent="flex-end" alignItems={"center"}>
                <Restricted to={[permissions.WRITE_CUSTOMER_CONTRACT_RATES]}>
                  <MoreMenu
                    data={{ uuid: displayRate.pricing_rate_uuid }}
                    GridProps={{ item: true, xs: 2 }}
                    options={[
                      {
                        label: 'Delete Rate',
                        icon: <DeleteIcon sx={{ color: "error.main" }} />,
                        onClick: (data) => {
                          confirmDialog("You should NOT BE DELETING this data in the case of a new contract, rate increase, nor any other amendment.  NITROvision maintains a historical record of data.  The “effective date” is used to recognized the active contract data.  Do you want to delete the pricing rate?", () => props.handleDeleteRate(data))
                        }
                      },
                    ]}
                  />
                </Restricted>
              </Grid>
            }
          }
        });

      if (newPricingRates !== pricingRates) {
        setPricingRates(newPricingRates.length === 0 ? [{
          id: "add",
          sx: { p: 2 },
          left: { title: "  No pricing rates", },
        }] : newPricingRates);
      }
    }
  }, [props]);

  return (

    <MList
      dense
      items={pricingRates.length > 0 ? pricingRates : [{
        disableButton: true,
        sx: { p: 2 },
        left: { title: "  No pricing rates", },
      }]}
      component={item => <MListItemCustom
        component={Paper}
        elevation={5}
        GridProps={{ sx: { mb: 2, p: 2 } }}
        {...item}
        sx={{
          py: 1,
          px: 2,
          ...item.sx,
          bgcolor: "theme.background.paper",
          color: "theme.text.primary",
        }}
      />
      }
    />
  );
}

export default PricingRateList;