/* eslint-disable react-hooks/exhaustive-deps */
import { dateHelper } from '../helpers';
import createEntityContext from '../helpers/createEntityContext';

const {
    Context: TankDeliveryEventContext,
    Provider: TankDeliveryEventContextProvider,
} = createEntityContext({
    entityType: "tank_delivery_event",
    defaultEntity: {
      jira_completed: false,
      jira_ticket_number: null,
      manual_entry: null,
      site_uuid: null,
      supplier_bill_item_uuid: null,
      tank_delivery_begin_timestamp: "",
      tank_delivery_date: null,
      tank_delivery_duration: null,
      tank_delivery_end_timestamp: "",
      tank_delivery_event_active: false,
      tank_delivery_quantity: null,
      tank_level_begin: null,
      tank_level_end: null,
      manual_update_by: null,
      manual_update_timestamp: null,
      update_history: null,
      fill_quantity_reviewed: null,
    },
    queryOpts: {
        start_date: {
            defaultValue: dateHelper.getBackDays(new Date(), 2),
        },
        end_date: {
            defaultValue: "",
        },
        site_uuid: {
          defaultValue: "",
          options: [],
      }
    },
    includeQueryParams: true,
    queryValues: {
        start_date: dateHelper.getBackDays(new Date(), 2),
        end_date: dateHelper.getBackDays(dateHelper.getToday(), -1),
    },
    filterEntity: (entity, api) => {
        return true;
    }
})

export {
  TankDeliveryEventContext,
  TankDeliveryEventContextProvider,
}
export default TankDeliveryEventContext;    