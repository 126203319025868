import React from 'react';
import PageSection from '../../../templates/PageSection';
import {
  MButton,
} from '../../../@material-extend';
import { useNavigateTo } from '../../../../helpers';
import PageText from '../../../templates/PageText';
import { routeConfig } from '../../../../configs';
import Restricted from '../../../Restricted';
import { permissions } from '../../../../configs/permissionConstants';

/**
 * @param {{
 *    isLoading: boolean;
 *    siteView: {};
 * }} props
 * @returns 
 */
export default function SiteTankAccessPlanningSection({ isLoading, siteView }) {
  const [, navigateTo] = useNavigateTo();

  const handleEditSection = () => {
    navigateTo(routeConfig.site_edit_tank_access);
  }

  return (<PageSection
    title="Tank Access / Planning"
    omitContainer
    endActions={
      <Restricted to={[permissions.WRITE_TANK_ACCESS]}>
        <MButton
          GridProps
          collapseXs
          size="small"
          icon="edit"
          variant="outlined"
          color="primary"
          disabled={isLoading}
          tooltip={{ title: "Tank Access" }}
          onClick={handleEditSection}
          children="Edit Tank Access"
        />
      </Restricted>
    }
    spacing={2}
    collapsible
  >
    <PageText
      label="Access Instructions"
      GridProps={{ xs: 12 }}
      body={siteView?.access_instructions || "-"}
    />
    <PageText
      label="Restricted Hours"
      GridProps={{ xs: 6, }}
      body={siteView?.delivery_restricted ? "Yes" : "No"}
    />
    <PageText
      label="Tank Tricock Capacity"
      GridProps={{ xs: 6, lg: 4 }}
      body={siteView?.tank_tricock_capacity ? `${siteView?.tank_tricock_capacity} ${siteView?.unit_of_measure}` : "-"}
    />

  </PageSection>)
}
