import { useForm } from "react-hook-form";
import { useEffect } from 'react';
import { routeConfig } from '../../configs';
import { MForm, MFormTextField } from '../@material-extend';
import { useRegionalCompanyContext } from "../../contexts";
import { useNavigateTo } from "../../helpers";

const defaultValues = {
  regional_company_name: "",
  parent_company_uuid: "",
};

export function RegionalCompanyForm(props) {
  const regionalCompanyContext = useRegionalCompanyContext();
  const [params, navigateTo] = useNavigateTo();
  const form = useForm({
    defaultValues: defaultValues
  });

  function onSubmit(data) {
    data.regional_company_name = data.regional_company_name.trim()
    regionalCompanyContext.upsertEntity(data).then(res => {
      navigateTo(routeConfig.region_view, { regional_company_uuid: res.id });
    })
  }

  useEffect(() => {
    if (params.parent_company_uuid && !props.form_data?.parent_company_uuid) {
      form.setValue("parent_company_uuid", params.parent_company_uuid);
    }
  }, [params.parent_company_uuid, form, props])

  useEffect(() => {
    if (props.form_data) {
      form.reset(props.form_data)
    }
  }, [props.form_data, form])

  return (<MForm
    defaultValues={defaultValues}
    onSubmit={onSubmit}
    form={form}
  >
    <MFormTextField
      control={form.control}
      autoFocus
      onControlEnter={form.handleSubmit(onSubmit)}
      name="regional_company_name"
      label="Regional Company Name"
      fullWidth
      required
    />
  </MForm >

  );
}