/**
 * ## Common Utilities
 * Wrapper for common utilities for arrays and objects.
 */
const Common = {
    /**
     * Merge values from mergeObjs onto obj.
     * @param {*} obj 
     * @param  {...any} mergeObjs 
     * @returns 
     */
    mergeObject(obj, ...mergeObjs) {
        if (typeof obj === "object") {
            mergeObjs.forEach(mergeObject => {
                if (typeof mergeObject === "object")
                    for (let key in mergeObject) {
                        (obj)[key] = mergeObject[key];
                    }
            })
        }
        return obj;
    },

    /**
     * Merge `arrB` into `arrA`. 
     * @param {T[]} arrA existing values
     * @param {T[]} arrB incoming values
     * @param {(a:T,b:T)=>bool} isMatch matching function to trigger element merge
     * @returns {T[]} new array composed of the union of arrA and arrB
     */
    mergeArray(arrA, arrB, isMatch) {
        const result = arrA.slice();
        arrB.forEach(b => {
            const matchingIndex = result.findIndex(a => isMatch(a, b));
            if (matchingIndex > -1) {
                result[matchingIndex] = {
                    ...result[matchingIndex],
                    ...b
                };
            } else {
                result.push(b);
            }
        });
        return result;
    }
}

export default Common;