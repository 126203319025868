import React from 'react';
import LayoutContext from '../../../../contexts/LayoutContext';
import { routeConfig } from '../../../../configs';
import PageSection from '../../../templates/PageSection';
import { useContext, useState } from 'react';
import { Grid } from '@mui/material';
import { MButton, MDivider } from '../../../@material-extend';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import BusinessUnitContactList from '../../../lists/BusinessUnitContactList';
import SiteContactList from '../../../lists/SiteContactList';
import { useNavigateTo } from '../../../../helpers';
import Restricted from '../../../Restricted';
import { permissions } from '../../../../configs/permissionConstants';


export default function SiteContactsSection({ isLoading }) {
  const [, navigateTo] = useNavigateTo();
  const [showBusinessContacts, setShowBusinessContacts] = useState(false);

  const { windowDimensions } = useContext(LayoutContext);

  const handleAddContact = () => {
    navigateTo(routeConfig.site_contact_add);
  }

  const handleToggleShowBusinessContacts = () => {
    setShowBusinessContacts(!showBusinessContacts);
  }
  return (<PageSection
    title="Site Contacts"
    justifyContent='center'
    direction={"row"}
    collapsible
    endActions={
      <Restricted to={[permissions.WRITE_SITE_CONTACTS, permissions.WRITE_SUPPLIER_CONTACTS]}>
        <MButton
          GridProps
          collapseXs
          disabled={isLoading}
          size="small"
          icon="add"
          variant="outlined"
          color="primary"
          onClick={handleAddContact}
          children="Add Site Contact"
        />
      </Restricted>
    }
    action={<Grid container justifyContent={!windowDimensions.isMobile ? "space-between" : "flex-end"} alignItems="flex-end">
      {!windowDimensions.isMobile && <FormGroup sx={{ pl: 3 }}>
        <FormControlLabel
          label={showBusinessContacts ? "Hide business unit contacts" : "Show business unit contacts"}
          control={<Switch
            color="primary"
            checked={showBusinessContacts}
            onChange={handleToggleShowBusinessContacts}
          />}
        />
      </FormGroup>}
    </Grid>
    }
  >

    {showBusinessContacts
      && <Grid
        item
        xs={6}
        md={6}
        container
        direction="column"
        sx={{ px: 1 }}
      >
        <MDivider above={{ title: "Business Unit Contacts" }} sx={{ mb: 1 }} />
        <BusinessUnitContactList hidden={!showBusinessContacts} transferList={showBusinessContacts} isSitePage />
      </Grid>}

    <Grid
      item
      xs={showBusinessContacts ? 6 : 12}
      md={showBusinessContacts ? 6 : 12}
      container
      direction="column"
      sx={{ px: 1 }}
    >
      <MDivider above={{ title: "Site Contacts" }} sx={{ mb: 1 }} />
      <SiteContactList transferList={showBusinessContacts} />
    </Grid>
  </PageSection>
  );
}