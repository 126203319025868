/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import Page from '../../templates/Page';
import PageSection from '../../templates/PageSection';
import { routeConfig } from '../../../configs';
import { ButtonGroup } from '@mui/material';
import { MButton, MDivider, MList } from '../../@material-extend';
import { LookupContext, TankDeliveryEventContext } from '../../../contexts';
import { useNavigateTo } from '../../../helpers';
import PageText from '../../templates/PageText';
import { dateHelper } from '../../../helpers/dateHelper';
import { MForm, MFormTextField } from '../../@material-extend';
import { useForm } from "react-hook-form";
import Restricted from '../../Restricted';
import { permissions } from '../../../configs/permissionConstants';
import { useAuth0 } from '@auth0/auth0-react';
import { confirmDialog } from '../../ConfirmDialog';
import { StringParser } from '../../../helpers';



export default function TankDeliveryEvent() {

  const {
    activeEntity,
    setActiveEntity,
    upsertEntity,
  } = useContext(TankDeliveryEventContext);

  const [params, navigateTo] = useNavigateTo();
  const { user } = useAuth0();
  const tank_delivery_event_uuid = params.tank_delivery_event_uuid;
  const [items, setItems] = useState([]);

  function newItem(nickname, change, reason) {
    var theDate = new Date()
    return {
      data: theDate.toISOString(),
      id: theDate.toISOString(),
      title: dateHelper.formatLocalDateTimeDash(theDate).concat(' | ', nickname, ' | ', change, ':', reason)
    }
  }

  function suggestedAI(pod_quantity, post_fill_ai, pre_fill_ai) {
    if ((!pod_quantity) || (!post_fill_ai) || (!pre_fill_ai)) {
      return "-";
    }
    return StringParser.formatNumerical(pod_quantity / (post_fill_ai - pre_fill_ai), {decimalPlaces: 3});
  }

  function manual_tank_level_delta(tank_level_end_manual, tank_level_begin_manual) {
    if ((!tank_level_end_manual) || (!tank_level_begin_manual)) {
      return "-";
    }
    return parseInt(tank_level_end_manual)-parseInt(tank_level_begin_manual);
  }

  const handleToggleEventActive = (updateReason) => {
    let isActive = !activeEntity.tank_delivery_event_active
    let change = (isActive) ? "Activate Event" : "Inactivate Event";
    return upsertEntity({ ...activeEntity, tank_delivery_event_active: isActive, manual_update_by: user.nickname, update_history: change.concat(":", updateReason) }).then(() => {
      setActiveEntity(tank_delivery_event_uuid);
      items.unshift(newItem(user.nickname, change, updateReason))
    })
  }

  const handleToggleFillQtyReviewed = () => {
    let isReviewed = !activeEntity.fill_quantity_reviewed
    let change = (isReviewed) ? "Mark qty reviewed" : "Mark qty not reviewed";
    return upsertEntity({ ...activeEntity, fill_quantity_reviewed: isReviewed, manual_update_by: user.nickname, update_history: change.concat(":", isReviewed) }).then(() => {
      setActiveEntity(tank_delivery_event_uuid);
      items.unshift(newItem(user.nickname, change, isReviewed))
    })
  }

  const form = useForm({
  });

  function onSubmit(data) {
    let updated_jira_ticket_number = (data.jira_ticket_number) ? data.jira_ticket_number.toUpperCase() : ''
    let change = "Update Jira number";
    return upsertEntity({ ...activeEntity, jira_ticket_number: updated_jira_ticket_number, manual_update_by: user.nickname, update_history: change.concat(":", updated_jira_ticket_number) }).then(() => {
      setActiveEntity(tank_delivery_event_uuid);
      items.unshift(newItem(user.nickname, change, updated_jira_ticket_number))
    })
  }

  const lookup = useContext(LookupContext);

  useEffect(() => {
    setActiveEntity(tank_delivery_event_uuid);
    setItems(
      activeEntity?.update_history ?
        activeEntity.update_history.split('\n').map(item => ({
          ...item,
          data: item,
          id: item,
          title: item.replaceAll('|', ' | '),
        })) : []
    );
  }, []);

  return (
    <Page
      route={routeConfig.tank_fill_event_view}
      gap={1}
      onBack={() => navigateTo(routeConfig.tank_fill_table)}
    >

      <PageSection
        title="Details"
        spacing={1}
        collapsible
        omitContainer
      >
        <PageText
          label="Site Name"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.site_uuid ? lookup.byValue('site_hierarchy', activeEntity.site_uuid).label : ' - '}
        />
        <PageText
          label="Site UUID"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.site_uuid}
        />
        <PageText
          label="Site Unit of Measure"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.site_uuid ? lookup.byValue('site_hierarchy', activeEntity.site_uuid).uom : ' - '}
        />
        <PageText
          label="Event UUID"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.tank_delivery_event_uuid}
        />
        <PageText
          label="Tank Type"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.site_uuid ? lookup.byValue('site_hierarchy', activeEntity.site_uuid).tank_type : ' - '}
        />
        <PageText
          label="Supplier Name"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.supplier_name ? activeEntity.supplier_name : "-"}
        />
        <MDivider
          variant='list-middle'
          direction='row'
        />
        <PageText
          label="Duration Minutes"
          GridProps={{ xs: 12, md: 12 }}
          body={activeEntity?.tank_delivery_duration}
        />
        <PageText
          label="Begin"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.tank_delivery_begin_timestamp ? dateHelper.formatLocalDateTime(activeEntity.tank_delivery_begin_timestamp) : "-"}
        />
        <PageText
          label="End"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.tank_delivery_end_timestamp ? dateHelper.formatLocalDateTime(activeEntity.tank_delivery_end_timestamp) : "-"}
        />
        <MDivider
          variant='list-middle'
          direction='row'
        />
        <PageText
          body={"Tank levels and quantity are in site unit of measure"}
        />
        <PageText
          label="Quantity"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.tank_delivery_quantity}
        />
        <PageText
          label="Delivery Pulses"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.delivery_pulses}
        />
        <PageText
          label="Begin Level"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.tank_level_begin}
        />
        <PageText
          label="End Level"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.tank_level_end}
        />
        <MDivider
          variant='list-middle'
          direction='row'
        />
        <PageText
          label="Jira Ticket Number"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.jira_ticket_number ? activeEntity.jira_ticket_number : "-"}
        />
        <PageText
          label="Jira Completed"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.jira_completed ? activeEntity.jira_completed : "-"}
        />
        <PageText
          label="POD Quantity"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.pod_quantity ? activeEntity.pod_quantity : "-"}
        />
        <PageText
          label="Billable Quantity"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.billable_quantity ? activeEntity.billable_quantity : "-"}
        />
        <PageText
          label="Manual Entry"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.manual_entry ? "True" : "False"}
        />
        <PageText
          label="Supplier Bill Item UUID"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.supplier_bill_item_uuid ? activeEntity.supplier_bill_item_uuid : "-"}
        />
        <MDivider
          variant='list-middle'
          direction='row'
        />
        <PageText
          label="Last Updated"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.manual_update_timestamp ? dateHelper.formatLocalDateTime(activeEntity.manual_update_timestamp) : "-"}
        />
        <PageText
          label="Last Updated By"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.manual_update_by ? activeEntity.manual_update_by : "-"}
        />
      </PageSection>
      <PageSection
        title={activeEntity?.tank_delivery_event_active ? "Active" : "Inactive"}
        label='Event Status'
        endActions={<ButtonGroup sx={{ mr: 2 }}>
          <Restricted to={[permissions.WRITE_TANK_FILLS]}>
            <MButton
              onClick={() => {
                confirmDialog("Provide a reason to confirm the change?", handleToggleEventActive, true)
              }}
              color="inherit"
              children="Toggle active"
              tooltip={"Toggle active event"}
            />
          </Restricted>
        </ButtonGroup>}
        collapsible
      />
      <Restricted to={[permissions.WRITE_TANK_FILLS]}>
        <PageSection
          title=''
          label='Update Jira Ticket Number'
          startActions={
            <MForm
              onSubmit={onSubmit}
              form={form}
              omitSubmitButton
            >
              <MFormTextField
                name="jira_ticket_number"
                label='Jira Ticket Number'
                GridProps={{ xs: 12, md: 12 }}
                onControlEnter={form.handleSubmit(onSubmit)}
                control={form.control}
              />
            </MForm>
          }
          endActions={<ButtonGroup sx={{ mr: 2 }}>
            <MButton
              onClick={form.handleSubmit(onSubmit)}
              color="inherit"
              children="Update Ticket"
              tooltip={"Update Jira Ticket Number"}
            />
          </ButtonGroup>}
        >
        </PageSection>
      </Restricted>
      <PageSection
        title={activeEntity?.fill_quantity_reviewed ? "True" : "False"}
        label='Fill Quantity Reviewed'
        endActions={<ButtonGroup sx={{ mr: 2 }}>
          <Restricted to={[permissions.WRITE_TANK_FILL_QUANTITY_REVIEW]}>
            <MButton
              onClick={handleToggleFillQtyReviewed}
              color="inherit"
              children="Toggle Reviewed"
              tooltip={"Toggle fill quantity reviewed"}
            />
          </Restricted>
        </ButtonGroup>}
        collapsible
      />
      <PageSection

        title="Calibration"
        spacing={1}
        collapsible
        omitContainer
        endActions={
          <MButton
            GridProps
            collapseXs
            size="small"
            icon="edit"
            variant="outlined"
            color="inherit"
            tooltip={{ title: "Edit Tank Calibration" }}
            onClick={() => navigateTo(routeConfig.tank_fill_edit_calibration)}
            children="Edit Calibrations"
          />
        }
      >
        <PageText
          label="Calibration Note"
          GridProps={{ xs: 12, md: 12 }}
          body={activeEntity?.tank_delivery_calibration_note ? activeEntity.tank_delivery_calibration_note : "-"}
        />
        <PageText
          label="Manual Tank Level Begin Tank 1"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.tank_level_begin_manual_t1 ? activeEntity.tank_level_begin_manual_t1 : "-"}
        />
        <PageText
          label="Manual Tank Level Begin Tank 2"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.tank_level_begin_manual_t2 ? activeEntity.tank_level_begin_manual_t2 : "-"}
        />
        <PageText
          label="Manual Tank Level End Tank 1"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.tank_level_end_manual_t1 ? activeEntity.tank_level_end_manual_t1 : "-"}
        />
        <PageText
          label="Manual Tank Level End Tank 2"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.tank_level_end_manual_t2 ? activeEntity.tank_level_end_manual_t2 : "-"}
        />
        <PageText
          label="Manual Tank Level Delta Tank 1"
          GridProps={{ xs: 12, md: 6 }}
          body={ manual_tank_level_delta(activeEntity.tank_level_end_manual_t1,activeEntity.tank_level_begin_manual_t1)}
        />
        <PageText
          label="Manual Tank Level Delta Tank 2"
          GridProps={{ xs: 12, md: 6 }}
          body={ manual_tank_level_delta(activeEntity.tank_level_end_manual_t2,activeEntity.tank_level_begin_manual_t2)}
        />
        <PageText
          label="Pre Fill AI Tank 1"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.pre_fill_ai_t1 ? activeEntity.pre_fill_ai_t1 : "-"}
        />
        <PageText
          label="Pre Fill AI Tank 2"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.pre_fill_ai_t2 ? activeEntity.pre_fill_ai_t2 : "-"}
        />
        <PageText
          label="Post Fill AI Tank 1"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.post_fill_ai_t1 ? activeEntity.post_fill_ai_t1 : "-"}
        />
        <PageText
          label="Post Fill AI Tank 2"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.post_fill_ai_t2 ? activeEntity.post_fill_ai_t2 : "-"}
        />
        <PageText
          label="Current Unit Per AI Tank 1"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.current_unit_per_ai_t1 ? activeEntity.current_unit_per_ai_t1 : "-"}
        />
        <PageText
          label="Current Unit Per AI Tank 2"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.current_unit_per_ai_t2 ? activeEntity.current_unit_per_ai_t2 : "-"}
        />
        <PageText
          label="Calibration Date Tank 1"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.tank_calibration_date_t1 ? dateHelper.formatDate(activeEntity.tank_calibration_date_t1) : "-"}
        />
        <PageText
          label="Calibration Date Tank 2"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.tank_calibration_date_t2 ? dateHelper.formatDate(activeEntity.tank_calibration_date_t2) : "-"}
        />
        <PageText
          label="Suggested New AI Tank 1"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.pod_quantity ? suggestedAI(activeEntity.pod_quantity, activeEntity.post_fill_ai_t1, activeEntity.pre_fill_ai_t1) : "-"}
        />
        <PageText
          label="Suggested New AI Tank 2"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.pod_quantity ? suggestedAI(activeEntity.pod_quantity, activeEntity.post_fill_ai_t2, activeEntity.pre_fill_ai_t2) : "-"}
        />
        <PageText
          label="Hot Fill Tank 1"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.hot_fill_t1 ? "True" : "False"}
        />
        <PageText
          label="Hot Fill Tank 2"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.hot_fill_t2 ? "True" : "False"}
        />
      </PageSection>
      <PageSection
        title="History"
        spacing={1}
        collapsible
        omitContainer
      >
        <MList
          dense
          items={items}
        />
      </PageSection>


    </Page >
  );
}