import Page from '../../templates/Page';
import { ParentCompanyForm } from '../../forms';
import { useContext } from 'react';
import CustomerAdminContext from '../../../contexts/CustomerAdminContext';
import { routeConfig } from '../../../configs';
import PageSection from '../../templates/PageSection';

export default function EditParentCompany(props) {
  const { entities, navigateTo } = useContext(CustomerAdminContext);

  return (
    <Page route={routeConfig.parent_edit}
      onBack={() => navigateTo(routeConfig.parent_view)}
    >
      <PageSection title="Update Parent Details" >
        <ParentCompanyForm form_data={entities.active_parent} />
      </PageSection>
    </Page>
  );
}