/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import ErrorContext from "../contexts/ErrorContext";
import { lookupService } from "../services";
import { useAuth0 } from '@auth0/auth0-react';

/**
 * Gets and caches a type of Lookup value.
 * @template {{value: string, label: string}} LookupValue
 * @param {keyof lookupConfig} lookupType 
 * @param {{
 *     mapLookup?: (lookup: LookupType) => LookupValue,
 *     sort?: (lu1, lu2) => number,
 *     onChange?: (newValues: LookupType) => void,
 * }} options
 * @returns {[LookupValue[], boolean, ()=> void]} lookupValues
 */
export const useLookup = (lookupType, options) => {
    const { mapLookup, sort } = options || {};
    const [lookupValues, setLookupValues] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const context = useContext(ErrorContext);
    const { getAccessTokenSilently } = useAuth0();

    const fetchLookup = async () => {
        const token = await getAccessTokenSilently();
        lookupService.getLookupValues(token, lookupType)
            .then(response => {
                const payload = response[lookupType];
                if (!payload) {
                    context.pushError({ message: `Failed to get Lookup values for ${lookupType}: Invalid response payload.` });
                } else {
                    let newLookups = mapLookup
                        ? payload.map(lu => ({
                            ...lu,
                            value: lu[`${lookupType}`],
                            label: lu[`${lookupType}_description`],
                            ...mapLookup(lu),
                        }))
                        : payload.map(lu => {
                            return {
                                ...lu,
                                value: lu[`${lookupType}`],
                                label: lu[`${lookupType}_description`],
                            }
                        });
                    newLookups = newLookups.map(lu => ({ ...lu, value: lu.value || lu.label }));
                    setLookupValues(sort ? newLookups.sort(sort) : newLookups);
                    setIsLoading(false);
                }
            }).catch(err => {
                context.pushError({
                    message: `Failed to get Lookup values for ${lookupType}: ${err}`
                });
                setIsLoading(false);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    useEffect(fetchLookup, []);


    useEffect(() => {
        if (options && options.onChange) {
            options.onChange(lookupValues);
        }
    }, [lookupValues])

    return [lookupValues, isLoading, fetchLookup];
}