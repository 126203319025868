import { Grid } from "@mui/material";
import React from "react";
import { FormContainer } from "react-hook-form-mui";
import PropTypes from 'prop-types';
import MButton from "./MButton";

MForm.propTypes = {
    form: PropTypes.object.isRequired,
    defaultValues: PropTypes.object,
    onSubmit: PropTypes.func,
    submitText: PropTypes.string,
    omitSubmitButton: PropTypes.bool,
    submitDisabled: PropTypes.bool,
    sx: PropTypes.object,
}
function MForm({ children, omitSubmitButton, form, sx, onSubmit, submitDisabled, submitText = "Save", ...rest }) {
    return (<FormContainer
        onSuccess={onSubmit}
        {...rest}
    >
        <Grid
            container
            spacing={1}
            sx={{
                px: 1,
                ...sx,
            }}
        >
            {children}
        </Grid>
        {!omitSubmitButton && <Grid container justifyContent="center"
            sx={{
                px: 1,
                my: 2,
            }}>
            <MButton
                // size="large"
                GridProps={{ item: true, xs: 12, sm: 6, md: 4, lg: 3, }}
                onClick={form.handleSubmit(onSubmit)}
                variant={"contained"}
                disabled={submitDisabled}
                children={submitText}
            />
        </Grid>}
    </FormContainer>
    );
};

export default MForm;