/* eslint-disable react-hooks/exhaustive-deps */
import createEntityContext from '../helpers/createEntityContext';

const EntityContext = createEntityContext({
    entityType: "take_or_pay_term",
    defaultEntity: {
        supplier_contract_rate_uuid: "",
        take_or_pay_term_effective_date: "",
        take_or_pay_description: "",
        minimum_quantity: null,
        period: null
    },
});

export const TakeOrPayTermContext = EntityContext;
export const TakeOrPayTermContextProvider = EntityContext.Provider;
export const useTakeOrPayTermContext = EntityContext.useContext;