import Page from '../../templates/Page';
import { routeConfig } from '../../../configs';
import PageSection from '../../templates/PageSection';
import { useNavigateTo } from '../../../helpers';
import { TankDeliveryEventForm } from '../../forms/TankDeliveryEventForm';

export default function AddTankDeliveryEvent(props) {
  const [, navigateTo] = useNavigateTo();

  return (
    <Page
      route={routeConfig.tank_fill_manual_entry}
      gap={1}
      onBack={() => navigateTo(routeConfig.tank_fill_table)}
    >
      <PageSection>
        <TankDeliveryEventForm />
      </PageSection>
    </Page>
  );
}