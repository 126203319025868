/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useContext } from 'react';
import { useForm } from "react-hook-form";
import { routeConfig } from '../../../configs';
import { MForm, MFormTextField, MDivider, MFormSelect, MFormCheckbox } from '../../@material-extend';
import { SiteDefaultValues, useLookupContext, LayoutContext } from '../../../contexts';
import { useNavigateTo } from '../../../helpers';
import Restricted from '../../Restricted';
import { permissions } from '../../../configs/permissionConstants';
import { useAuth0 } from '@auth0/auth0-react';

/**
 * 
 * @param {{
 *  siteContext: {},
 *  form_data: {},
 * }} props 
 * @returns 
 */
export function SiteGeneralForm({ form_data, siteContext }) {
  const [params, navigateTo] = useNavigateTo();
  const lookup = useLookupContext();
  const [countryState, setCountryState] = useState({ state_code: "", state_name: "", country_code: "" });
  const [msaCountryState, setMsaCountryState] = useState({ state_code: "", state_name: "", country_code: "" });
  const layout = useContext(LayoutContext);
  const form = useForm({ defaultValues: SiteDefaultValues });
  const { user } = useAuth0();

  const handleValidateForm = (data) => {
    let errorMessage = null;
    if (!data.site_id) {
      data.site_id = null;
      errorMessage = "Site ID is required.";
    }
    if (!data.site_name) {
      data.site_name = null;
      errorMessage = "Site name is required.";
    }
    if (errorMessage) {
      layout.handleError(errorMessage);
      return false;
    }
    return true;
  }

  function onSubmit(data) {

    const isValidForm = handleValidateForm(data);
    if (!isValidForm) {
      return;
    }

    if (data.state_code && data.state_code !== form_data.state_code) {
      data.country_code = countryState.country_code;
    }

    if (data.msa_state_code && data.msa_state_code !== form_data.msa_state_code) {
      data.msa_country_code = msaCountryState.country_code;
    }

    // passed in, API only uses if site_status changed
    data.site_status_update_by = user.nickname

    data.site_name = data.site_name.trim()
    return siteContext.upsertEntity(data)
      .then(res => {
        if (res)
          navigateTo(routeConfig.site_view, { site_uuid: res.id })
      });
  }

  useEffect(() => {
    if (params.business_unit_uuid && !form_data?.business_unit_uuid) {
      form.setValue("business_unit_uuid", params.business_unit_uuid);
    }
  }, [form, params.business_unit_uuid, form_data]);

  useEffect(() => {
    if (form_data) {
      form.reset(form_data)
    }
  }, [siteContext, form_data])

  const handleStateChange = (newState) => {
    console.log("selected state", newState);
    setCountryState(newState);
  }

  const handleMsaStateChange = (newState) => {
    setMsaCountryState(newState);
  }

  return (<MForm
    defaultValues={siteContext.defaultValues}
    onSubmit={onSubmit}
    form={form}
  >
    {/* ----------------------------- Detail Fields ----------------------------- */}
    <Restricted to={[permissions.WRITE_SITE_GENERAL, permissions.WRITE_SITE_TANK_NITROFILL, permissions.WRITE_SITE_HIERARCHY]}>
      <MDivider
        variant="form"
        above={{ title: "About Site" }}
      />
      <Restricted to={[permissions.WRITE_SITE_HIERARCHY]}>
        <MFormTextField
          autoFocus
          onControlEnter={form.handleSubmit(onSubmit)}
          control={form.control}
          name="site_name"
          label="Site Name"
          fullWidth
          required
        />
        <MFormTextField
          name="site_id"
          label="Site ID"
          type="number"
          GridProps={{
            xs: 12,
            md: 6,
          }}
          required
          onControlEnter={form.handleSubmit(onSubmit)}
          control={form.control}
          fullWidth
        />
      </Restricted>
      <Restricted to={[permissions.WRITE_SITE_GENERAL]}>
        <MFormTextField
          onControlEnter={form.handleSubmit(onSubmit)}
          control={form.control}
          name="site_year"
          type="number"
          label="Year"
          GridProps={{
            xs: 12,
            md: 6,
          }}
        />
        <MFormSelect
          name="channel"
          label="Channel"
          GridProps={{
            xs: 12,
            md: 6,
          }}
          onControlEnter={form.handleSubmit(onSubmit)}
          includeAll="-- Unselect --"
          control={form.control}
          options={lookup.values.channel}
          fullWidth
        />
      </Restricted>
      <Restricted to={[permissions.WRITE_SITE_TANK_NITROFILL]}>
        <MFormCheckbox
          onControlEnter={form.handleSubmit(onSubmit)}
          control={form.control}
          name="nitro_deliver"
          row
          label="Is Tank Nitrofill?"
          GridProps={{
            xs: 12,
            md: 4,
            sx: { pl: 2 }
          }}
        />
      </Restricted>
      <Restricted to={[permissions.WRITE_SITE_GENERAL]}>
        <MFormTextField
          name="site_description"
          label="Description"
          onControlEnter={form.handleSubmit(onSubmit)}
          control={form.control}
          multiline
          minRows={3}
        />
      </Restricted>
    </Restricted>
    {/* ----------------------------- Units ----------------------------- */}
    <Restricted to={[permissions.WRITE_SITE_GENERAL]}>
      <MDivider
        variant="form"
        above={{ title: "Site Units" }}
      />
      <MFormSelect
        name="unit_of_measure"
        label="Unit of Measure"
        includeAll="-- Unselect --"
        onControlEnter={form.handleSubmit(onSubmit)}
        options={lookup.values.unit_of_measure}
        control={form.control}
        GridProps={{
          xs: 12,
          md: 6,
        }}
      />
      <MFormSelect
        name="currency_code"
        label="Site Currency"
        onControlEnter={form.handleSubmit(onSubmit)}
        GridProps={{
          xs: 12,
          md: 6,
        }}
        includeAll="-- Unselect --"
        control={form.control}
        options={lookup.values.currency}
      />
    </Restricted>

    {/* ----------------------------- Location ----------------------------- */}
    <Restricted to={[permissions.WRITE_SITE_GENERAL, permissions.WRITE_SITE_ADDRESS]}>

      <MDivider
        variant="form"
        above={{ title: "Site Location" }}
      />
      <MFormTextField
        label="Address 1"
        name="site_address1"
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
      />
      <MFormTextField
        label="Address 2"
        name="site_address2"
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
      />
      <MFormTextField
        label="City"
        name="site_city"
        onControlEnter={form.handleSubmit(onSubmit)}
        GridProps={{
          xs: 12,
          md: 6,
        }}
        control={form.control}
      />
      <MFormTextField
        label="Postal Code"
        name="site_postal_code"
        onControlEnter={form.handleSubmit(onSubmit)}
        GridProps={{
          xs: 12,
          md: 6,
        }}
        control={form.control}
      />
    </Restricted>
    <Restricted to={[permissions.WRITE_SITE_GENERAL]}>
      <MFormSelect
        label="Continent / Country / State"
        name="state_code"
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        onOptionSelect={handleStateChange}
        includeAll="-- Unselect --"
        options={lookup.values.state}
        GridProps={{
          xs: 12,
          md: 6,
        }}
      />

      <MFormSelect
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        name="territory"
        options={lookup.values.territory}
        GridProps={{
          xs: 12,
          md: 6,
        }}
        includeAll="-- Unselect --"
        label="Region / Territory"
      />

      <MFormTextField
        label="MSA City"
        name="site_msa_city"
        onControlEnter={form.handleSubmit(onSubmit)}
        GridProps={{
          xs: 12,
          md: 6,
        }}
        control={form.control}
      />

      <MFormSelect
        label="Continent / Country / MSA State"
        name="msa_state_code"
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        onOptionSelect={handleMsaStateChange}
        includeAll="-- Unselect --"
        options={lookup.values.state}
        GridProps={{
          xs: 12,
          md: 6,
        }}
      />

      <MFormSelect
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        name="language_used"
        GridProps={{
          xs: 12,
          md: 6,
        }}
        options={lookup.values.language}
        includeAll="-- Unselect --"
        label="Language"
      />
      <MFormSelect
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        name="timezone_name"
        options={lookup.values.timezone}
        GridProps={{
          xs: 12,
          md: 6,
        }}
        includeAll="-- Unselect --"
        label="Timezone"
      />
      <MFormTextField
          onControlEnter={form.handleSubmit(onSubmit)}
          control={form.control}
          name="site_latitude"
          type="number"
          label="Site Latitude"
          GridProps={{
            xs: 12,
            md: 6,
          }}
        />
        <MFormTextField
          onControlEnter={form.handleSubmit(onSubmit)}
          control={form.control}
          name="site_longitude"
          type="number"
          label="Site Longitude"
          GridProps={{
            xs: 12,
            md: 6,
          }}
        />
    </Restricted>

    {/* ----------------------------- Status ----------------------------- */}
    <Restricted to={[permissions.WRITE_SITE_STATUS]}>
      <MDivider
        variant="form"
        above={{ title: "Site Status" }}
      />
      <MFormSelect
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        name="site_status"
        options={lookup.values.site_status}
        includeAll="-- Unselect --"
        GridProps={{
          xs: 12,
          md: 6,
        }}
        label="Site Status"
      />
      <MFormSelect
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        name="system_status"
        options={lookup.values.system_status}
        label="System Status"
        GridProps={{
          xs: 12,
          md: 6,
        }}
        includeAll="-- Unselect --"
      />
      <MFormSelect
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        name="tank_status"
        options={lookup.values.tank_status}
        label="Tank Status"
        GridProps={{
          xs: 12,
          md: 6,
        }}
        includeAll="-- Unselect --"
      />
    </Restricted>

  </MForm >

  );
}