/* eslint-disable react-hooks/exhaustive-deps */
import createEntityContext from '../helpers/createEntityContext';

const EntityContext = createEntityContext({
    entityType: "customer_contract",
    // upsertMode: "create",
    defaultEntity: {
        customer_contract_uuid: "",
        customer_contract_description: "",
        customer_contract_effective_date: "",
        customer_contract_rate_terms: "",
        first_fill_date: "",
        customer_contract_term_length: null,
        customer_contract_number: "",
        customer_contract_status: "",
        site_uuid: "",
        pricing_model: ""
    },
});

export const CustomerContractContext = EntityContext.Context;
export const CustomerContractContextProvider = EntityContext.Provider;
export const useCustomerContractContext = EntityContext.useContext;