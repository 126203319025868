/* eslint-disable react-hooks/exhaustive-deps */
import { Fab, Grid, Paper } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { LayoutContext } from "../../contexts";
import { MIconButton, MList } from "../@material-extend";

/**
 * 
 * @param {{
 * pageContext: {
 *   pageExpanded: boolean;
 *   sections: {};
 *   handleExpandPage: (isExpanded?: boolean) => void;
 *   handleExpandSection: (sectionId: any) => void;
 *   mountSection: (section: any) => void;
 *   unmountSection: (sectionId: any) => void;
 *   scrollTo: (elementId: any, offset: any) => void;
 * }} props 
 * @returns 
 */
function PageStickyStepper({ pageContext }) {
    const { windowDimensions, scrollTo, pageState, setPageState } = useContext(LayoutContext);
    // const [params,] = useNavigateTo();
    const [sectionAnchors, setSectionAnchors] = useState([]);

    /**
     * Set's active anchor.
     * Hacky way to get the anchor to set active after the automatic scroll-to triggers on each PageSection.
     * @param {{id: string}} newAnchor 
     */
    const setActiveAnchor = (newAnchor) => {
        scrollTo(newAnchor?.id);
        if (newAnchor) {
            setTimeout(() => {
                setPageState(prevState => ({
                    ...prevState,
                    activePageSection: newAnchor.id,
                }));
            }, 150)
        };
    }

    useEffect(() => {
        const newAnchors = Object.values(pageContext.sections).map(section => ({
            ...section,
            id: section.title,
        }));

        setSectionAnchors(newAnchors);

        setActiveAnchor(newAnchors[0]);
    }, [pageContext.sections,]);

    const sizeSx = windowDimensions.isMobile ? {
        pr: 1,
        width: "auto",
        bottom: 16,
        right: 0,
    } : {
        width: windowDimensions.isXl ? 264 : windowDimensions.isLg ? 210 : 210,
        right: windowDimensions.isXl ? 0 : 0,
        height: 500,
        top: 88,
        pr: windowDimensions.isXl ? 3
            : windowDimensions.isLg ? 1.5
                : 1,
        pl: windowDimensions.isXl ? 3
            : windowDimensions.isLg ? 1
                : 3,
        pb: 4,
    }

    const handleClick = (item) => {
        setActiveAnchor(item)
    }

    return <Grid
        item
        sx={{
            position: "fixed",
            ...sizeSx,
        }}
    >

        {windowDimensions.isMdUp
            ? <Grid
                component={Paper}
                variant="elevation"
                elevation={16}
                sx={{
                    height: 1,
                    width: 1,
                    pt: 1.5,
                    // borderRadius: 0,
                }}
            >

                <MList
                    selectedId={pageState.activePageSection}
                    dense
                    onClick={handleClick}
                    items={sectionAnchors}
                />
            </Grid>
            : <Fab
                size="small"
                onClick={() => scrollTo()}
                color="secondary"
            >
                <MIconButton
                    icon={"expand"}
                    sx={{ color: "white" }}
                    rotateDegrees={180}
                />
            </Fab>
        }
    </Grid>

}

export default PageStickyStepper;