/* eslint-disable react-hooks/exhaustive-deps */
import CustomerAdminContext from '../../contexts/CustomerAdminContext';
import { entityConfig, routeConfig } from '../../configs';
import { useContext, useEffect, useState } from 'react';
import { ContactList } from './ContactList';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import { useNavigateTo } from '../../helpers';
import { useAuth0 } from '@auth0/auth0-react';
import { confirmDialog } from '../ConfirmDialog';

SiteContactList.propTypes = {
  transferList: PropTypes.bool,
}

function SiteContactList(props) {
  const { entities, deleteEntity } = useContext(CustomerAdminContext);
  const [params, navigateTo] = useNavigateTo();
  const [contacts, setContacts] = useState([]);
  const { getAccessTokenSilently } = useAuth0();

  function handleSelectContact(contact) {
    navigateTo(routeConfig.site_contact_edit, { site_contact_uuid: contact?.id || contact.site_contact_uuid });
  }

  async function deleteContact(item) {
    const token = await getAccessTokenSilently();
    deleteEntity(token, item);
  }

  useEffect(() => {
    if (entities.site_contact.length) {
      const site_contacts = entities.site_contact.filter(contact => contact.site_uuid === params.site_uuid);
      setContacts(site_contacts);
    }

  }, [entities]);

  return (
    <ContactList
      transferList={props.transferList}
      options={
        [
          {
            label: 'Edit Contact',
            icon: <EditIcon />,
            onClick: handleSelectContact
          },
          {
            label: 'Delete Contact',
            icon: <DeleteIcon />,
            onClick: (item) => {
              confirmDialog("Do you want to delete the contact?", () => deleteContact(item))
            }
          }
        ]}
      type={entityConfig.type.site_contact}
      contacts={contacts}
    />
  );

}

export default SiteContactList;