import * as React from 'react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import PropTypes from 'prop-types';
import MDivider from './MDivider';

MFormGroup.propTypes = {
    title: PropTypes.string,
    helperText: PropTypes.string,
    GridProps: PropTypes.object,
    labelsx: PropTypes.object,
}

export default function MFormGroup(props) {


    const content = (
        <FormControl
            sx={{ width: 1 }}
        >
            {props.title && <MDivider
                GridProps={{ xs: 12, sx: { mt: .5, mb: 2} }}
                above={{ title: props.title, sx: { mb: .3 } }}
            />}
            {/* {props.title && <FormLabel component="legend" children={props.title} sx={props.labelsx} />} */}
            <FormGroup>
                {props.GridProps
                    ? <Grid
                        container
                        justifyContent={props.GridProps?.justifyContent}
                        alignItems={props.GridProps?.alignItems}
                        spacing={props.GridProps?.spacing}
                        gap={props.GridProps?.gap}
                        children={props.children}
                        direction={props.GridProps?.direction}
                    />
                    : props.children
                }
            </FormGroup>
            {props.helperText && <FormHelperText children={props.helperText} />}
        </FormControl>
    );

    if (props.GridProps) {
        return <Grid
            item
            xs={props.GridProps?.xs}
            sm={props.GridProps?.sm}
            md={props.GridProps?.md}
            lg={props.GridProps?.lg}
            xl={props.GridProps?.xl}
            sx={props.GridProps?.sx}
            children={content} />
    }

    return (content);
}