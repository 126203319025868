import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import LogoutIcon from '@mui/icons-material/Logout';
import BillingTableIcon from '@mui/icons-material/CreditCard';
import InventoryIcon from '@mui/icons-material/Inventory';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import { permissions } from './permissionConstants';

const iconProps = {
  width: 22,
  height: 22,
}
const getIcon = (name) => <Icon icon={name} {...iconProps} />;


/**
 * Used in route config for filtering routes for menus and 
 * rendering nested components. (`routeConfig["routeID"].types`)
 */
export const RouteConfigType = {
  sidebar: "sidebar",
}

/**
 * ## App Route Config
 * Encapsulate app routes for easy links and navigation.
 */
export const routeConfig = {

  // Root Pages
  dashboard: {
    id: "dashboard",
    types: [RouteConfigType.sidebar],
    path: "home",
    fullPath: "/dashboard/home",
    title: "Dashboard",
    icon: getIcon(pieChart2Fill),
    restrict: permissions.VIEW_TOOL_NITROVISION
  },
  site_admin: {
    id: "site_admin",
    types: [RouteConfigType.sidebar],
    path: "site_admin",
    fullPath: "/dashboard/site_admin",
    title: "Site Manager",
    icon: getIcon(peopleFill),
    restrict: permissions.VIEW_TOOL_SITE_MANAGER
  },
  //Suppliers
  supplier: {
    id: "supplier",
    types: [RouteConfigType.sidebar],
    path: "supplier",
    fullPath: "/dashboard/supplier",
    title: "All Suppliers",
    icon: <InventoryIcon sx={iconProps} />,
    restrict: permissions.VIEW_TOOL_SUPPLIERS
  },
  supplier_add: {
    id: "supplier_add",
    path: "add",
    fullPath: "/dashboard/supplier/add",
    title: "Add Supplier",
  },
  supplier_view: {
    id: "supplier_view",
    path: ":supplier_uuid",
    fullPath: "/dashboard/supplier/:supplier_uuid",
    title: "Supplier",
  },
  supplier_edit: {
    id: "supplier_edit",
    path: "edit",
    fullPath: "/dashboard/supplier/:supplier_uuid/edit",
    title: "Edit Supplier",
  },

  // tank fills
  tank_fill_table: {
    id: "tank_fill_event_table",
    types: [RouteConfigType.sidebar],
    path: "tank_fills",
    fullPath: "/dashboard/tank_fills",
    title: "Tank Fills",
    icon: <BillingTableIcon sx={iconProps} />,
    restrict: permissions.VIEW_TOOL_TANK_FILLS
  },
  tank_fill_event_view: {
    id: "tank_fill_event_view",
    path: ":tank_delivery_event_uuid",
    fullPath: "/dashboard/tank_fills/:tank_delivery_event_uuid",
    title: "Tank Fill Event",
  },
  tank_fill_manual_entry: {
    id: "tank_fill_manual_entry",
    path: "manual_entry",
    fullPath: "/dashboard/tank_fills/manual_entry",
    title: "Tank Fill - Manual Entry",
  },
  tank_fill_table_refresh: {
    id: "tank_fill_event_table_refresh",
    path: "refresh",
    fullPath: "/dashboard/tank_fills/refresh",
    title: "Tank Fills",
  },
  tank_fill_edit_calibration: {
    id: "tank_fill_edit_calibration",
    path: "edit_calibration",
    fullPath: "/dashboard/tank_fills/:tank_delivery_event_uuid/edit_calibration",
    title: "Tank Fill Calibration",
  },

  // usage_events
  usage_table: {
    id: "usage_event_table",
    types: [RouteConfigType.sidebar],
    path: "usages",
    fullPath: "/dashboard/usages",
    title: "Usages",
    icon: <BillingTableIcon sx={iconProps} />,
    restrict: permissions.VIEW_TOOL_USAGES
  },
  usage_view: {
    id: "usage_event_view",
    path: ":usage_event_uuid",
    fullPath: "/dashboard/usages/:usage_event_uuid",
    title: "Usage Event",
  },
  usage_manual_entry: {
    id: "usage_manual_entry",
    path: "manual_entry",
    fullPath: "/dashboard/usages/manual_entry",
    title: "Usage - Manual Entry",
  },
  usage_table_refresh: {
    id: "usage_event_table_refresh",
    path: "refresh",
    fullPath: "/dashboard/usages/refresh",
    title: "Usages",
  },

  // delivery tickets
  delivery_ticket_table: {
    id: "delivery_ticket_table",
    types: [RouteConfigType.sidebar],
    path: "delivery_tickets",
    fullPath: "/dashboard/delivery_tickets",
    title: "Delivery Tickets",
    icon: <BillingTableIcon sx={iconProps} />,
    restrict: permissions.VIEW_TOOL_DELIVERY_TICKETS
  },
  delivery_ticket_view: {
    id: "delivery_ticket_view",
    path: ":delivery_ticket_uuid",
    fullPath: "/dashboard/delivery_tickets/:delivery_ticket_uuid",
    title: "Delivery Ticket",
  },
  delivery_ticket_table_refresh: {
    id: "delivery_ticket_table_refresh",
    path: "refresh",
    fullPath: "/dashboard/delivery_tickets/refresh",
    title: "Delivery Tickets",
  },

    // representatives
    representative_table: {
      id: "representative_table",
      types: [RouteConfigType.sidebar],
      path: "representatives",
      fullPath: "/dashboard/representatives",
      title: "Representatives",
      icon: <BillingTableIcon sx={iconProps} />,
      restrict: permissions.VIEW_TOOL_NITROCRETE_REPRESENTATIVES
    },
    representative_view: {
      id: "representative_view",
      path: ":nitrocrete_representative",
      fullPath: "/dashboard/representatives/:nitrocrete_representative",
      title: "Representative",
    },
    representative_manual_entry: {
      id: "representative_manual_entry",
      path: "manual_entry",
      fullPath: "/dashboard/representatives/manual_entry",
      title: "Representative - Manual Entry",
    },

  // operations comment
  operations_comment: {
    id: "operations_comment",
    types: [RouteConfigType.sidebar],
    path: "operations_comment",
    fullPath: "/dashboard/operations_comment",
    title: "Operations Comment",
    icon: <AddCommentOutlinedIcon sx={iconProps} />,
    restrict: permissions.VIEW_TOOL_OPERATIONS_COMMENT
  },

    // usage comment
    usage_comment: {
      id: "usage_comment",
      types: [RouteConfigType.sidebar],
      path: "usage_comment",
      fullPath: "/dashboard/usage_comment",
      title: "Usage Comment",
      icon: <AddCommentOutlinedIcon sx={iconProps} />,
      restrict: permissions.VIEW_TOOL_USAGE_COMMENT
    },

  logout: {
    id: "logout",
    types: [RouteConfigType.sidebar],
    path: "/logout",
    title: "Logout",
    icon: <LogoutIcon sx={iconProps} />,
    restrict: permissions.VIEW_TOOL_NITROVISION

  },

  app: {
    id: "app",
    types: [],
    path: "app",
    title: "Dashboard",
  },

  // Parent Company
  parent: {
    id: "parent_company",
    path: "parent",
    fullPath: "/dashboard/site_admin/parent",
    title: "Parent Company",
  },
  parent_add: {
    id: "parent_add",
    path: "add",
    fullPath: "/dashboard/site_admin/parent/add",
    title: "Add Parent Company",
  },
  parent_view: {
    id: "parent_view",
    path: ":parent_company_uuid",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid",
    title: "Parent Company",
  },
  parent_edit: {
    id: "parent_edit",
    path: "edit",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid/edit",
    title: "Edit Parent Company",
  },

  // Regional Company
  region: {
    id: "regional_company",
    path: "region",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid/region",
    title: "Regional Company",
  },
  region_add: {
    id: "region_add",
    path: "add",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid/region/add",
    title: "Add Regional Company",
  },
  region_view: {
    id: "region_view",
    path: ":regional_company_uuid",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid/region/:regional_company_uuid",
    title: "Regional Company",
  },
  region_edit: {
    id: "region_edit",
    path: "edit",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid/region/:regional_company_uuid/edit",
    title: "Edit Regional Company",
  },
  // Business Unit
  business: {
    id: "business_unit",
    path: "business",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid/region/:regional_company_uuid/business",
    title: "Business Unit",
  },
  business_add: {
    id: "business_add",
    path: "add",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid/region/:regional_company_uuid/business/add",
    title: "Add Business Unit",
  },
  business_view: {
    id: "business_view",
    path: ":business_unit_uuid",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid/region/:regional_company_uuid/business/:business_unit_uuid",
    title: "Business Unit",
  },
  business_edit: {
    id: "business_edit",
    path: "edit",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid/region/:regional_company_uuid/business/:business_unit_uuid/edit",
    title: "Edit Business Unit",
  },
  business_contacts: {
    id: "business_contacts",
    path: "contacts",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid/region/:regional_company_uuid/business/:business_unit_uuid/contacts",
    title: "Business Unit Contacts",
  },
  business_contact_add: {
    id: "business_contact_add",
    path: "add",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid/region/:regional_company_uuid/business/:business_unit_uuid/contacts/add",
    title: "Add Business Unit Contact",
  },
  business_contact_edit: {
    id: "business_contact_edit",
    path: ":business_unit_contact_uuid",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid/region/:regional_company_uuid/business/:business_unit_uuid/contacts/:business_unit_contact_uuid",
    title: "Edit Business Unit Contact",
  },
  // Business Site
  site: {
    id: "site",
    path: "site",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid/region/:regional_company_uuid/business/:business_unit_uuid/site",
    title: "Site",
  },
  site_add: {
    id: "site_add",
    path: "add",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid/region/:regional_company_uuid/business/:business_unit_uuid/site/add",
    title: "Add Site",
  },
  site_view: {
    id: "site_view",
    path: ":site_uuid",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid/region/:regional_company_uuid/business/:business_unit_uuid/site/:site_uuid",
    title: "Site",
  },
  site_edit: {
    id: "site_edit",
    path: "edit/*",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid/region/:regional_company_uuid/business/:business_unit_uuid/site/:site_uuid/edit",
    title: "General",
  },
  site_edit_tank_details: {
    id: "site_edit",
    path: "tank_details",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid/region/:regional_company_uuid/business/:business_unit_uuid/site/:site_uuid/edit/tank_details",
    title: "Tank Details",
  },
  site_edit_plant_details: {
    id: "site_edit",
    path: "plant_details",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid/region/:regional_company_uuid/business/:business_unit_uuid/site/:site_uuid/edit/plant_details",
    title: "Plant Details",
  },
  site_edit_tank_access: {
    id: "site_edit",
    path: "tank_access",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid/region/:regional_company_uuid/business/:business_unit_uuid/site/:site_uuid/edit/tank_access",
    title: "Tank Access",
  },
  site_edit_billing_information: {
    id: "site_edit",
    path: "billing_information",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid/region/:regional_company_uuid/business/:business_unit_uuid/site/:site_uuid/edit/billing_information",
    title: "Billing Information",
  },
  site_contacts: {
    id: "site_contacts",
    path: "contacts",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid/region/:regional_company_uuid/business/:business_unit_uuid/site/:site_uuid/contacts",
    title: "Contacts",
  },
  site_contact_add: {
    id: "site_contact_add",
    path: "contacts",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid/region/:regional_company_uuid/business/:business_unit_uuid/site/:site_uuid/contacts/add",
    title: "Add Site Contact",
  },
  site_contact_edit: {
    id: "site_contact_edit",
    path: ":site_contact_uuid",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid/region/:regional_company_uuid/business/:business_unit_uuid/site/:site_uuid/contacts/:site_contact_uuid",
    title: "Edit Site Contact",
  },
  representative_assignments: {
    id: "representative_assignments",
    path: "representative_assignments",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid/region/:regional_company_uuid/business/:business_unit_uuid/site/:site_uuid/representative_assignments",
    title: "Representative Assignment",
  },
  representative_assignment_add: {
    id: "representative_assignment_add",
    path: "representative_assignments",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid/region/:regional_company_uuid/business/:business_unit_uuid/site/:site_uuid/representative_assignments/add",
    title: "Add Representative Assignment",
  },
  representative_assignment_edit: {
    id: "representative_assignment_edit",
    path: ":representative_assignment_uuid",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid/region/:regional_company_uuid/business/:business_unit_uuid/site/:site_uuid/representative_assignments/:representative_assignment_uuid",
    title: "Edit Representative Assignment",
  },
  customer_contract: {
    id: "customer_contract",
    path: "customer_contract",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid/region/:regional_company_uuid/business/:business_unit_uuid/site/:site_uuid/customer_contract",
    title: "Customer Contract",
  },
  customer_contract_add: {
    id: "customer_contract_add",
    path: "add",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid/region/:regional_company_uuid/business/:business_unit_uuid/site/:site_uuid/customer_contract/add",
    title: "Add Customer Contract",
  },
  customer_contract_edit: {
    id: "customer_contract_edit",
    path: ":customer_contract_uuid/edit",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid/region/:regional_company_uuid/business/:business_unit_uuid/site/:site_uuid/customer_contract/:customer_contract_uuid/edit",
    title: "Edit Customer Contract",
  },
  customer_contract_add_minimum: {
    id: "customer_contract_add_minimum",
    path: ":customer_contract_uuid/add/minimum",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid/region/:regional_company_uuid/business/:business_unit_uuid/site/:site_uuid/customer_contract/:customer_contract_uuid/add/minimum",
    title: "Add Pricing Minimum",
  },
  customer_contract_add_fee: {
    id: "customer_contract_add_fee",
    path: ":customer_contract_uuid/add/fee",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid/region/:regional_company_uuid/business/:business_unit_uuid/site/:site_uuid/customer_contract/:customer_contract_uuid/add/fee",
    title: "Add Pricing Fee",
  },
  customer_contract_add_rate: {
    id: "customer_contract_add_rate",
    path: ":customer_contract_uuid/add/rate",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid/region/:regional_company_uuid/business/:business_unit_uuid/site/:site_uuid/customer_contract/:customer_contract_uuid/add/rate",
    title: "Add Pricing Rate",
  },
  supplier_contract: {
    id: "supplier_contract",
    path: "supplier_contract",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid/region/:regional_company_uuid/business/:business_unit_uuid/site/:site_uuid/supplier_contract",
    title: "Supplier Contract",
  },
  supplier_contract_add: {
    id: "supplier_contract_add",
    path: "add",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid/region/:regional_company_uuid/business/:business_unit_uuid/site/:site_uuid/supplier_contract/add",
    title: "Add Supplier Contract",
  },
  supplier_contract_edit: {
    id: "supplier_contract_edit",
    path: ":supplier_contract_uuid/edit",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid/region/:regional_company_uuid/business/:business_unit_uuid/site/:site_uuid/supplier_contract/:supplier_contract_uuid/edit",
    title: "Edit Supplier Contract",
  },
  supplier_contract_add_take_or_pay_term: {
    id: "supplier_contract_add_take_or_pay_term",
    path: ":supplier_contract_uuid/add/take_or_pay_term",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid/region/:regional_company_uuid/business/:business_unit_uuid/site/:site_uuid/supplier_contract/:supplier_contract_uuid/add/take_or_pay_term",
    title: "Add Take or Pay Term",
  },
  supplier_contract_add_rate: {
    id: "supplier_contract_add_rate",
    path: ":supplier_contract_uuid/add/rate",
    fullPath: "/dashboard/site_admin/parent/:parent_company_uuid/region/:regional_company_uuid/business/:business_unit_uuid/site/:site_uuid/supplier_contract/:supplier_contract_uuid/add/rate",
    title: "Add Supplier Contract Rate",
  },
  tab_path: {
    id: "tab_path",
    path: ":tab_path",
  },
  // portals
  portal_navbar_left: {
    id: "portal_navbar_left",
  },
  portal_navbar_center: {
    id: "portal_navbar_center",
  },
  portal_navbar_right: {
    id: "portal_navbar_right",
  }

};
