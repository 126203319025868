/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import CustomerAdminContext from '../../contexts/CustomerAdminContext';
import { useLookupContext } from '../../contexts';
import { MListItemCustom, MList } from '../@material-extend';
import { useNavigateTo } from '../../helpers';
import { Paper, Grid } from '@mui/material';
import MoreMenu from '../MoreMenu';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useAuth0 } from '@auth0/auth0-react';
import Restricted from '../Restricted';
import { permissions } from '../../configs/permissionConstants';
import { routeConfig } from '../../configs';
import { confirmDialog } from '../ConfirmDialog';

function RepresentativeAssignmentList(props) {
  const { entities, deleteEntity } = useContext(CustomerAdminContext);
  const [params] = useNavigateTo();
  const [representativeAssignments, setRepresentativeAssignments] = useState([{
    id: "add",
    // disableButton: true,
    sx: { p: 2 },
    left: { title: "No representative assignments", },
  }]);
  const { getAccessTokenSilently } = useAuth0();
  const lookup = useLookupContext();

  const [, navigateTo] = useNavigateTo();

  const navigateToRepresentative = (representative) => {
    navigateTo(routeConfig.representative_assignment_edit, { representative_assignment_uuid: representative.representative_assignment_uuid })
  };

  async function deleteRepresentativeAssignment(representativeAssignment) {
    const token = await getAccessTokenSilently();
    deleteEntity(token, representativeAssignment);
  }

  useEffect(() => {
    if (!lookup.values.nitrocrete_representative.length) {
      return;
    }
    if (entities.representative_assignment.length) {
      const representative_assignments = entities.representative_assignment.filter(ra => ra.site_uuid === params.site_uuid);

      const newRepresentativeAssignments =
        representative_assignments.map(ra => {
          let representative_name = lookup.values.nitrocrete_representative.find(x => x.value === ra.nitrocrete_representative)?.label
          const is_active = lookup.values.nitrocrete_representative.find(x => x.value === ra.nitrocrete_representative)?.nitrocrete_representative_active
          if (!is_active) {
            representative_name = representative_name.concat(" - INACTIVE");
          }

          let options = []
          if (is_active) {
            options.push({
              label: 'Edit Assignment',
              icon: <EditIcon />,
              onClick: navigateToRepresentative
            });
          }
          options.push({
            label: 'Delete Assignment',
            icon: <DeleteIcon />,
            onClick: (ra) => {
              confirmDialog("Do you want to delete the representative assignment?", () => deleteRepresentativeAssignment(ra))
            }
          });

          let menu_content = {}
          if (ra.representative_type === 'FIELD_OPS_MGR') {
            menu_content = {
              children: <Grid container justifyContent="flex-end" alignItems={"center"}>
                <Restricted to={[permissions.WRITE_REPRESENTATIVE_OPS]}>
                  <MoreMenu
                    data={ra}
                    GridProps={{ item: true, xs: 2 }}
                    options={options}
                  />
                </Restricted>
              </Grid>
            }
          }
          else if (ra.representative_type === 'SALES_REP') {
            menu_content = {
              children: <Grid container justifyContent="flex-end" alignItems={"center"}>
                <Restricted to={[permissions.WRITE_REPRESENTATIVE_SALES]}>
                  <MoreMenu
                    data={ra}
                    GridProps={{ item: true, xs: 2 }}
                    options={options}
                  />
                </Restricted>
              </Grid>
            }
          }
          else {                // allow for delete of OTHER
            menu_content = {
              children: <Grid container justifyContent="flex-end" alignItems={"center"}>
                <MoreMenu
                  data={ra}
                  GridProps={{ item: true, xs: 2 }}
                  options={options}
                />
              </Grid>
            }
          }

          return {
            id: ra.id,
            left: {
              title: representative_name,
              subtitles: [
                (`Type: ${lookup.values.representative_type.find(x => x.value === ra.representative_type)?.label}`),
                (ra['effective_date'] && `Efective Date: ${ra['effective_date']}`)
              ]
            },
            right: menu_content
          }
        });

      if (newRepresentativeAssignments !== representativeAssignments) {
        setRepresentativeAssignments(newRepresentativeAssignments.length === 0 ? [{
          id: "add",
          // disableButton: true,
          sx: { p: 2 },
          left: { title: "No representative assignments", },
        }] : newRepresentativeAssignments);
      }
    }
  }, [entities]);

  return (
    <MList
      dense
      divider
      items={representativeAssignments.length > 0 ? representativeAssignments : [{
        disableButton: true,
        sx: { p: 2 },
        left: { title: "No representative assignments", },
      }]}
      component={item => <MListItemCustom
        component={Paper}
        elevation={3}
        GridProps={{ sx: { mb: 2, p: 2 } }}
        {...item}
        sx={{
          py: 1,
          px: 2,
          ...item.sx,
          bgcolor: "theme.background.paper",
          color: "theme.text.primary",
        }}
      />
      }
    />
  );
}

export default RepresentativeAssignmentList;