import { alpha } from "@mui/material";

export default function Lists(theme) {
  return {
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          minWidth: 'auto',
          marginRight: theme.spacing(2)
        }
      }
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: theme.spacing(2)
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "inherit",
            '&:hover': {
              backgroundColor: "inherit",
            },
          },
          '&:hover': {
            backgroundColor: "inherit",
          },
        }
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&:hover': {

          },
          "&.Mui-selected": {
            backgroundColor: alpha(theme.palette.secondary.main, 0.04),
            '&:hover': {
              backgroundColor: theme.palette.background.default,
            },
          }
        }
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          marginTop: 0,
          marginBottom: 0
        },
        multiline: {
          marginTop: 0,
          marginBottom: 0
        }
      }
    }
  }
}