import { useEffect, useContext } from 'react';
import { useForm } from "react-hook-form";
import { routeConfig } from '../../configs';
import { UsageEventContext, LayoutContext, LookupContext, useSiteContext } from '../../contexts';
import { useNavigateTo } from '../../helpers';
import { useSiteView } from '../../helpers/useViews';
import { MDivider, MForm, MFormTextField } from '../@material-extend';
import MFormDateTimePicker from '../@material-extend/MFormDateTimePicker';
import PageText from '../templates/PageText';
import MFormAutocomplete from '../@material-extend/MFormAutocomplete';
import { useAuth0 } from '@auth0/auth0-react';


const defaultValues = {
  manual_entry: true,
  usage_begin_timestamp: null,
  usage_end_timestamp: null,
  tank_level_begin: 0.0001,
  tank_level_end: 0.0001,
  usage_pulses: null,
  usage_duration: null,
  usage_quantity: null,
  starting_pressure: 0.0001,
  target_pressure: 0.0001,
  target_upm: 0.0001,
  actual_upm: 0.0001,
  target_duration: 0.0001,
  run_count_id: null,
  level_selection: 0.0001,
  dispensing_type: 0.0001,
  quantity_required: null,
  milliseconds_per_pulse: 0.0001,
  quantity_per_pulse: 0.0001,
  testing_mode: false
}

export function UsageEventForm(props) {
  const { upsertEntity } = useContext(UsageEventContext);
  const layout = useContext(LayoutContext);
  const [, navigateTo] = useNavigateTo();
  const lookup = useContext(LookupContext);
  const siteContext = useSiteContext();
  const siteView = useSiteView();
  const { user } = useAuth0();


  const form = useForm({
    defaultValues: defaultValues,
  });

  const handleSelectSite = (site) => {
    siteContext.setActiveEntity(site.site_uuid);
    siteView.refreshView();
  }

  function isOptionEqualToValue(option, value) {
    if (value === "") {
      return true;
    }
    return option.site_uuid.localeCompare(value.site_uuid);
  }

  const handleValidateForm = (data) => {
    let errorMessage = null;

    if (!data.site_uuid) {
      errorMessage = "Select a site for usage"
    }
    else if (!data.run_count_id) {
      data.run_count_id = null;
      errorMessage = "Run count id required"
    }
    else if (!data.usage_end_timestamp) {
      errorMessage = "Select an usage end timestamp"
    }
    else if (!data.usage_duration) {
      data.usage_duration = null;
      errorMessage = "Duration required"
    }
    else if (!data.usage_pulses) {
      data.usage_pulses = null;
      errorMessage = "Usage pulses required"
    }
    else if (!data.quantity_required) {
      data.quantity_required = null;
      errorMessage = "Requested quantity required"
    }
    else if (!data.usage_quantity) {
      data.usage_quantity = null;
      errorMessage = "Delivered quantity required"
    }

    if (errorMessage) {
      layout.handleError(errorMessage);
      return false;
    }
    return true;
  }

  function onSubmit(data) {

    const isValidForm = handleValidateForm(data);
    if (!isValidForm) {
      return;
    }

    // manual_entry is set to true in form defaultValues

    let endDate = data.usage_end_timestamp
    let seconds = data.usage_duration
    data.usage_begin_timestamp = new Date(endDate.getTime() - seconds * 1000)
    data.manual_update_by = user.nickname

    return upsertEntity(data).then(() => {
      navigateTo(routeConfig.usage_table);
    })
  }

  useEffect(() => {
    if (props.form_data) {
      form.reset(props.form_data)
    }
  }, [props.form_data, form])

  return (
    <MForm
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      form={form}
    >
      <MFormAutocomplete
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        name="site_uuid"
        label="Select Site*"
        options={lookup.values.site_hierarchy}
        GridProps={{
          xs: 9,
        }}
        onOptionSelect={handleSelectSite}
        isOptionEqualToValue={isOptionEqualToValue}
      />
      <PageText
        label="Unit of Measure"
        GridProps={{ xs: 3, md: 3, }}
        body={siteView?.unit_of_measure || "-"}
        align="center"
      />
      <MFormTextField
        name="run_count_id"
        label="Run Count"
        GridProps={{ xs: 6 }}
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
      />
      <MDivider
        direction="row"
        variant="form"
      />
      {/* ----------------------------- Detail Fields ----------------------------- */}
      <MFormDateTimePicker
        GridProps={{ xs: 6 }}
        name="usage_end_timestamp"
        label="Usage End Time"
        clearable
        autoFocus
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
      />
      <MFormTextField
        name="usage_duration"
        label="Usage Duration (in seconds)"
        GridProps={{ item: true, xs: 6 }}
        required
        type="number"
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
      />
      <MFormTextField
        name="usage_pulses"
        label="Pulses"
        GridProps={{ item: true, xs: 12 }}
        required
        type="number"
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
      />
      <MFormTextField
        name="quantity_required"
        label="Requested Quantity"
        GridProps={{ item: true, xs: 12 }}
        required
        type="number"
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
      />

      <MFormTextField
        name="usage_quantity"
        label="Delivered Quantity"
        GridProps={{ item: true, xs: 12 }}
        required
        type="number"
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
      />


      <PageText
        body={"Quantities are in site unit of measure"}
      />

    </MForm>
  );
}