import React, { useContext, useEffect, useState } from 'react';
import MList from '../@material-extend/MList';
import { routeConfig } from '../../configs';
import { MCard } from '../@material-extend';
import CustomerAdminContext from '../../contexts/CustomerAdminContext';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigateTo } from '../../helpers';
import { useAuth0 } from '@auth0/auth0-react';

RegionalCompanyList.propTypes = {
  filterItems: PropTypes.func,
}

/**
 * All regional Companies.
 * Regional Company Fields:
 * - created_at: "2022-01-31T22:22:10.716192"
 * - regional_company_name: "regional company one - UPDATED"
 * - regional_company_uuid: "ff9121c5-5a81-48bf-b7e6-b96de9737801"
 * - updated_at: "2022-01-31T22:22:43.359807"
 */
export function RegionalCompanyList(props) {
  const context = useContext(CustomerAdminContext)
  const [, navigateTo] = useNavigateTo();
  const { getAccessTokenSilently } = useAuth0();

  const [items, setItems] = useState([]);

  useEffect(() => {
    const newItems = props.filterItems ? context.entities.region.filter(props.filterItems) : context.entities.region;
    if (newItems !== items) {
      setItems(newItems);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [props, context.entities])

  const handleClickItem = (item) => {
    const params = context.getHierarchyParams(item);
    navigateTo(routeConfig.region_view, params)
  }



  return (
    <MCard
      title="Regional"
      // subtitle={`Count: ${items.length}`}
      // cardActions={context.params.parent_company_uuid && <Button
      //   children="Add region"
      //   onClick={() => context.navigateTo(routeConfig.region_add)}
      //   startIcon={<AddCircleOutlineIcon />}
      // />}
    >
      <MList
        dense
        onClick={handleClickItem}
        items={items.map(item => ({
          ...item,
          data: item,
          id: item.regional_company_uuid,
          title: item.regional_company_name,
          selected: context.params.regional_company_uuid === item.regional_company_uuid,
          secondaryAction: context.dev_control.show_delete_icon_on_lists && <IconButton
          onClick={async (item) => {
            const token = await getAccessTokenSilently();
            context.deleteEntity(token, item);}}
          children={<DeleteIcon />}
          />
        }))}
      />
    </MCard>
  );
}
