import React, { useState } from 'react';

const defaultValue = {
    pageExpanded: true,
    sections: {},
    mountSection: (section) => { },
    unmountSection: (sectionId) => { },
    handleExpandPage: (isExpanded) => { console.log("setting expanded", isExpanded); },
    handleExpandSection: (sectionId) => { },
}

export const PageContext = React.createContext(defaultValue);

export const usePageContext = () => {

    const [pageExpanded, setPageExpanded] = useState(true);

    const [sections, setSections] = useState({});

    const handleExpandPage = (isExpanded = !pageExpanded) => {
        setPageExpanded(isExpanded);
        setSections(prevState => {
            const newState = { ...prevState };
            for (var section in prevState) {
                newState[section].expanded = isExpanded;
            }
            return newState;
        });
    };

    const handleExpandSection = (sectionId) => {
        setSections(prevState => ({
            ...prevState, [sectionId]: {
                ...prevState[sectionId],
                expanded: !prevState[sectionId].expanded
            }
        }));
    };

    const mountSection = (section) => {
        setSections(prevState => ({
            ...prevState,
            [section.id]: {
                ...section,
                expanded: true
            }
        }));
    };

    const unmountSection = (sectionId) => {
        setSections(prevState => {
            const newSections = { ...prevState };
            delete newSections[sectionId];
            return newSections;
        });
    }

    return {
        pageExpanded,
        sections,
        handleExpandPage,
        handleExpandSection,
        mountSection,
        unmountSection,
    }
};

export default PageContext;