export const fetchWrapper = {
  get,
  post,
  put,
  delete: _delete
};

const appendParam = (endpoint, key, value) => {
  let result = endpoint;

  if (!endpoint.includes('?'))
    result = result + '?';

  return result + key + '=' + value;
}

const appendParams = (endpoint, params) => {
  let result = endpoint;

  while (Object.keys(params).length > 0) {
    const key = Object.keys(params)[0];
    const value = params[key];
    result = result.includes('?')
      ? result + '&'
      : result;
    result = appendParam(result, key, value);
    delete params[key];
  }

  return result;
}

function get(token, endpoint, params) {
  const url = !params ? endpoint : appendParams(endpoint, params);
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` }
  };
  return fetch(url, requestOptions).then(handleResponse);
}

function post(token, url, body) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    body: JSON.stringify(body)
  };
  return fetch(url, requestOptions).then(handleResponse);
}

function put(token, url, body) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}`  },
    body: JSON.stringify(body)
  };
  return fetch(url, requestOptions).then(handleResponse);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(token, url) {
  const requestOptions = {
    method: 'DELETE',
    headers: { Authorization: `Bearer ${token}` }
  };
  return fetch(url, requestOptions).then(handleResponse);
}

// helper functions

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
