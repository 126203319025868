import * as React from 'react';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
}); // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591


MBreadcrumbs.propTypes = {
    onClick: PropTypes.func,
    tooltipProps: PropTypes.object,
    itemProps: PropTypes.object,
    separator: PropTypes.string,
    color: PropTypes.oneOf(["primary", "secondary", "inherit", "info"]),
    items: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        sx: PropTypes.object,
        onClick: PropTypes.func,
        active: PropTypes.bool,
        tooltip: PropTypes.string,
    }))
}

/**
 * Breadcrumbs component
 * See [MUI Docs](https://mui.com/material-ui/react-breadcrumbs/#customization)
 * @param {*} props 
 * @returns 
 */
function MBreadcrumbs(props) {

    function handleClick(event, item) {
        event.preventDefault();
        if (item.onClick) {
            item.onClick();
        }
        if (props.onClick) {
            props.onClick(item);
        }
        console.info('You clicked a breadcrumb.', item);
    }

    const crumbs = props.items.map((item, index) => {

        let result = (<Link
            {...item}
            color={"inherit"}
            // color={!!props.color
            //     ? props.color
            //     : props.disabled
            //         ? "inherit"
            //         : "info"}
            underline={item.disabled ? "none" : "hover"}
            key={index}
            component={StyledBreadcrumb}
            sx={{
                // color: "info.main",
                cursor: item.disabled ? "default" : "pointer"
            }}
            onClick={item.disabled || (!props.onClick && !item.onClick) ? undefined : (event) => handleClick(event, item)}
            {...props.itemProps}
        />);
        const { tooltip } = item;
        if (tooltip) {
            let tooltipProps = !tooltip || typeof tooltip === 'string' ?
                {
                    placement: "top",
                    title: tooltip || props.children,
                    ...props.tooltipProps
                } :
                {
                    placement: "top",
                    ...tooltip,
                    title: props.tooltip.title || props.children,
                    ...props.tooltipProps
                };

            if (tooltipProps.tooltip) {
                delete tooltipProps.tooltip;
            }
            result = <Tooltip {...tooltipProps} children={result} />

        }
        return result;
    })


    return (<MuiBreadcrumbs separator={props.separator !== undefined ? props.separator : "›"} aria-label="breadcrumb">
        {crumbs}
    </MuiBreadcrumbs>);
}

export default MBreadcrumbs;
