import { useMediaQuery, useTheme } from "@mui/material";
import { useState, useEffect } from "react";

export const mobileScreenWidth = 1280;

export const doNotShowMoreTokenTableWidth = 510;


export function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}


/**
  * Be careful using this hook. It only works because the number of
  * breakpoints in theme is static. It will break once you change the number of
  * breakpoints. See https://reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level
  */
export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    const theme = useTheme();
    const keys = [...theme.breakpoints.keys].reverse();

    const size = keys.reduce((output, key) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const matches = useMediaQuery(theme.breakpoints.up(key));
        return !output && matches ? key : output;
    }, null) || "sm";

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const isXlUp = size === "xl";
    const isLgUp = isXlUp || size === "lg";
    const isMdUp = isLgUp || size === "md";
    const isSmUp = isMdUp || size === "sm";
    const isXsUp = isSmUp || size === "xs";



    return {
        width: windowDimensions.width,
        height: windowDimensions.height,
        /**
      * Returns true if screen width is `sm` or `xs`.
      */
        isMobile: size === "sm" || size === "xs",
        isDesktop: size === "md" || size === "lg" || size === "xl",
        size,
        isXs: size === "xs",
        isSm: size === "sm",
        isMd: size === "md",
        isLg: size === "lg",
        isXl: size === "xl",

        isXlUp,
        isLgUp,
        isMdUp,
        isSmUp,
        isXsUp,
    };
}
