import Page from '../../../templates/Page';
import { SupplierTakeOrPayTermForm } from '../../../forms/SiteForm/SiteSupplierTakeOrPayTermForm';
import { routeConfig } from '../../../../configs';
import { PageSection } from '../../../templates';
import { useContext } from 'react';
import { CustomerAdminContext } from '../../../../contexts';

export default function AddSupplierTakeOrPayTerm() {
  const { navigateTo } = useContext(CustomerAdminContext);

  return (
    <Page
      route={routeConfig.supplier_contract_add_take_or_pay_term}
      onBack={() => navigateTo(routeConfig.site_view)}
    >
      <PageSection>
        <SupplierTakeOrPayTermForm />
      </PageSection>
    </Page>
  );
} 