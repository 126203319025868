import React, { useEffect, useState } from 'react';
import { MList, MListItemCustom } from '../@material-extend';
import { Paper, Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import { useLookupContext } from '../../contexts';
import MoreMenu from '../MoreMenu';
import Restricted from '../Restricted';
import { permissions } from '../../configs/permissionConstants';
import { confirmDialog } from '../ConfirmDialog';

PricingMinimumList.propTypes = {
  items: PropTypes.array,
  handleDeleteMinimum: PropTypes.func,
}

function PricingMinimumList(props) {
  const [pricingMinimums, setPricingMinimums] = useState([])
  const lookup = useLookupContext();

  useEffect(() => {
    if (!lookup.values.minimum_type.length) {
      return;
    }
    if (props.items) {
      const pricingMinimums = props.items;

      const newPricingMinimums =
        pricingMinimums.map(pm => {
          const displayPm = {
            ...pm,
            minimum_type: lookup.byValue("minimum_type", pm?.minimum_type)?.label || "-",
            effective_date: pm?.pricing_minimum_effective_date || "-",
            annual_minimum_date: pm?.annual_minimum_date || "-",
            minimum_quantity: pm?.minimum_quantity || "-",
          }
          return {
            id: pm.id,
            left: {
              title: displayPm.pricing_minimum_description,
              subtitles: [
                (`Minimum Type: ${displayPm.minimum_type}`),
                (`Minimum Quantity: ${displayPm.minimum_quantity}`),
                (`Annual Minimum Date : ${displayPm.annual_minimum_date}`),
                (`Effective Date: ${displayPm.effective_date}`),
              ]
            },
            right: {
              children: <Grid container justifyContent="flex-end" alignItems={"center"}>
                <Restricted to={[permissions.WRITE_CUSTOMER_CONTRACT_PRICING_FEES]}>
                  <MoreMenu
                    data={{ uuid: displayPm.pricing_minimum_uuid }}
                    GridProps={{ item: true, xs: 2 }}
                    options={[
                      {
                        label: 'Delete Minimum',
                        icon: <DeleteIcon sx={{ color: "error.main" }} />,
                        onClick: (data) => {
                          confirmDialog("You should NOT BE DELETING this data in the case of a new contract, rate increase, nor any other amendment.  NITROvision maintains a historical record of data.  The “effective date” is used to recognized the active contract data.  Do you want to delete the pricing minimum?", () => props.handleDeleteMinimum(data))
                        }
                      },
                    ]}
                  />
                </Restricted>
              </Grid>
            }
          }
        });

      if (newPricingMinimums !== pricingMinimums) {
        setPricingMinimums(newPricingMinimums.length === 0 ? [{
          id: "add",
          sx: { p: 2 },
          left: { title: "  No pricing minimums", },
        }] : newPricingMinimums);
      }
    }
  }, [props, lookup]);

  return (

    <MList
      dense
      items={pricingMinimums.length > 0 ? pricingMinimums : [{
        disableButton: true,
        sx: { p: 2 },
        left: { title: "  No pricing minimums", },
      }]}
      component={item => <MListItemCustom
        component={Paper}
        elevation={5}
        GridProps={{ sx: { mb: 2, p: 2 } }}
        {...item}
        sx={{
          py: 1,
          px: 2,
          ...item.sx,
          bgcolor: "theme.background.paper",
          color: "theme.text.primary",
        }}
      />
      }
    />
  );
}

export default PricingMinimumList;
