/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from 'react';
import { useForm } from "react-hook-form";
import { MForm, MFormTextField, MFormDatePicker } from '../../@material-extend';
import { LayoutContext, usePricingRateContext } from '../../../contexts';
import { dateHelper, useNavigateTo } from '../../../helpers';
import { routeConfig } from '../../../configs';
import PageText from '../../templates/PageText';
import { useSiteView } from '../../../helpers/useViews';

export function SitePricingRateForm(props) {
  const layout = useContext(LayoutContext);
  const [params, navigateTo] = useNavigateTo();
  const pricingRateContext = usePricingRateContext();
  const siteView = useSiteView();


  const form = useForm({
    defaultValues: pricingRateContext.defaultValues,
  });

  const handleValidateForm = (data) => {
    let errorMessage = null;
    if (!data.pricing_rate_description) {
      data.pricing_rate_description = null;
      errorMessage = "Provide a pricing rate description.";
    }
    else if (!data.tank_delivery_rate) {
      data.tank_delivery_rate = null;
      errorMessage = "Provide a tank fill rate.";
    }
    else if (!data.effective_date) {
      data.effective_date = null;
      errorMessage = "Select an effective date.";
    }

    if (errorMessage) {
      layout.handleError(errorMessage);
      return false;
    }
    return true;
  }

  const onSubmit = async (data) => {

    const isValidForm = handleValidateForm(data);
    if (!isValidForm) {
      return;
    }

    data.customer_contract_uuid = params.customer_contract_uuid;
    data.effective_date = dateHelper.formatDate(data.effective_date);

    pricingRateContext.upsertEntity(data)
      .then(res => {
        console.log("Saved pricing rate", res);
        navigateTo(routeConfig.site_view);
      }).catch((e) => {
        console.log("Error saved pricing rate", e);
      });
  }

  return (
    <MForm
      form={form}
      onSubmit={onSubmit}
    >
      <MFormTextField
        name="pricing_rate_description"
        label="Rate Description"
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        minRows={2}
        multiline
        fullWidth
        required
      />
      <MFormTextField
        name="usage_rate"
        label="Usage Rate"
        normalize='currency'
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        GridProps={{
          xs: 12,
          md: 6,
        }}
      />
      <MFormTextField
        name="tank_delivery_rate"
        label="Tank Fill Rate"
        normalize='currency'
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        required
        GridProps={{
          xs: 12,
          md: 6,
        }}
      />
      <MFormDatePicker
        name="effective_date"
        label="Effective Date"
        clearable
        required
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        GridProps={{
          xs: 12,
          md: 6,
        }}
      />
      <PageText
        label="Rate Units"
        GridProps={{ xs: 6, md: 6, }}
        body={siteView?.currency_code + ' per ' + siteView?.unit_of_measure || "-"}
        align="center"
      />
    </MForm>
  );
}