import React, { useEffect, useState } from 'react';
import { MList, MListItemCustom } from '../@material-extend';
import { Paper, Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import MoreMenu from '../MoreMenu';
import Restricted from '../Restricted';
import { permissions } from '../../configs/permissionConstants';
import { confirmDialog } from '../ConfirmDialog';

TakeOrPayTermList.propTypes = {
  items: PropTypes.array,
  handleDeleteTakeOrPayTerm: PropTypes.func,
}

function TakeOrPayTermList(props) {
  const [takeOrPayTerms, setTakeOrPayTerms] = useState([])

  useEffect(() => {
    if (props.items) {
      const takeOrPayTerms = props.items;

      const newTakeOrPayTerms =
        takeOrPayTerms.map(pm => {
          const displayPm = {
            ...pm,
            effective_date: pm?.take_or_pay_term_effective_date || "-",
            minimum_quantity: pm?.minimum_quantity || "-",
            PictureInPictureWindow: pm?.PictureInPictureWindow || "-",

          }
          return {
            id: pm.id,
            left: {
              title: displayPm.take_or_pay_term_description,
              subtitles: [
                (`Minimum Quantity: ${displayPm.minimum_quantity}`),
                (`Period (months): ${displayPm.period}`),
                (`Effective Date: ${displayPm.effective_date}`),
              ]
            },
            right: {
              children: <Grid container justifyContent="flex-end" alignItems={"center"}>
                <Restricted to={[permissions.WRITE_SUPPLIER_CONTRACT_TAKE_OR_PAY_TERMS]}>
                  <MoreMenu
                    data={{ uuid: displayPm.take_or_pay_term_uuid }}
                    GridProps={{ item: true, xs: 2 }}
                    options={[
                      {
                        label: 'Delete Term',
                        icon: <DeleteIcon sx={{ color: "error.main" }} />,
                        onClick: (data) => {
                          confirmDialog("You should NOT BE DELETING this data in the case of a new contract, rate increase, nor any other amendment.  NITROvision maintains a historical record of data.  The “effective date” is used to recognized the active contract data.  Do you want to delete the take or pay term?", () => props.handleDeleteTakeOrPayTerm(data))
                        }
                      },
                    ]}
                  />
                </Restricted>
              </Grid>
            }
          }
        });

      if (newTakeOrPayTerms !== takeOrPayTerms) {
        setTakeOrPayTerms(newTakeOrPayTerms.length === 0 ? [{
          id: "add",
          sx: { p: 2 },
          left: { title: "  No take or pay terms", },
        }] : newTakeOrPayTerms);
      }
    }
  }, [props]);

  return (

    <MList
      dense
      items={takeOrPayTerms.length > 0 ? takeOrPayTerms : [{
        disableButton: true,
        sx: { p: 2 },
        left: { title: "  No take or pay terms", },
      }]}
      component={item => <MListItemCustom
        component={Paper}
        elevation={5}
        GridProps={{ sx: { mb: 2, p: 2 } }}
        {...item}
        sx={{
          py: 1,
          px: 2,
          ...item.sx,
          bgcolor: "theme.background.paper",
          color: "theme.text.primary",
        }}
      />
      }
    />
  );
}

export default TakeOrPayTermList;
