/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';

import Page from '../../templates/Page';

import { routeConfig } from '../../../configs';
import { useNavigateTo } from '../../../helpers';
import { PageSection } from '../../templates/PageSection';
import { Grid } from '@mui/material';
import { MForm, MFormTextField } from '../../@material-extend';
import { LayoutContext, LookupContext } from '../../../contexts';
import { useForm } from 'react-hook-form';
import {
  operationsCommentService
} from '../../../services/operations-comment.service';
import { useAuth0 } from '@auth0/auth0-react';
import { useSnackbar } from 'notistack';
import MFormAutocomplete from '../../@material-extend/MFormAutocomplete';



export default function AddOperationsComment() {
  const [, navigateTo] = useNavigateTo();
  const layout = useContext(LayoutContext);
  const { getAccessTokenSilently, user } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  const handleSuccess = (message) => {
    if (message)
      enqueueSnackbar(message, { variant: "success" });
  }

  const lookup = useContext(LookupContext);

  const defaultEntity = {
    site_uuid: '',
    operations_comment: '',
  }

  function isOptionEqualToValue(option, value) {
    if (value === "All") {
      return true;
    }
    if (option.label === "All") {
      return false;
    }
    return option.site_uuid.localeCompare(value.site_uuid);
  }

  const form = useForm({
    defaultValues: defaultEntity,
  });

  const handleValidateForm = (data) => {
    let errorMessage = null;
    if (!data.site_uuid) {
      data.site_uuid = null;
      errorMessage = "Choose a site.";
    }
    else if (!data.operations_comment) {
      data.operations_comment = null;
      errorMessage = "Provide an operations comment.";
    }

    if (errorMessage) {
      layout.handleError(errorMessage);
      return false;
    }
    return true;
  }

  async function onSubmit(data) {
    const token = await getAccessTokenSilently();
    const isValidForm = handleValidateForm(data);
    if (!isValidForm) {
      return;
    }
    const d = new Date();
    const monthDay = d.getMonth()+1 + "/" +  d.getDate();
    const comment = data.operations_comment.trim() + ' | ' + user.nickname + ' | ' + monthDay;
    const params = { "site_uuid": data.site_uuid, "operations_comment": comment }
    operationsCommentService.create(token, params)
    return operationsCommentService.create(token, params)
      .then(res => {
        console.log("Saved operations comment", res);
        handleSuccess(`Saved operations comment.`);
        form.reset({
          site_uuid: '',
          operations_comment: '',
        });
        navigateTo(routeConfig.site_admin)
        navigateTo(routeConfig.operations_comment)
      });

  }

  return (
    <Page
      hideHeader
      fullWidth
    >
      <MForm
        form={form}
        onSubmit={onSubmit}
        submitText={"Save Comment"}
      >
        <PageSection
          title={"Operations Comment Entry"}
          direction="row"
          headerSx={{ mt: 1 }}
          onBack={() => navigateTo(routeConfig.dashboard)}
          onBackTooltip={"Back to Dashboard"}>

          <Grid container
            item
            spacing={5}
            sx={{ pr: 1 }}
            xs={12}
          >
            <MFormAutocomplete
              onControlEnter={form.handleSubmit(onSubmit)}
              control={form.control}
              name="site_uuid"
              label="Select Site*"
              options={lookup.values.site_hierarchy}
              isOptionEqualToValue={isOptionEqualToValue}
              onOptionSelect={(site) => () => {return}}
              GridProps={{
                xs: 12,
                md: 6,
              }}
            />

            <MFormTextField
              onControlEnter={form.handleSubmit(onSubmit)}
              control={form.control}
              minRows={1}
              name="operations_comment"
              label="Comment"
              GridProps={{
                xs: 12,
                md: 6,
              }}
              required
            />

          </Grid>
        </PageSection>
      </MForm>

    </Page>
  );
}
