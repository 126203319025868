import React, { useEffect, useState } from 'react';
import { Autocomplete, Grid, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';

MFormAutocomplete.propTypes = {
  GridProps: PropTypes.object,
  control: PropTypes.object.isRequired,
  sx: PropTypes.object,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  multiline: PropTypes.bool,
  minRows: PropTypes.number,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  autoFocus: PropTypes.bool,
  onControlEnter: PropTypes.func,
  options: PropTypes.array,
}

function MFormAutocomplete({ control, onControlEnter, type, autoFocus, freeSolo, options = [], size, placeholder, GridProps = { xs: 12 }, disabled, sx, name, label, required, helperText, onOptionSelect, isOptionEqualToValue }) {

  const { field } = useController({
    control,
    name,
  })
  const { value, onChange } = field;

  const [displayValue, setDisplayValue] = useState(value);

  useEffect(() => {
    if (typeof value === "string") {
      const newValue = options.find(option => option.value === value);
      setDisplayValue(newValue);
    } else {
      setDisplayValue(value);
    }
  }, [value, options]);;


  function handleChange(event, componentValue, reason, details) {
    onOptionSelect(componentValue)
    onChange(componentValue.site_uuid);
  }

  const onKeyDown = (event) => {
    if (event.key !== 'Control' && event.ctrlKey && event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      onControlEnter();
    }
  }

  let result = <Autocomplete
    fullWidth
    clearOnBlur
    size={size}
    options={options}
    freeSolo={freeSolo}
    value={displayValue || ""}
    onKeyDown={onKeyDown}
    type={type}
    autoFocus={autoFocus}
    name={name}
    autoSelect={freeSolo}
    placeholder={placeholder}
    label={label}
    required={required}
    disabled={disabled}
    onChange={handleChange}
    renderInput={(params) => <TextField
      helperText={helperText}
      size={size}
      fullWidth
      {...params}
      label={label}
    />}
    isOptionEqualToValue={isOptionEqualToValue}
    sx={{
      ...sx,
      mt: 1,
      // mb: .5,
      backgroundColor: "#fff",
      borderRadius: 1,
    }}
  />


  if (GridProps) {
    const { fullRow, ...gridRest } = GridProps;
    result = <Grid item {...gridRest} children={result} />;
    if (fullRow) {
      result = <Grid container item xs={12} children={result} />;
    }
  }

  return result;

};

export default MFormAutocomplete