import { fetchWrapper } from './fetchWrapper';

export const createEntityService = (entityEndpoint) => {


  const baseUrl = `${process.env.REACT_APP_SERVER_URL}/api/${entityEndpoint}`;

  function getAll(token, query, endpointPath) {
    return fetchWrapper.get(token, baseUrl + endpointPath, query);
  }

  function getById(token, id) {
    return fetchWrapper.get(token, `${baseUrl}/${id}`);
  }

  function create(token, params) {
    return fetchWrapper.post(token, baseUrl, params);
  }

  function update(token, id, params) {
    return fetchWrapper.put(token, `${baseUrl}/${id}`, params);
  }

  // prefixed with underscored because delete is a reserved word in javascript
  function _delete(token,id) {
    return fetchWrapper.delete(token, `${baseUrl}/${id}`);
  }

  return {
    getAll,
    getById,
    create,
    update,
    delete: _delete
  };
}