import PropTypes from 'prop-types';

export const GridProps = PropTypes.shape({
    xs: PropTypes.number,
    sm: PropTypes.string,
    md: PropTypes.string,
    lg: PropTypes.string,
    xl: PropTypes.string,
    // md: PropTypes.oneOf(['M', 'F']),
    // birthdate: PropTypes.instanceOf(Date),
    item: PropTypes.bool
  })