import Page from '../../../templates/Page';
import { useContext } from 'react';
import CustomerAdminContext from '../../../../contexts/CustomerAdminContext';
import { routeConfig } from '../../../../configs';
import PageSection from '../../../templates/PageSection';
import { SiteCustomerContractForm } from '../../../forms/SiteForm';

export default function UpsertCustomerContract() {
  const { entities, navigateTo } = useContext(CustomerAdminContext);
  const formData = entities.active_customer_contract;

  const route = !formData
    ? routeConfig.customer_contract_add
    : routeConfig.customer_contract_edit;

  return (
    <Page route={route}
      onBack={() => navigateTo(routeConfig.site_view)}
    >
      <PageSection title="" denseY>
        <SiteCustomerContractForm form_data={formData} />
      </PageSection>
    </Page>
  );
}