/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import Page from '../../templates/Page';
import PageSection from '../../templates/PageSection';
import { routeConfig } from '../../../configs';
import { MButton, MDivider } from '../../@material-extend';
import { LookupContext, UsageEventContext } from '../../../contexts';
import { useNavigateTo } from '../../../helpers';
import PageText from '../../templates/PageText';
import { dateHelper } from '../../../helpers/dateHelper';
import Restricted from '../../Restricted';
import { useAuth0 } from '@auth0/auth0-react';
import { ButtonGroup } from '@mui/material';
import { permissions } from '../../../configs/permissionConstants';
import { confirmDialog } from '../../ConfirmDialog';


export default function UsageEvent() {

  const {
    activeEntity,
    setActiveEntity,
    upsertEntity,
    deleteEntity
  } = useContext(UsageEventContext);

  const [params, navigateTo] = useNavigateTo();
  const usage_event_uuid = params.usage_event_uuid;
  const lookup = useContext(LookupContext);
  const { user } = useAuth0();

  const handleToggleTestingMode = () => {
    let isTestingMode = !activeEntity.testing_mode
    return upsertEntity({ ...activeEntity, testing_mode: isTestingMode, manual_update_by: user.nickname }).then(() => {
      setActiveEntity(usage_event_uuid);
    })
  }

  const handleDeleteUsage = async () => {
    await deleteEntity(activeEntity.usage_event_uuid)
      .then(() => {
        navigateTo(routeConfig.usage_table)
      })
  }

  useEffect(() => {
    setActiveEntity(usage_event_uuid);
  }, []);

  return (
    <Page
      route={routeConfig.usage_view}
      gap={1}
      onBack={() => navigateTo(routeConfig.usage_table)}
    >
      <PageSection
        title="Details"
        spacing={1}
        collapsible
        omitContainer
      >
        <PageText
          label="Site Name"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.site_uuid ? lookup.byValue('site_hierarchy', activeEntity.site_uuid).label : ' - '}
        />
        <PageText
          label="Site UUID"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.site_uuid}
        />
        <PageText
          label="Site Id"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.site_id}
        />
        <PageText
          label="Site Unit of Measure"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.site_uuid ? lookup.byValue('site_hierarchy', activeEntity.site_uuid).uom : ' - '}
        />
        <MDivider
          variant='list-middle'
          direction='row'
        />
        <PageText
          label="Run Count Id"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.run_count_id}
        />
        <PageText
          label="Event UUID"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.usage_event_uuid}
        />
        <MDivider
          variant='list-middle'
          direction='row'
        />
        <PageText
          label="Pulses"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.usage_pulses}
        />
        <PageText
          label="Requested Quantity"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.quantity_required}
        />
        <PageText
          label="Delivered Quantity"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.usage_quantity}
        />
        <MDivider
          variant='list-middle'
          direction='row'
        />
        <PageText
          label="Duration Seconds"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.usage_duration}
        />
        <PageText
          label="Target Duration"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.target_duration}
        />
        <PageText
          label="Begin Event"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.usage_begin_timestamp ? dateHelper.formatLocalDateTime(activeEntity.usage_begin_timestamp) : "-"}
        />
        <PageText
          label="End Event"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.usage_end_timestamp ? dateHelper.formatLocalDateTime(activeEntity.usage_end_timestamp) : "-"}
        />
        <MDivider
          variant='list-middle'
          direction='row'
        />
        <PageText
          label="Begin Tank Level"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.tank_level_begin}
        />
        <PageText
          label="End Tank Level"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.tank_level_end}
        />
        <PageText
          label="Starting Pressure"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.starting_pressure}
        />
        <PageText
          label="Target Pressure"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.target_pressure}
        />
        <PageText
          label="Last UPM"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.actual_upm}
        />
        <PageText
          label="Target UPM"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.target_upm}
        />
        <MDivider
          variant='list-middle'
          direction='row'
        />
        <PageText
          label="Level Selection"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.level_selection}
        />
        <PageText
          label="Type of Use"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.dispensing_type}
        />
        <PageText
          label="MSec Timer"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.milliseconds_per_pulse}
        />
        <MDivider
          variant='list-middle'
          direction='row'
        />
        <PageText
          label="Last Updated"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.manual_update_timestamp ? dateHelper.formatLocalDateTime(activeEntity.manual_update_timestamp) : "-"}
        />
        <PageText
          label="Last Updated By"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.manual_update_by ? activeEntity.manual_update_by : "-"}
        />
        <PageText
          label="Manual Entry"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.manual_entry ? "True" : "False"}
        />
        <MDivider
          variant='list-middle'
          direction='row'
        />
        <PageText
          body={"Tank levels and quantity are in site unit of measure"}
        />
      </PageSection>

      <PageSection
        title={activeEntity?.testing_mode ? "True" : "False"}
        label='Testing Mode Status'
        endActions={<ButtonGroup sx={{ mr: 2 }}>
          <Restricted to={[permissions.WRITE_USAGES]}>
            <MButton
              onClick={handleToggleTestingMode}
              color="primary"
              children="Toggle Testing Mode"
              tooltip={"Toggle testing mode"}
            />
          </Restricted>
        </ButtonGroup>}
        collapsible
      />
      
      <PageSection
        title=''
        label='Delete the usage event?'
        endActions={<ButtonGroup sx={{ mr: 2 }}>
          <Restricted to={[permissions.DELETE_USAGES]}>
            <MButton
              size="small"
              tooltip="Delete selected usage event"
              onClick={() => {
                confirmDialog("Do you want to delete the usage event? This is permanent.", () => handleDeleteUsage())
              }}
              icon="delete"
              color="primary"
              children="Delete Usage Event"
            />
          </Restricted>
        </ButtonGroup>}
        collapsible
      />

    </Page >
  );
}