import MStepperNav from './MStepperNav';
import MStepNav from './MStepNav';
import * as React from 'react';
import StepperContext from './StepperContext';
import { Typography } from '@mui/material';

/**
 * 
 * @param {{
 *      asString: boolean,
 *      variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'caption' | 'button' | 'overline',
 * }} props 
 * @returns 
 */
function ActiveLabel({ variant = "subtitle1", asString, ...props }) {
    const { activeStep, steps } = React.useContext(StepperContext);

    const stepLabel = steps[activeStep] ? steps[activeStep].label : "";

    const label = `${activeStep + 1}. ${stepLabel}`;


    return asString ? label : <Typography variant={variant} children={`${activeStep + 1}. ${stepLabel}`} />;
}

const MStepper = {
    ActiveLabel: ActiveLabel,
    Stepper: MStepperNav,
    StepNav: MStepNav,
}

export default MStepper;