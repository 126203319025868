import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import ErrorMessage from './ErrorMessage';
import ErrorContext from '../../contexts/ErrorContext';

export default function Errors(props) {
    const { errors, removeError } = useContext(ErrorContext);
    if (errors.length === 0) {
        return <></>;
    }
    return (<Grid container direction="column">
        {errors.map(error =>
            <ErrorMessage key={`error-message-${error.id}`} {...error} removeError={() => removeError(error.id)} />
        )}
    </Grid>
    );
}
