import CustomerAdminContext from '../../contexts/CustomerAdminContext';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { entityConfig, routeConfig } from '../../configs';
import { useContext, useEffect, useState } from 'react';
import { ContactList } from './ContactList';
import PropTypes from 'prop-types';
import { useNavigateTo } from '../../helpers';
import { useAuth0 } from '@auth0/auth0-react';
import { confirmDialog } from '../ConfirmDialog';

BusinessUnitContactList.propTypes = {
  hidden: PropTypes.bool,
  transferList: PropTypes.bool,
  isSitePage: PropTypes.bool,
}

function BusinessUnitContactList(props) {
  const { getAccessTokenSilently } = useAuth0();
  const { entities, deleteEntity, params, createEntity } = useContext(CustomerAdminContext);
  const [, navigateTo] = useNavigateTo();

  const handleSelectContact = (contact) => {
    navigateTo(routeConfig.business_contact_edit, { business_unit_contact_uuid: contact.business_unit_contact_uuid });
  }

  async function deleteContact(item) {
    const token = await getAccessTokenSilently();
    deleteEntity(token, item);
  }

  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(entities.business_unit_contact.filter(contact => {
      return contact.business_unit_uuid === entities.active_business?.business_unit_uuid;
    }).map(contact => ({ ...contact })));

  }, [entities]);

  if (!props.hidden) {

    return (<ContactList
      onSelect={!props.isSitePage && handleSelectContact}
      type={entityConfig.type.business_unit_contact}
      transferList={props.transferList}
      contacts={items}
      options={!props.isSitePage && [
        {
          label: 'Delete Contact',
          subtitle: props.transferList ? "Only on Business Unit page" : undefined,
          disabled: props.transferList,
          icon: <DeleteIcon />,
          onClick: (item) => {
            confirmDialog("Do you want to delete the contact?", () => deleteContact(item))
          }
        },
        {
          label: 'Copy to Site Contacts',
          icon: <ContentCopyIcon />,
          hidden: true,
          onClick: async (item) => {
            const token = await getAccessTokenSilently();
            const siteContact = entityConfig.mapBusinessContactToSiteContact(item);
            siteContact.site_uuid = params.site_uuid;
            createEntity(token, siteContact);
          }
        }
      ]}
    />
    );
  }
  return <></>

}

export default BusinessUnitContactList;