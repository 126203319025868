import { Divider, Grid } from "@mui/material";
import PropTypes from "prop-types";
import { Typography } from '@mui/material';

MDividerSection.propTypes = {
    gap: PropTypes.number,
    spacing: PropTypes.number,
    collapsible: PropTypes.bool,
    justifyContent: PropTypes.string,
    alignItems: PropTypes.string,
    GridProps: PropTypes.object,
    children: PropTypes.node,
    above: PropTypes.object,
    direction: PropTypes.oneOf(["row", "column"]),
    below: PropTypes.object,
    xs: PropTypes.number,
    color: PropTypes.string,
    active: PropTypes.bool,
    position: PropTypes.oneOf(['form', 'list-middle', 'list-first', 'standard']),
    variant: PropTypes.oneOf(["section", "subsection"]),
}
/**
 * 
 * @param {{
 *    position: "form" | "list-middle" | "list-first" | "standard",
 *    variant: "section" | "subsection",
 * 
 * }} props 
 * @returns 
 */
function MDividerSection(props) {
    const {
        GridProps,
        gap,
        spacing,
        alignItems,
        justifyContent,
        collapsible,
        above,
        variant = "section",
        below,
        color,
        active,
        children,
        position = "standard",
        xs,
        ...rest
    } = props;


    const variantTitleSx = variant === "section"
        ? { color: props.active ? "primary.main" : (color || "secondary.dark") }
        : { color: props.active ? "primary.main" : (color || "inherit") };

    const itemColumnSx = position === "form" ? { mt: 1, mb: 0, mx: 0 }
        : position === "list-first" ? { mt: 1, mb: 1, }
            : position === "list-middle" ? { mt: 3, mb: 0, }
                : {};

    const itemColumnTitleTextVariant = variant === "section" ? "h5" : "h6";

    const direction = props.direction || position === "form" ? "row" : "column";
    const directionXs = direction === "row" ? 12 : undefined

    const gridSx = variant === "section"
        ? {
            spacing: spacing || 1.5,
            direction: direction,
            gap: gap,
            alignItems: alignItems,
            justifyContent: justifyContent,
            sx: GridProps?.sx,
        } : {
            spacing: spacing || 1,
            direction: direction,
            sx: GridProps?.sx,
            gap: gap,
            alignItems: alignItems,
            justifyContent: justifyContent,
        };
    return (

        <Grid container item {...gridSx}>

            <Grid
                item
                sx={{ ...itemColumnSx, ...props.sx }}
                xs={xs || GridProps?.xs || directionXs}
                {...GridProps}
            >
                {above && <ItemColumn
                    {...above}
                    variant={itemColumnTitleTextVariant}
                    titleSx={variantTitleSx}
                    sx={above.sx}
                />}
                <Divider
                    {...rest}
                />
                {below && <ItemColumn
                    {...below}
                    titleSx={variantTitleSx}
                    sx={below.sx}
                />}
            </Grid>
            {/* <Grid item container xs={12} sx={props.sx}> */}
                {children}
            {/* </Grid> */}
        </Grid >

    );
}

ItemColumn.propTypes = {
    GridProps: PropTypes.object,
    title: PropTypes.string,
    variant: PropTypes.string,
    subtitles: PropTypes.array,
    titleSx: PropTypes.object,
    subtitleSx: PropTypes.object,
    sx: PropTypes.object,
}
function ItemColumn({ GridProps, variant = "h6", ...props }) {

    return <Grid item xs={12} sx={props.sx} container direction="column" {...GridProps}>
        {props.title && <Typography variant={variant} sx={{ color: "inherit", ...props.titleSx }} children={props.title} />}
        {props.subtitles?.map((subtitle, index) => {
            return (
                <Typography variant="caption" key={index} sx={props.subtitleSx} color="text.secondary" children={subtitle} />
            )
        })}
    </Grid>
}
export default MDividerSection;