
export const lookupConfig = {
    application: "application",
    business_unit: "business_unit",
    channel: "channel",
    continent: "continent",
    country: "country",
    currency: "currency",
    customer_contract: "customer_contract",
    customer_contract_status: "customer_contract_status", 
    language: "language",
    nitrocrete_region: "nitrocrete_region",
    nitrocrete_representative: "nitrocrete_representative",
    parent_company: "parent_company",
    piping_type: "piping_type",
    plant_type: "plant_type",
    po_number_type: "po_number_type",
    fee_type: "fee_type",
    minimum_type: "minimum_type",
    pricing_model: "pricing_model",
    pricing_rate: "pricing_rate",
    regional_company: "regional_company",
    representative_assignment: "representative_assignment",
    representative_type: "representative_type",
    site: "site",
    site_hierarchy: "site_hierarchy",
    site_contact_type: "site_contact_type",
    site_status: "site_status",
    site_tank_delivery_charges: "site_tank_delivery_charges",
    state: "state",
    supplier: "supplier",
    supplier_contract: "supplier_contract",
    supplier_contract_charge_type: "supplier_contract_charge_type",
    supplier_contract_rate: "supplier_contract_rate",
    supplier_contract_rate_type: "supplier_contract_rate_type",
    supplier_contract_status: "supplier_contract_status",
    system_status: "system_status",
    take_or_pay_term: "take_or_pay_term",
    tank_status: "tank_status",
    tank_supplier: "tank_supplier",
    tank_type: "tank_type",
    territory: "territory",
    timezone: "timezone",
    unit_of_measure: "unit_of_measure",
  }