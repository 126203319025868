import {  useContext, useEffect } from 'react';
import Page from '../../templates/Page';
import { routeConfig } from '../../../configs';
import PageSection from '../../templates/PageSection';
import { useNavigateTo } from '../../../helpers';
import { TankDeliveryEventContext } from '../../../contexts';
import { TankDeliveryCalibrationForm } from '../../forms/TankDeliveryCalibrationForm';

export default function EditTankDeliveryCalibrations(props) {
  const {
    activeEntity,
    setActiveEntity,
  } = useContext(TankDeliveryEventContext);

  const [params, navigateTo] = useNavigateTo();
  const tank_delivery_event_uuid = params.tank_delivery_event_uuid;

  useEffect(() => {
    if (!activeEntity) {
      setActiveEntity(tank_delivery_event_uuid);
    }
  }, [setActiveEntity, activeEntity, tank_delivery_event_uuid]);

  return (
    <Page
      route={routeConfig.tank_fill_edit_calibration}
      gap={1}
      onBack={() => navigateTo(routeConfig.tank_fill_event_view)}
    >
      <PageSection>
        <TankDeliveryCalibrationForm form_data={activeEntity}/>
      </PageSection>
    </Page>
  );
}