/* eslint-disable react-hooks/exhaustive-deps */
import createEntityContext from '../helpers/createEntityContext';

const {
    Context: SupplierContext,
    Provider: SupplierContextProvider,
} = createEntityContext({
    entityType: "supplier",
    defaultEntity: {
        currency_code: "USD",
        erp_supplier_id: "",
        language_used: "ENGLISH",
        supplier_name: "",
        supplier_uuid: "",
        third_party: false,
        unit_of_measure: "SCF"
    },
    queryOpts: {
    },
    queryValues: {
    },

})

export {
    SupplierContext,
    SupplierContextProvider,
}
export default SupplierContext;    