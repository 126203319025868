import { Link as RouterLink } from 'react-router-dom';
import { Button, Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import Page from '../templates/Page'

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

export default function Page404() {
  return (
    <RootStyle title="404 Page Not Found">
      <Container>
        <Button to="/" size="large" variant="contained" component={RouterLink}>
          Go to Home
        </Button>
      </Container>
    </RootStyle>
  )
}