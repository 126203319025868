/* eslint-disable react-hooks/exhaustive-deps */
import createEntityContext from '../helpers/createEntityContext';

const EntityContext = createEntityContext({
    entityType: "business_unit",
    defaultEntity: {
        business_unit_uuid: "",
        business_unit_name: "",
        regional_company_uuid: "",
    },
});

export const BusinessUnitContext = EntityContext.Context;
export const BusinessUnitContextProvider = EntityContext.Provider;
export const useBusinessUnitContext = EntityContext.useContext;