import Page from '../../templates/Page';
import { ParentCompanyForm } from '../../forms';
import { routeConfig } from '../../../configs';
import PageSection from '../../templates/PageSection';
import { useNavigateTo } from '../../../helpers';

export default function AddParentCompany(props) {
  const [, navigateTo] = useNavigateTo();

  return (
    <Page route={routeConfig.parent_add}
      onBack={() => navigateTo(routeConfig.site_admin)}
    >
      <PageSection title="Enter Parent Company Details">
        <ParentCompanyForm />
      </PageSection>
    </Page>
  );
}