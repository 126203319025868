import {
    Link,
    useMatch,
    useResolvedPath
} from "react-router-dom";
import { alpha, useTheme } from "@mui/material";

/**
 * Uses the current Route path to determine if the Link should be active.
 * This was originally copied from react-router-dom's Link component [here](https://reactrouter.com/docs/en/v6/examples/custom-link). 
 * @param param0 
 * @returns 
 */
export function MLink({ children, to, inputRef, ...props }) {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });

    const theme = useTheme();

    let linkStyle = {
        width: "100%",
        backgroundColor: match ? alpha(theme.palette.primary.main, 0.07) : "transparent"
    }

    return (
        <Link
            style={linkStyle}
            to={to}
            ref={inputRef}
            {...props}
        >
            {children}
        </Link>
    );
}