/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext, useState } from 'react';
import { useForm } from "react-hook-form";
import { routeConfig } from '../../../configs';
import { MForm, MFormTextField, MFormSelect } from '../../@material-extend';
import { LookupContext, useSiteContext } from '../../../contexts';
import { useNavigateTo } from '../../../helpers';
import { Backdrop, CircularProgress, Grid } from '@mui/material';

const defaultValues = {
  tank_capacity_gallons: null,
  tank_description: null,
  tank_quantity: null,
  tank_rental: false,
  tank_status: null,
}

export function SiteTankDetailsForm(props) {
  const { form_data } = props
  const lookup = useContext(LookupContext);
  const siteContext = useSiteContext();
  const [params, navigateTo] = useNavigateTo();
  const [showBackdrop, setShowBackdrop] = useState(false);

  const form = useForm({
    defaultValues: defaultValues,
  });

  function onSubmit(data) {

    setShowBackdrop(true);
    siteContext.upsertEntity(data)
      .then(newSiteSuccess => {
        navigateTo(routeConfig.site_view);
        setShowBackdrop(false);
      }).catch(() => {
        setShowBackdrop(false);
      });
  }

  useEffect(() => {
    if (params.business_unit_uuid && !props.form_data?.business_unit_uuid) {
      form.setValue("business_unit_uuid", params.business_unit_uuid);
    }
  }, [form, params.business_unit_uuid, props.form_data]);

  useEffect(() => {
    if (!!form_data) {
      form.reset(props.form_data)
    }
  }, [props.form_data])

  return (<MForm
    defaultValues={defaultValues}
    onSubmit={onSubmit}
    form={form}
  >
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={showBackdrop}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <MFormTextField
      autoFocus
      onControlEnter={form.handleSubmit(onSubmit)}
      control={form.control}
      minRows={2}
      name="tank_description"
      label="Tank Description"
      multiline
      fullWidth
    />
    <MFormSelect
      name="tank_type"
      label="Tank Type"
      onControlEnter={form.handleSubmit(onSubmit)}
      GridProps={{
        xs: 12,
        md: 6,
      }}
      includeAll="-- Unselect --"
      control={form.control}
      options={lookup.values.tank_type}
    />

    <MFormTextField
      name="tank_capacity_gallons"
      label="Tank Capacity (Gallons)"
      onControlEnter={form.handleSubmit(onSubmit)}
      type="number"
      control={form.control}
      GridProps={{
        xs: 12,
        md: 6,
      }}
    />

    <MFormTextField
      name="tank_quantity"
      label="Tank Quanitity"
      onControlEnter={form.handleSubmit(onSubmit)}
      control={form.control}
      type="number"
      GridProps={{
        xs: 12,
        md: 6,
      }}
    />
    <MFormSelect
      onControlEnter={form.handleSubmit(onSubmit)}
      control={form.control}
      name="tank_supplier"
      options={lookup.values.tank_supplier}
      includeAll="-- Unselect --"
      label="Tank Supplier"
      GridProps={{
        xs: 12,
        md: 6,
      }}
    />
    <Grid item xs={12} sx={{ p: 2 }} />
  </MForm >

  );
}