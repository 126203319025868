/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import ErrorContext from './ErrorContext';
import { PropTypes } from 'prop-types';
import { LookupContext } from './LookupContext';

const defaultValue = {
    isLoading: false,
    setIsLoading: (newIsLoading) => { },
    handleSuccess: (successMessge) => { },
    handleError: (errorMessage, error) => { },
    constants: {
        APP_BAR_HEIGHT: 0,
        APP_BAR_MOBILE: 64,
        APP_BAR_DESKTOP: 92,
        DRAWER_WIDTH: 280,
    },
    sidebarOpen: true,

    openSidebar: () => { },
    closeSidebar: () => { },
    setSidebarOpen: (open) => { },
    pageState: {
        densePadding: false,
        appBarPosition: "sticky",
        activePageSection: "",
        windowScrollPast: {
            "app-start": true,
        }
    },
    scrollTo: (anchor, offset) => { },
    setPageState: (newPageState) => { },
    windowDimensions: {
        isMobile: false,
        isDesktop: false,
        isXs: false,
        isSm: false,
        isMd: false,
        isLg: false,
        isXl: false,
        size: 0,
        isXlUp: false,
        isLgUp: false,
        isMdUp: false,
        isSmUp: false,
        isXsUp: false,
    },
}
/**
 * # Layout Context
 */
export const LayoutContext = React.createContext(defaultValue);

LayoutContextProvider.propTypes = {
    windowDimensions: PropTypes.object.isRequired,
}


export function LayoutContextProvider({ windowDimensions, ...props }) {


    /* -------------------------------------------------------------------------- */
    /*                         Layout Component Overrides                         */
    /* -------------------------------------------------------------------------- */
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [pageState, setPageState] = useState({
        dense: false,
        appBarPosition: "static",
        activePageSection: "",
        windowScrollPast: {
            "app-start": true,
        }
    });

    useEffect(() => {
        const activeSections = Object.keys(pageState.windowScrollPast).filter(key => pageState.windowScrollPast[key]);
        if (activeSections.length > 0) {
            setPageState({
                ...pageState,
                activePageSection: activeSections.at(-1),
            });
        }
    }, [pageState.windowScrollPast])



    /* -------------------------------------------------------------------------- */
    /*                               Feedback Utils                               */
    /* -------------------------------------------------------------------------- */
    const { pushError } = useContext(ErrorContext);
    const lookupContext = useContext(LookupContext);
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState(lookupContext);

    const handleSuccess = (message) => {
        setIsLoading(false);
        if (!!message) {
            enqueueSnackbar(message, { variant: "success" });
            // scrollTo();
        }
    }
    const handleError = (message, err) => {
        setIsLoading(false);
        pushError(err ? { message: `${message} : ${err}` } : { message: `${message}` });
        enqueueSnackbar(message, { variant: "error" });
        scrollTo();
    }

    /**
     * Push errors with a `message`
     * @param {*} newLayout 
     */
    const handleSetSidebarOpen = (isOpen = !sidebarOpen) => {
        setSidebarOpen(isOpen);
    }
    const closeSidebar = () => setSidebarOpen(false);
    const openSidebar = () => setSidebarOpen(true);

    /**
       * # Scroll page utility
       * @param elementId if empty, scrolls to top of page
       * @param offset 
       */
    const scrollTo = (elementId = "app-start", offset) => {
        const element = document.getElementById(elementId);
        if (element) {
            // these are relative to the viewport, i.e. the window
            var topOfElement = element.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({
                top: topOfElement + (offset || -80),
                behavior: "smooth"
            })
        } else {
            console.debug(`Unable to scroll to element.`);
        }
    }

    return (
        <LayoutContext.Provider
            value={{
                scrollTo,
                isLoading: isLoading || lookupContext.isLoading,
                setIsLoading,
                handleSuccess,
                handleError,
                windowDimensions,
                sidebarOpen: sidebarOpen,
                setSidebarOpen: handleSetSidebarOpen,
                openSidebar: openSidebar,
                closeSidebar: closeSidebar,
                pageState,
                setPageState,
                constants: {
                    ...defaultValue.constants,
                    APP_BAR_HEIGHT: windowDimensions.isMobile + 16
                        ? defaultValue.constants.APP_BAR_MOBILE
                        : pageState?.dense ? defaultValue.constants.APP_BAR_MOBILE
                            : defaultValue.constants.APP_BAR_DESKTOP,
                },
            }}
        >
            {props.children}
        </LayoutContext.Provider>
    );
}

export default LayoutContext;