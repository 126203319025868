/* eslint-disable react-hooks/exhaustive-deps */
import Page from '../../../templates/Page';
import React from 'react';
import CustomerAdminContext from '../../../../contexts/CustomerAdminContext';
import DeleteIcon from '@mui/icons-material/Delete';
import { routeConfig } from '../../../../configs';
import { useContext, useEffect, useState } from 'react';
import { MBreadcrumbs } from '../../../@material-extend';
import MoreMenu from '../../../MoreMenu';
import { useNavigateTo } from '../../../../helpers';
import SiteTankAccessPlanningSection from './SiteTankAccessPlanningSection ';
import SiteGeneralSection from './SiteGeneralSection';
import SiteContactsSection from './SiteContactsSection';
import SiteTankDetailSection from './SiteTankDetailSection';
import SitePlantDetailSection from './SitePlantDetailSection';
import SiteSupplierContractSection from './SiteSupplierContractSection';
import SiteCustomerContractSection from './SiteCustomerContractSection';
import SiteBillingInformationSection from './SiteBillingInformationSection';
import SiteRepresentativeSection from './SiteRepresentativeSection';
import { useSiteContext, LookupContext, LayoutContext } from '../../../../contexts';
import { useSiteView } from '../../../../helpers/useViews';
import Restricted from '../../../Restricted';
import { permissions } from '../../../../configs/permissionConstants';
import { confirmDialog } from '../../../ConfirmDialog';


export default function SitePage() {
  const customerAdminContext = useContext(CustomerAdminContext);
  const siteContext = useSiteContext();
  const lookup = useContext(LookupContext);
  const { windowDimensions, isLoading } = useContext(LayoutContext);
  const { entities } = customerAdminContext;
  const [params, navigateTo] = useNavigateTo();
  const siteView = useSiteView(params.site_uuid);
  const [routeTitle, setRouteTitle] = useState("Loading");


  useEffect(() => {
    if (!entities.active_site?.site_name) {
      setRouteTitle("Loading Site...");
    } else if (lookup.isLoading) {
      setRouteTitle("Loading App Data...")
    } else if (entities.active_site?.site_name) {
      setRouteTitle(entities.active_site?.site_name)
    }
  }, [entities]);


  const handleDelete = () => {
    siteContext.deleteEntity(entities.active_site.site_uuid).then(() => {
      navigateTo(routeConfig.business_view);
    });
  }

  useEffect(() => {
    if (!params.site_uuid) {
      return;
    }
    siteContext.setActiveEntity(params.site_uuid);
    customerAdminContext.setActiveEntity("site", params.site_uuid);
    siteView.refreshView();
  }, [params.site_uuid]);

  return (
    <Page
      route={routeConfig.site_view}
      title={routeTitle}
      showPageNav
      collapsible
      subtitle={<>
        <MBreadcrumbs
          items={[
            { label: "Parent Company", onClick: () => navigateTo(routeConfig.parent_view) },
            { label: "Regional Company", onClick: () => navigateTo(routeConfig.region_view) },
            { label: "Business Unit", onClick: () => navigateTo(routeConfig.business_view) },
            { label: "Site", disabled: true },
          ]}
        />
      </>
      }
      gap={windowDimensions.isMobile ? 1 : 3}
      subtitleEndActions={
        <Restricted to={[permissions.WRITE_SITE_HIERARCHY]}>
          <MoreMenu
            color='primary'
            options={[
              {
                label: 'Delete Site',
                icon: <DeleteIcon />,
                onClick:
                  (data) => {
                    confirmDialog("Do you want to delete the site?", () => handleDelete())
                  }
              },
            ]}
          />
        </Restricted>
      }
    >
      <Restricted to={[permissions.VIEW_SITE_GENERAL]}>
        <SiteGeneralSection siteView={siteView} isLoading={!!isLoading} />
      </Restricted>
      <Restricted to={[permissions.VIEW_SITE_CONTACTS]}>
        <SiteContactsSection customerAdminContext={customerAdminContext} isLoading={!!isLoading} />
      </Restricted>
      <Restricted to={[permissions.VIEW_SITE_TANK_DETAILS]}>
        <SiteTankDetailSection siteView={siteView} isLoading={!!isLoading} />
      </Restricted>
      <Restricted to={[permissions.VIEW_SITE_PLANT_DETAILS]}>
        <SitePlantDetailSection siteView={siteView} isLoading={!!isLoading} />
      </Restricted>
      <Restricted to={[permissions.VIEW_SITE_TANK_ACCESS]}>
        <SiteTankAccessPlanningSection siteView={siteView} isLoading={!!isLoading} />
      </Restricted>
      <Restricted to={[permissions.VIEW_SITE_SUPPLIER_CONTRACT]}>
        <SiteSupplierContractSection siteView={siteView} isLoading={!!isLoading} />
      </Restricted>
      <Restricted to={[permissions.VIEW_SITE_CUSTOMER_CONTRACT]}>
        <SiteCustomerContractSection siteView={siteView} isLoading={!!isLoading} />
      </Restricted>
      <Restricted to={[permissions.VIEW_SITE_BILLING_INFORMATION]}>
        <SiteBillingInformationSection siteView={siteView} isLoading={!!isLoading} />
      </Restricted>
      <Restricted to={[permissions.VIEW_SITE_REPRESENTATIVES]}>
        <SiteRepresentativeSection siteView={siteView} isLoading={!!isLoading} />
      </Restricted>
    </Page >
  );
}