/* eslint-disable react-hooks/exhaustive-deps */
import createEntityContext from '../helpers/createEntityContext';

const EntityContext = createEntityContext({
    entityType: "parent_company",
    defaultEntity: {
        parent_company_uuid: "",
        parent_company_name: "",
    },
});

export const ParentCompanyContext = EntityContext.Context;
export const ParentCompanyContextProvider = EntityContext.Provider;
export const useParentCompanyContext = EntityContext.useContext;