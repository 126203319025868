import { Grid } from '@mui/material';
import { useContext } from 'react';
import Page from '../templates/Page';
import PageSection from '../templates/PageSection';
import PageText from '../templates/PageText';
import CustomerAdminContext from '../../contexts/CustomerAdminContext';
import { routeConfig } from '../../configs';
import { MButton } from '../@material-extend';
import { LayoutContext } from '../../contexts';
import Restricted from '../Restricted';
import { permissions } from '../../configs/permissionConstants';

export default function CustomerAdmin() {
  const { windowDimensions } = useContext(LayoutContext);
  const { navigateTo } = useContext(CustomerAdminContext);

  const handleNewParentClick = () => {
    navigateTo(routeConfig.parent_add);
  }

  return (
    <Page hideTitle route={routeConfig.customeradmin}>

      <PageSection
        omitContainer
        GridProps={{ sx: { mt: 7 } }}
        sx={{ m: windowDimensions.isMobile ? 0 : 5, }}

      >
        <PageText
          title={"Site Manager"}
          body={"Use the search bar up top to find and manage a resource or create a new parent company to get started."}
          sx={{ mb: windowDimensions.isMobile ? 0 : 2 }}
        />


        <Grid item container direction="row" justifyContent={"center"}>
          <Restricted to={[permissions.WRITE_SITE_HIERARCHY]}>
            <MButton
              GridProps={{ xs: 12, md: 6, lg: 4, sx: { pt: 3 } }}
              variant="contained"
              // size="large"
              onClick={handleNewParentClick}
              children="New Parent Company"
            />
          </Restricted>
        </Grid>
      </PageSection>
    </Page>
  );
}