import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Logo from '../../Logo';
import { Box, Drawer } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { blue } from '@mui/material/colors';
import { MHidden } from '../../@material-extend';
import NavSection from './NavSection';
import Scrollbar from './ScrollBar';
import { routeConfig, RouteConfigType } from '../../../configs';
import { useContext } from 'react';
import { LayoutContext } from '../../../contexts';

// get sidebar routes
const sidebarRouteConfig = Object.values(routeConfig).filter(rc => rc.types?.includes(RouteConfigType.sidebar));

const RootStyle = styled('div')(({ theme, constants }) => ({
  backgroundColor: blue,
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: constants.DRAWER_WIDTH
  }
}));

SidebarLayout.propTypes = {
};

export default function SidebarLayout() {
  const { pathname } = useLocation();
  const { sidebarOpen, constants, closeSidebar } = useContext(LayoutContext)

  useEffect(() => {
    if (sidebarOpen) {
      closeSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
          <Logo />
        </Box>
      </Box>

      <NavSection navConfig={sidebarRouteConfig} />
      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );
  return (
    <RootStyle constants={constants}>
      <MHidden width="lgUp">
        <Drawer
          open={sidebarOpen}
          onClose={closeSidebar}
          PaperProps={{
            sx: { width: constants.DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: constants.DRAWER_WIDTH,
              bgcolor: blue
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );

}
