import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import PropTypes from 'prop-types';

CheckedIcon.propTypes = {
    checked: PropTypes.bool,
    onClick: PropTypes.func,
}
function CheckedIcon({ checked, ...rest }) {
    return checked ? <CheckBoxIcon {...rest} /> : <CheckBoxOutlineBlankIcon {...rest} />
}
export default CheckedIcon;