import Page from '../../../templates/Page';
import { useContext } from 'react';
import CustomerAdminContext from '../../../../contexts/CustomerAdminContext';
import { routeConfig } from '../../../../configs';
import PageSection from '../../../templates/PageSection';
import { SiteSupplierContractForm } from '../../../forms/SiteForm';

export default function UpsertSupplierContract() {
  const { entities, navigateTo } = useContext(CustomerAdminContext);
  const formData = entities.active_supplier_contract;

  const route = !formData
    ? routeConfig.supplier_contract_add
    : routeConfig.supplier_contract_edit;

  return (
    <Page route={route}
      onBack={() => navigateTo(routeConfig.site_view)}
    >
      <PageSection title="" denseY>
        <SiteSupplierContractForm form_data={formData} />
      </PageSection>
    </Page>
  );
}