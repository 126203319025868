import React, { useEffect, useState } from 'react';
import { MList, MListItemCustom } from '../@material-extend';
import { Paper, Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import MoreMenu from '../MoreMenu';
import { useLookupContext } from '../../contexts';
import { StringParser } from '../../helpers';
import Restricted from '../Restricted';
import { permissions } from '../../configs/permissionConstants';
import { confirmDialog } from '../ConfirmDialog';


SupplierContractRateList.propTypes = {
  items: PropTypes.array,
  handleDeleteRate: PropTypes.func,
  showEffective: PropTypes.bool,
}

function SupplierContractRateList(props) {
  const [supplierContractRates, setSupplierContractRates] = useState([])
  const lookup = useLookupContext();

  useEffect(() => {
    if (!lookup.values.minimum_type.length) {
      return;
    }
    if (props.items) {
      const supplierContractRates = props.items;

      // sort rates by type then effective date in descending order
      let sortedRates = supplierContractRates.sort((a, b) => {
        if (a.supplier_contract_rate_type === b.supplier_contract_rate_type) {
          return a.supplier_contract_rate_effective_date > b.supplier_contract_rate_effective_date ? -1 : 1
        } else {
          return a.supplier_contract_rate_type > b.supplier_contract_rate_type ? -1 : 1
        }
      })

      // mark the effective rate for each rate type
      let rate_type = ""
      let today = new Date().toISOString().slice(0, 10);
      let found_effective = false;
      for (const rate of sortedRates) {
        if (rate_type !== rate.supplier_contract_rate_type) {
          rate_type = rate.supplier_contract_rate_type;
          found_effective = false;
        }
        if (!found_effective && (rate.supplier_contract_rate_effective_date <= today)) {
          found_effective = true;
          rate.effective = true
        }
        else {
          rate.effective = false
        }
      }

      if (props.showEffective) {
        sortedRates = sortedRates.filter(x => x.effective === true);
      }

      const newSupplierContractRates =
        sortedRates.map(rate => {
          const displayRate = {
            ...rate,
            effective_date: rate?.supplier_contract_rate_effective_date || "-",
            supplier_contract_rate: StringParser.formatCurrency(rate?.supplier_contract_rate, 4) || "-",
            supplier_contract_rate_gallons: StringParser.formatCurrency(rate?.supplier_contract_rate_gallons, 4) || "-",
            rate_type: lookup.byValue("supplier_contract_rate_type", rate?.supplier_contract_rate_type)?.label || "-",
            charge_type: lookup.byValue("supplier_contract_charge_type", rate?.supplier_contract_charge_type)?.label || "-",
            uom: lookup.byValue("unit_of_measure", rate?.unit_of_measure)?.label || "-",
          }

          const contactRateGallons = displayRate.charge_type === "Per Unit"
            ? `Contract Rate (Gallons): ${displayRate.supplier_contract_rate_gallons}`
            : undefined;

          const uom = displayRate.charge_type === "Per Unit"
            ? `Unit of Measure: ${displayRate.uom}`
            : undefined;

          return {
            id: displayRate.id,
            sx: { p: 5 },
            left: {
              title: displayRate.supplier_contract_rate_description,
              subtitles: [
                (`Rate Type: ${displayRate.rate_type}`),
                (`Contract Rate: ${displayRate.supplier_contract_rate}`),
                contactRateGallons,
                (`Charge Type: ${displayRate.charge_type}`),
                uom,
                (`Effective Date: ${displayRate.effective_date}`),
              ]
            },
            right: {
              children: <Grid container justifyContent="flex-end" alignItems={"center"}>
                <Restricted to={[permissions.DELETE_SUPPLIER_CONTACT_RATES]}>
                  <MoreMenu
                    data={{ uuid: displayRate.supplier_contract_rate_uuid }}
                    GridProps={{ item: true, xs: 2 }}
                    options={[
                      {
                        label: 'Delete Rate',
                        icon: <DeleteIcon sx={{ color: "error.main" }} />,
                        onClick: (data) => {
                          confirmDialog("You should NOT BE DELETING this data in the case of a new contract, rate increase, nor any other amendment.  NITROvision maintains a historical record of data.  The “effective date” is used to recognized the active contract data.  Do you want to delete the contract rate?", () => props.handleDeleteRate(data))
                        }
                      },
                    ]}
                  />
                </Restricted>
              </Grid>
            }
          }
        });

      if (newSupplierContractRates !== supplierContractRates) {
        setSupplierContractRates(newSupplierContractRates.length === 0 ? [{
          id: "add",
          sx: { p: 2 },
          left: { title: "  No supplier contract rates", },
        }] : newSupplierContractRates);
      }
    }
  }, [props, props.items, props.handleDeleteRate, lookup]);

  return (

    <MList
      dense
      items={supplierContractRates.length > 0 ? supplierContractRates : [{
        disableButton: true,
        sx: { p: 2 },
        left: { title: "  No supplier_contract rates", },
      }]}
      component={item => <MListItemCustom
        component={Paper}
        elevation={5}
        GridProps={{ sx: { mb: 2, p: 2 } }}
        {...item}
        sx={{
          py: 1,
          px: 2,
          ...item.sx,
          bgcolor: "theme.background.paper",
          color: "theme.text.primary",
        }}
      />
      }
    />
  );
}

export default SupplierContractRateList;