import PropTypes from 'prop-types';
import { useLayoutEffect, useState } from "react";
import { createPortal } from "react-dom";

const MPortal = ({ children, portalId }) => {

  const [portal, setPortal] = useState(null)

  useLayoutEffect(() => {
    const mount = document.getElementById(portalId);
    if (mount) {
      setPortal(createPortal(children, mount))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portalId]);
  return portal;
};
MPortal.propTypes = {
  portalId: PropTypes.string,
}
export default MPortal;