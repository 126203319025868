/* eslint-disable react-hooks/exhaustive-deps */
import { ListItemButton } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import React from "react";
import { MDisplay } from "./MDisplay";
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const columnProps = {
    title: PropTypes.string,
    direction: PropTypes.oneOf(["row", "column", "row-reverse", "column-reverse"]),
    subtitles: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.node,
    GridProps: PropTypes.object,
}

function _Component(props) {

    const { onClick, sx, GridProps, transferList, ...muiProps } = MDisplay.mui(props)
    const handleClick = () => {
        if (props.onClick) {
            props.onClick(props);
        }
    }

    const content = <Grid container direction="row" >
        {props.left && <LeftColumn
            {...props.left}
            xs={props.left && props.right ? 8 : 12}
            md={props.left && props.right ? 8 : 12}
            lg={props.left && props.right ? 8 : 12}
        />}
        {props.right && <RightColumn
            {...props?.right}
            xs={props.left && props.right ? 4 : 12}
            md={props.left && props.right ? 4 : 12}
            lg={props.left && props.right ? 4 : 12}
        />}
    </Grid>

    return (<ListItem
        key={props.id}
        {...muiProps}
        sx={GridProps?.sx}
        disablePadding
    >
        {props.onClick ? <ListItemButton
            sx={{ borderRadius: 8, ...props.sx }}
            disabled={props.disabled}
            disableRipple
            disableTouchRipple
            onClick={props.onClick && handleClick}
            selected={props.isSelected}
        >
            {content}
        </ListItemButton>
            : content}
    </ListItem>
    );
}


const LeftColumn = (props) => {
    // console.log("Render list item: " + props.title)
    return <Grid item xs={props.xs} md={props.md} lg={props.lg} sm={props.sm} xl={props.xl}
        zeroMinWidth
        container
        direction={props.direction || "column"}
        wrap="nowrap"
        {...props?.GridProps}>
        {props.title && <Grid item key={`left-title-${props.title}`}>
            <Typography variant="body1" children={props.title} noWrap />
        </Grid>}
        {props.subtitles?.map((subtitle, index) => {
            return (
                <Grid item key={`left-${index}`}>
                    <Typography variant="caption" key={index} children={subtitle} noWrap />
                </Grid>
            )
        })}
        {props.children}
    </Grid>
}
LeftColumn.propTypes = columnProps;

const RightColumn = (props) => {
    return <Grid item xs={props.xs} md={props.md} lg={props.lg} sm={props.sm} xl={props.xl}
        container
        direction={props.direction || "column"}
        wrap="nowrap"
        justifyContent="center" sx={{ pr: 1 }} {...props?.GridProps}>
        {props.title && <Typography variant="body1" children={props.title} />}
        {props.subtitles?.map((subtitle, index) => {
            return (
                <Typography variant="caption" key={index} children={subtitle} />
            )
        })}
        {props.children}
    </Grid>
}
RightColumn.propTypes = columnProps;

export const MListItemCustom = React.memo(_Component);

MListItemCustom.propTypes = {
    onClick: PropTypes.func,
    transferList: PropTypes.bool,
    right: PropTypes.shape(columnProps),
    left: PropTypes.shape(columnProps),
}

export default MListItemCustom;