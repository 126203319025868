/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLookupContext, useSiteContext } from "../../contexts";
import { defaultSupplier } from "../../services";

/**
 * # Site View
 * Reads 
 * @param {string} site_uuid
 * @returns {{
 *    refreshView: () => void,
 *    business_unit_name: "",
 *    business_unit_uuid: "",
 *    parent_company_name: "",
 *    parent_company_uuid: "",
 *    regional_company_name: "",
 *    regional_company_uuid: "",
 *    access_instructions:  string;
 *    application:  string;
 *    business_unit_uuid: string;
 *    channel:  string;
 *    country: {
 *      continent_code: string;
 *      country_code: string;
 *      country_name: string;
 *    },
 *    country_code:  string;
 *    currency_code:  string;
 *    delivery_restricted:  boolean;
 *    erp_site_id:  string;
 *    language_used:  string;
 *    msa_country: {
 *      continent_code: string;
 *      country_code: string;
 *      country_name: string;
 *    },
 *    msa_country_code: string;
 *    nat_box_count:  string;
 *    nitro_deliver:  boolean;
 *    number_of_lanes:  string;
 *    piping_type:  string;
 *    po_number_type: string;
 *    plant_type:  string;
 *    region: { nitrocrete_region: string; nitrocrete_region_description: string; };
 *    site_address1: string;
 *    site_address2:  string;
 *    site_city:  string;
 *    site_currency: {currency: string; currency_code: string;};
 *    site_description:  string;
 *    site_name:  string;
 *    site_msa_city: string;
 *    site_postal_code:  string;
 *    site_status:  string;
 *    site_uuid:  string;
 *    site_year:  string;
 *    state_code:  string;
 *    supplier_contract_number:  string;
 *    supplier_contract_status:  string;
 *    system_status:  string;
 *    tank_capacity_gallons:  string;
 *    tank_description:  string;
 *    tank_quantity:  string;
 *    tank_rental:  boolean;
 *    tank_status:  string;
 *    tank_supplier:  string;
 *    tank_type:  string;
 *    territory:  string;
 *    territory_description: {nitrocrete_region: string; territory_description: string; territory: string; };
 *    timezone_name:  string;
 *    unit_of_measure:  string; 
 *    tank_supplier_description: string;
 *    plant_type_description: string;
 *    application_description: string;
 *    state: {
 *        country_code: string;
 *        state_code: string;
 *        state_name: string;
 *    },
 *    msa_state: {
 *        country_code: string;
 *        state_code: string;
 *        state_name: string;
 *    },
 *    msa_state_code: string;
 *    supplier_contract_status_description: string;
 *    piping_type_description: string;
 * }}
 */
const useSiteView = (site_uuid) => {
    const [view, setView] = useState({
        access_instructions: "",
        application: "",
        business_unit_name: "",
        business_unit_uuid: "",
        channel: "",
        country_code: "",
        country: {
            continent_code: "",
            country_code: "",
            country_name: "",
        },
        continent: {
            continent_code: "",
            continent_name: "",
        },
        currency_code: "",
        delivery_restricted: "",
        erp_site_id: "",
        language_used: "",
        msa_country: {
            continent_code: "",
            country_code: "",
            country_name: "",
        },
        msa_country_code: "",
        msa_state: {
          country_code: "",
          state_code: "",
          state_name: "",
          subdivision_type: "",
        },
        msa_state_code: "",
        nat_box_count: "",
        nitro_deliver: "",
        number_of_lanes: "",
        parent_company_name: "",
        parent_company_uuid: "",
        piping_type: "",
        po_number_type: "",
        plant_type: "",
        regional_company_name: "",
        regional_company_uuid: "",
        site_address1: "",
        site_address2: "",
        site_city: "",
        site_description: "",
        site_msa_city: "",
        site_name: "",
        site_postal_code: "",
        site_status: "",
        site_uuid: "",
        site_year: "",
        state: {
            country_code: "",
            state_code: "",
            state_name: "",
            subdivision_type: "",
        },
        site_currency: {
            currency: "US Dollar",
            currency_code: "USD"
        },
        state_code: "",
        system_status: "",
        tank_capacity_gallons: "",
        tank_description: "",
        tank_quantity: "",
        tank_rental: "",
        tank_status: "",
        tank_supplier: "",
        tank_type: "",
        territory: "",
        territory_description: {
            nitrocrete_region: "",
            territory: "",
            territory_description: "",
        },
        timezone_name: "",
        unit_of_measure: "",
        site_status_description: "",
        tank_type_description: "",
        system_status_description: "",
        tank_status_description: "",
        channel_description: "",
        tank_supplier_description: "",
        plant_type_description: "",
        application_description: "",
        piping_type_description: "",
        po_number_type_description: "",
    });

    const { values, isLoading } = useLookupContext();
    const siteContext = useSiteContext();

    const refreshView = () => {
        if (isLoading) {
            return;
        }
        const site = siteContext.activeEntity;
        if (!site) {
            return;
        }
        // TODO: Need to use effective supplier contract supplier
        const default_supplier = values.supplier.find(supplier => supplier.supplier_uuid === site.site_uuid) || defaultSupplier;
        const business_unit = values.business_unit.find(business => business.business_unit_uuid === site.business_unit_uuid) || {};
        const regional_company = values.regional_company.find(company => company.regional_company_uuid === business_unit.regional_company_uuid) || {};
        const parent_company = values.parent_company.find(company => company.parent_company_uuid === regional_company.parent_company_uuid) || {};
        const channel = values.channel.find(channel => channel.value === site.channel) || {};
        const site_status = values.site_status.find(status => status.value === site.site_status) || {};
        const system_status = values.system_status.find(status => status.value === site.system_status) || {};
        const tank_status = values.tank_status.find(status => status.value === site.tank_status) || {};
        const tank_type = values.tank_type.find(type => type.value === site.tank_type) || {};
        const tank_unit_of_measure = values.unit_of_measure.find(unit => unit.value === site.tank_unit_of_measure) || {};
        const tank_supplier = values.tank_supplier.find(supplier => supplier.value === site.tank_supplier) || {};
        const plant_type = values.plant_type.find(type => type.value === site.plant_type) || {};
        const application = values.application.find(app => app.value === site.application) || {};
        const piping_type = values.piping_type.find(type => type.value === site.piping_type) || {};
        const po_number_type = values.po_number_type.find(type => type.value === site.po_number_type) || {};
        const language = values.language.find(lang => lang.value === site.language_used) || {
            language_description: "",
            language_used: "",
        };
        const msa_state = values.state.find(state => state.state_code === site.msa_state_code && state.country_code === site.msa_country_code) || {
          country_code: "",
          state_code: "",
          state_name: "",
          subdivision_type: "",
        };
        const msa_country = !!msa_state.country_code ? values.country.find(country => country.country_code === site.msa_country_code) : {
          continent_code: "",
          country_code: "",
          country_name: "",
        };
        // TODO: Need to use effective supplier contract supplier
        const supplier = values.supplier.find(supplier => supplier.supplier_uuid === site.site_uuid) || {};
        const supplier_currency = values.currency.find(currency => currency.currency_code === supplier.currency_code) || {};
        const site_currency = values.currency.find(currency => currency.currency_code === site.currency_code) || {
            currency: "US Dollar",
            currency_code: "USD"
        };
        const supplier_language = values.language.find(language => language.language_used === supplier.language_used) || {};
        const state = values.state.find(state => state.state_code === site.state_code && state.country_code === site.country_code) || {
            country_code: "",
            state_code: "",
            state_name: "",
            subdivision_type: "",
        };
        const country = !!state.country_code ? values.country.find(country => country.country_code === site.country_code) : {
            continent_code: "",
            country_code: "",
            country_name: "",
        };
        const continent = !!country.continent_code ? values.continent.find(continent => continent.continent_code === country.continent_code) : {
            continent_code: "",
            continent_name: "",
        };
        const territory_description = values.territory.find(territory => territory.value === site.territory) || {
            nitrocrete_region: "",
            territory: "",
            territory_description: "",
        };
        const region = territory_description.nitrocrete_region && (
            values.nitrocrete_region.find(region => region.nitrocrete_region === territory_description.nitrocrete_region)
            || {
                nitrocrete_region: "",
                nitrocrete_region_description: "",
            }
        );

        const supplier_contract_status = values.supplier_contract_status.find(status => status.value === site.supplier_contract_status) || {};

        const newView = {
            ...site,
            ...business_unit,
            ...regional_company,
            ...parent_company,
            country: country,
            language: language,
            continent: continent,
            supplier_contract_status_description: supplier_contract_status.label,
            territory_description: territory_description,
            region: region,
            supplier_description: supplier.label,
            supplier: { ...supplier, currency: supplier_currency.currency, language_description: supplier_language.language_description },
            state: state,
            site_currency: site_currency,
            tank_type_description: tank_type.label,
            site_status_description: site_status.label,
            system_status_description: system_status.label,
            tank_status_description: tank_status.label,
            channel_description: channel.label,
            default_supplier: default_supplier,
            tank_unit_of_measure_description: tank_unit_of_measure.label || tank_unit_of_measure.value || site.tank_unit_of_measure, // TODO - fix tank_unit_of_measure
            tank_supplier_description: tank_supplier.label,
            plant_type_description: plant_type.label,
            application_description: application.label,
            piping_type_description: piping_type.label,
            po_number_type_description: po_number_type.label,
            msa_country: msa_country,
            msa_state: msa_state,
        };
        if (newView !== view) {
            setView(newView);
        }
    };

    useEffect(refreshView, [isLoading, siteContext.activeEntity]);

    return { ...view, refreshView };

}

export default useSiteView;