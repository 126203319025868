import { fetchWrapper } from '../helpers/fetchWrapper';

export const defaultSite = {
  access_instructions: "",
  application: "",
  business_unit_uuid: "",
  channel: "",
  country_code: "",
  currency_code: "",
  delivery_restricted: false,
  erp_site_id: "",
  language_used: "",
  msa_country_code: "",
  msa_state_code: "",
  nat_box_count: "",
  nitro_deliver: false,
  number_of_lanes: "",
  piping_type: "",
  plant_type: "",
  po_number_type: "",
  site_address1: "",
  site_address2: "",
  site_city: "",
  site_description: "",
  site_msa_city: "",
  site_name: "",
  site_postal_code: "",
  site_status: "",
  site_uuid: "",
  site_year: "",
  state_code: "",
  system_status: "",
  tank_capacity_gallons: "",
  tank_description: "",
  tank_quantity: "",
  tank_rental: false,
  tank_status: "",
  tank_supplier: "",
  tank_type: "",
  territory: "",
  timezone_name: "",
  unit_of_measure: "",
}

export const siteService = {
  getAll,
  getById,
  create,
  update,
  delete: _delete
};

const baseUrl = `${process.env.REACT_APP_SERVER_URL}/api/site`;

function getAll(token) {
  return fetchWrapper.get(token, baseUrl);
}

function getById(token, id) {
  return fetchWrapper.get(token, `${baseUrl}/${id}`);
}

function create(token, params) {
  return fetchWrapper.post(token, baseUrl, params);
}

function update(token, id, params) {
  return fetchWrapper.put(token, `${baseUrl}/${id}`, params);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(token, id) {

  return fetchWrapper.delete(token, `${baseUrl}/${id}`);
}