import * as React from 'react';
import PropTypes from 'prop-types';
import { useNavigateTo } from '../../../helpers/useNavigateTo';
import { Navigate, Route, Routes } from 'react-router-dom';

StepperProvider.propTypes = {
    steps: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        children: PropTypes.node,
    })),
    state: PropTypes.object,
    handleRedirect: PropTypes.func,
};

export const StepperContext = React.createContext({
    steps: [{ label: "", children: "" }],
    activeStep: 0,
    handleRedirect: (state, path) => { },
    skipped: new Set(),
    isStepSkipped: (stepNumber) => false,
    handleNext: (newState) => { },
    handleStep: (stepIndex) => { },
    handleBack: (newState) => { },
    handleSkip: () => { },
    handleReset: () => { },
    params: { tab_path: "" },
    navigateTo: (pathRoute, params) => { },
    setCurrentStep: (index) => { },
    state: {},
    setState: (newState) => { },
});


/**
 * @template {{label: string, children: React.ReactNode, path?: string}} Step
 * @template StepperState
 * @param {{
 *   steps: Step[];
 *   state: StepperState;
 *   handleRedirect: (newState: StepperState, tabPath) => string;
 * } } props 
 * @returns {{
 *   handleSkip: () => void;
 *   handleReset: () => void;
 *   handleBack: (newState) => void;
 *   handleRedirect: (newState, tabPath) => string;
 *   handleNext: (newState) => void;
 *   handleStep: (stepIndex) => void;
 *   params: { tab_path: "" },
 *   navigateTo: (pathRoute, params) => void;
 *   setCurrentStep: (index: number) => void;
 *   activeStep: number;
 *   isStepSkipped: (stepNumber: number) => boolean;
 *   state: StepperState;
 *   setState: (newState: StepperState) => void;
 * }}
 */
export function StepperProvider(props) {

    const {
        steps,
        state: initialState,
        handleRedirect = () => undefined,
    } = props;
    const [state, setState] = React.useState(initialState || {});

    const [skipped, setSkipped] = React.useState(new Set());

    const [params, navigateTo, tabs] = useNavigateTo(props.steps)

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleStep = (stepIndex) => {
        if (steps[stepIndex]?.path) {
            navigateTo(steps[stepIndex].path)
        }
    };

    const handleNext = (newState) => {
        let newSkipped = skipped;
        if (isStepSkipped(tabs.index)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(tabs.index);
        }
        if (newState) {
            setState(newState);
        }
        const newStepIndex = tabs.index + 1;
        if (steps[newStepIndex]?.path) {
            navigateTo(steps[newStepIndex].path)
        }
        setSkipped(newSkipped);
    };

    const handleBack = (newState) => {
        const newStepIndex = tabs.index - 1;
        if (newState) {
            setState(newState);
        }
        if (steps[newStepIndex].path) {
            navigateTo(steps[newStepIndex].path)
        }
    };

    const handleSkip = () => {
        if (steps[tabs.index].optional) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(tabs.index);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setState(props.state || {});
    };

    return <StepperContext.Provider value={{
        handleSkip,
        handleReset,
        handleBack,
        handleStep,
        steps,
        handleNext,
        params,
        navigateTo,
        activeStep: tabs.index,
        isStepSkipped,
        setCurrentStep: tabs.setIndex,
        state: state,
        handleRedirect,
        setState,
    }}>
        <Routes>
            <Route element={props.children} >
                {steps.map((step, index) => {
                    return <Route key={index} path={step.path} element={step.children} />
                })}
                <Route path="/" element={<Navigate to={steps[0].path} replace />} />
            </Route>
        </Routes>
    </StepperContext.Provider>
}

export default StepperContext;