import { useEffect, useContext, useState } from 'react';
import { useForm } from "react-hook-form";
import CustomerAdminContext from '../../contexts/CustomerAdminContext';
import { entityConfig } from '../../configs';
import { MForm, MFormTextField, MDivider, MFormSelect } from '../@material-extend';
import PropTypes from 'prop-types';
import { useLookupContext } from '../../contexts';
import { useAuth0 } from '@auth0/auth0-react';
import { permissions } from '../../configs/permissionConstants';
import { usePermission } from "../../contexts/PermissionContext";


const defaultValues = {
  site_contact_email: "",
  site_contact_first_name: "",
  site_contact_last_name: "",
  site_contact_mobile_phone: "",
  site_contact_office_phone: "",
  site_contact_title: "",
  site_contact_uuid: "",
  site_uuid: "",
}

SiteContactForm.propTypes = {
  form_data: PropTypes.object,
}

export function SiteContactForm(props) {
  const { params, createEntity, updateEntity } = useContext(CustomerAdminContext);
  const lookup = useLookupContext();
  const form = useForm({
    defaultValues: defaultValues,
  });
  const { getAccessTokenSilently } = useAuth0();
  const [isAllowedTo] = usePermission([permissions.WRITE_SUPPLIER_CONTACTS]);
  const [contactTypes, setContactTypes] = useState([]);

  async function onSubmit(data) {
    const token = await getAccessTokenSilently();
    if (!props.form_data) {
      const { site_contact_uuid, ...createPayload } = data
      return createEntity(token, entityConfig.type.site_contact, createPayload)
    } else {
      return updateEntity(token, entityConfig.type.site_contact, props.form_data?.site_contact_uuid, data);
    }
  }

  useEffect(() => {
    if (params.site_uuid && !props.form_data?.site_uuid) {
      form.setValue("site_uuid", params.site_uuid);
    }
  }, [form, params.site_uuid, props.form_data])

  useEffect(() => {
    if (!lookup.values.site_contact_type.length) {
      return;
    }
    let contact_types = lookup.values.site_contact_type;
    if (! isAllowedTo) {
      contact_types = lookup.values.site_contact_type.filter(item => item.site_contact_type !== "LN_DELIVERY");
    }
    setContactTypes(contact_types)
  }, [lookup.values.site_contact_type,  isAllowedTo ])

  useEffect(() => {
    if (props.form_data) {
      form.reset(props.form_data)
    }
  }, [props.form_data, form])

  return (<MForm
    form={form}
    defaultValues={defaultValues}
    onSubmit={onSubmit}
  >
    <MDivider
      above={{ title: "Contact Name" }}
      direction="row"
      variant="form"
    />
    <MFormTextField
      GridProps={{
        xs: 12,
        md: 6,
      }}
      onControlEnter={form.handleSubmit(onSubmit)}
      control={form.control}
      name="site_contact_first_name"
      label="First Name"
      required
    />
    <MFormTextField
      GridProps={{
        xs: 12,
        md: 6,
      }}
      onControlEnter={form.handleSubmit(onSubmit)}
      control={form.control}
      name="site_contact_last_name"
      label="Last Name"
      required
    />
    <MFormTextField
      control={form.control}
      GridProps={{
        xs: 6,
        md: 3,
      }}
      onControlEnter={form.handleSubmit(onSubmit)}
      name="site_contact_title"
      label="Title"
      fullWidth
    />
    <MFormSelect
      autoFocus
      onControlEnter={form.handleSubmit(onSubmit)}
      control={form.control}
      GridProps={{
        xs: 6,
      }}
      name="site_contact_type"
      includeAll="-- Unselect --"
      options={contactTypes}
      label="Contact Type"
      required
    />
    <MFormTextField
      GridProps={{
        xs: 12,
      }}
      onControlEnter={form.handleSubmit(onSubmit)}
      control={form.control}
      name="site_contact_note"
      label="Note"
      fullWidth
    />

    {/* ----------------------------- Contact Fields ----------------------------- */}
    <MDivider
      above={{ title: "Communication Methods" }}
      direction="row"
      variant="form"
    />
    <MFormTextField
      GridProps={{
        xs: 12,
      }}
      onControlEnter={form.handleSubmit(onSubmit)}
      control={form.control}
      name="site_contact_email"
      label="Email Address"
      fullWidth
    />
    <MFormTextField
      GridProps={{
        xs: 12,
      }}
      onControlEnter={form.handleSubmit(onSubmit)}
      control={form.control}
      normalize="phone"
      name="site_contact_mobile_phone"
      label="Mobile Phone"
      fullWidth
    />
    <MFormTextField
      control={form.control}
      GridProps={{
        xs: 12,
      }}
      normalize="phone"
      onControlEnter={form.handleSubmit(onSubmit)}
      name="site_contact_office_phone"
      label="Office Phone"
      fullWidth
    />



  </MForm>

  );
}