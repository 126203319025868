/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import Page from '../../templates/Page';
import PageSection from '../../templates/PageSection';
import PageText from '../../templates/PageText';
import { RegionalCompanyList, BusinessUnitList, BusinessSiteList } from '../../lists';
import CustomerAdminContext from '../../../contexts/CustomerAdminContext';
import DeleteIcon from '@mui/icons-material/Delete';
import { routeConfig } from '../../../configs';
import { Grid } from '@mui/material';
import { MButton, MIconButton, MBreadcrumbs } from '../../@material-extend';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useParentCompanyContext } from '../../../contexts';
import MoreMenu from '../../MoreMenu';
import { useNavigateTo } from '../../../helpers';
import Restricted from '../../Restricted';
import { permissions } from '../../../configs/permissionConstants';
import { confirmDialog } from '../../ConfirmDialog';

export default function ParentCompany() {
  const { entities, getChildUuids } = useContext(CustomerAdminContext);
  const [params, navigateTo] = useNavigateTo();
  const parentCompanyContext = useParentCompanyContext();
  const [routeTitle, setRouteTitle] = useState("Loading");
  const [childUuids, setChildUuids] = useState([]);

  const handleAddRegionalCompany = () => {
    navigateTo(routeConfig.region_add);
  }

  useEffect(() => {
    parentCompanyContext.setActiveEntity(params.parent_company_uuid);
  }, [params.parent_company_uuid]);

  useEffect(() => {
    if (!!parentCompanyContext.activeEntity) {
      setRouteTitle(parentCompanyContext.activeEntity?.parent_company_name);
      const childIds = getChildUuids(parentCompanyContext.activeEntity);
      setChildUuids(childIds);
    } else {
      setRouteTitle("Loading...");
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [parentCompanyContext.activeEntity, entities]);

  const handleDeleteParentCompany = () => {
    parentCompanyContext.deleteEntity(parentCompanyContext.activeEntity.parent_company_uuid).then(() => {
      navigateTo(routeConfig.site_admin);
    });
  }

  return (
    <Page
      route={routeConfig.parent_view}
      gap={2}
      collapsible
      showPageNav
      title={routeTitle}
      subtitle={<MBreadcrumbs
        items={[
          { label: "Parent Company", onClick: () => navigateTo(routeConfig.parent_view), disabled: true },
        ]}
      />}
      subtitleEndActions={
        <Restricted to={[permissions.WRITE_SITE_HIERARCHY]}>
          <MoreMenu
            color="primary"
            options={[
              {
                label: 'Delete Parent Company',
                icon: <DeleteIcon />,
                onClick: () => confirmDialog("Do you want to delete the parent company?", () => handleDeleteParentCompany())
              },
            ]}
          />
        </Restricted>}
    >
      {/* ----------------------------- Details Section ---------------------------- */}
      <PageSection
        title="Details"
        collapsible
        endActions={
          <Restricted to={[permissions.WRITE_SITE_HIERARCHY]}>
            <MIconButton
              icon="edit"
              size="small"
              color="primary"
              onClick={() => navigateTo(routeConfig.parent_edit)}
            />
          </Restricted>
        }
      >
        <PageText
          label="Parent Company Name"
          body={parentCompanyContext.activeEntity?.parent_company_name}
        />
      </PageSection>

      {/* ----------------------------- Hierarchy Section ---------------------------- */}
      <PageSection
        title="Hierarchy"
        collapsible
        direction="row"
        justifyContent="flex-start"
        action={<Restricted to={[permissions.WRITE_SITE_HIERARCHY]}>
          <MButton
            startIcon={<AddCircleIcon />}
            variant="contained"
            size="small"
            onClick={handleAddRegionalCompany}
            children="Add Regional Company"
          />
        </Restricted>}
      >
        <Grid item xs={12} md={4} xl={3} sx={{ px: .5 }}>
          <RegionalCompanyList
            filterItems={(i) => childUuids.some(uuid => uuid === i.id)}
          />
        </Grid>
        <Grid item xs={12} md={4} xl={3} sx={{ px: .5 }}>
          <BusinessUnitList
            filterItems={(i) => childUuids.some(uuid => uuid === i.id)}
          />
        </Grid>
        <Grid item xs={12} md={4} xl={3} sx={{ px: .5 }}>
          <BusinessSiteList
            filterItems={(i) => childUuids.some(uuid => uuid === i.id)}
          />
        </Grid>
      </PageSection>


    </Page>
  );
}