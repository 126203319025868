import { useController } from 'react-hook-form'
import React from 'react';
import PropTypes from 'prop-types';
import MSelect from './MSelect';

MFormSelect.propTypes = {
    GridProps: PropTypes.object,
    control: PropTypes.object.isRequired,
    sx: PropTypes.object,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    multiline: PropTypes.bool,
    minRows: PropTypes.number,
    required: PropTypes.bool,
    fullWidth: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({ lable: PropTypes.string, value: PropTypes.string })),
    autoFocus: PropTypes.bool,
    onControlEnter: PropTypes.func,
    FormHelperTextProps: PropTypes.object,
    includeAll: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}

/**
 * 
 * @param {{
 *  control: {},
 *  required?: boolean;
 *  options: {label: string, value: string}[];
 *  includeAll?: boolean | string;
 *  GridProps?: {xs: number, sm: number, md: number, lg: number, xl: number};
 *  sx?: {};
 *  autoFocus?: boolean;
 *  defaultValue: string,
 *  name: string,
 *  label: string,
 *  onOptionSelect?: (selectedOption?) => void,
 * }} props 
 * @returns 
 */
function MFormSelect({ control, defaultValue, name, onOptionSelect, ...rest }) {

    const { field: { value, onChange, } } = useController({
        control,
        name,
        defaultValue,
    })


    function handleChange(event, item) {
        onChange(event);

        if (onOptionSelect) {
            onOptionSelect(item);
        }

    }


    return <MSelect
        {...rest}
        margin="dense"
        value={rest.options?.length ? value : ""}
        onChange={handleChange}
    />;


};




export default MFormSelect