import React from 'react';
import { routeConfig } from '../../../../configs';
import PageSection from '../../../templates/PageSection';
import PageText from '../../../templates/PageText';
import { MButton, MDivider } from '../../../@material-extend';
import { useNavigateTo } from '../../../../helpers';
import { Grid } from '@mui/material';
import Restricted from '../../../Restricted';
import { permissions } from '../../../../configs/permissionConstants';

/**
 * @param {{
 *    siteView: {};
 * }} props
 * @returns 
 */
export default function SiteGeneralSection({ siteView, isLoading }) {
  const [, navigateTo] = useNavigateTo();


  return (<PageSection
    title="General"
    spacing={2}
    collapsible
    omitContainer
    endActions={
      <Restricted to={[permissions.WRITE_SITE_GENERAL, permissions.WRITE_SITE_TANK_NITROFILL, permissions.WRITE_SITE_ADDRESS, permissions.WRITE_SITE_STATUS, permissions.WRITE_SITE_HIERARCHY]}>
        <MButton
          GridProps
          collapseXs
          size="small"
          icon="edit"
          variant="outlined"
          disabled={isLoading}
          tooltip={{ title: "Edit Site General info" }}
          onClick={() => navigateTo(routeConfig.site_edit)}
          children="Edit General"
        />
      </Restricted>
    }
  >
    {/* ------------------------------ Details ------------------------------  */}
    <MDivider
      variant='list-first'
      direction='row'
      above={{
        title: "About",
      }} />

    <PageText
      label="Name"
      GridProps={{ xs: 6, md: 3 }}
      body={siteView?.site_name || "-"}
    />
    <PageText
      label="Site ID"
      GridProps={{ xs: 6, md: 3 }}
      body={siteView?.site_id || "-"}
    />
    <PageText
      GridProps={{ xs: 6, md: 6, }}
      label="Year Added"
      body={siteView?.site_year || "-"}
    />

    <PageText
      label="Channel"
      GridProps={{ xs: 6, md: 3, }}
      body={siteView?.channel_description || "-"}
    />
    <PageText
      label="Is Tank Nitrofill"
      GridProps={{ xs: 6, md: 3 }}
      body={siteView?.nitro_deliver ? "Yes" : "No"}
    />

    <PageText
      label="Description"
      GridProps={{ xs: 6, md: 6 }}
      body={siteView?.site_description || "-"}
    />
    <MDivider
      direction='row'
      variant='list-middle'
      above={{
        title: "Status",
      }} />
    <PageText
      label="Site Status"
      GridProps={{ xs: 6, md: 3, }}
      body={siteView?.site_status_description || "-"}
    />
    <PageText
      label="System Status"
      GridProps={{ xs: 6, md: 3, }}
      body={siteView?.system_status_description || "-"}
    />
    <PageText
      label="Tank Status"
      GridProps={{ xs: 6, md: 3, }}
      body={siteView?.tank_status_description || "-"}
    />

    <MDivider
      direction='row'
      variant='list-middle'
      above={{
        title: "Site Units",
      }} />
    <PageText
      label="Unit of Measure"
      GridProps={{ xs: 6, md: 3, }}
      body={siteView?.unit_of_measure || "-"}
    />
    <PageText
      label="Currency"
      GridProps={{ xs: 6, md: 3, }}
      body={siteView?.site_currency?.currency || "-"}
    />
    <PageText
      GridProps={{ xs: 6, md: 3, }}
      label="Language"
      body={siteView?.language?.language_description || "-"}
    />

    {/* ------------------------------- Site Location ------------------------------- */}
    <MDivider
      direction='row'
      variant='list-middle'
      above={{
        title: "Location",
      }} />
    <PageText
      GridProps={{ xs: 6, md: 3, lg: 3 }}
      label="City"
      body={siteView?.site_city || "-"}
    />
    <PageText
      GridProps={{ xs: 6, md: 3, lg: 3 }}
      label="State"
      body={siteView?.state?.state_name || "-"}
    />
    <PageText
      GridProps={{ xs: 6, md: 3, lg: 3 }}
      label="Country"
      body={siteView?.country?.country_name || "-"}
    />
    <PageText
      GridProps={{ xs: 6, md: 3, lg: 3 }}
      label="Postal Code"
      body={siteView?.site_postal_code || "-"}
    />
    <PageText
      GridProps={{ xs: 6, md: 3, lg: 3 }}
      label="Continent"
      body={siteView?.continent?.continent_name || "-"}
    />
    <PageText
      GridProps={{ xs: 6, md: 3, lg: 3 }}
      label="Timezone"
      body={siteView?.timezone_name || "-"}
    />

    <PageText
      GridProps={{ xs: 6, md: 3, lg: 3 }}
      label="Region"
      body={siteView?.region?.nitrocrete_region_description || "-"}
    />
    <PageText
      GridProps={{ xs: 6, md: 3, lg: 3 }}
      label="Territory"
      body={siteView?.territory_description?.territory_description || "-"}
    />
    <PageText
      GridProps={{ xs: 6, md: 3, lg: 3 }}
      label="MSA City"
      body={siteView?.site_msa_city || "-"}
    />
    <PageText
      GridProps={{ xs: 6, md: 3, lg: 3 }}
      label="MSA State"
      body={siteView?.msa_state.state_name || "-"}
    />
    <PageText
      GridProps={{ xs: 6, md: 3, lg: 3 }}
      label="MSA Country"
      body={siteView?.msa_country.country_name || "-"}
    />

    <Grid container item spacing={1}>

      <PageText
        label="Address 1"
        body={siteView?.site_address1 || "-"}
      />
      <PageText
        label="Address 2"
        body={siteView?.site_address2 || "-"}
      />
    </Grid>
    <PageText
      GridProps={{ xs: 6, md: 3, lg: 3 }}
      label="Site Latitude"
      body={siteView?.site_latitude || "-"}
    />
    <PageText
      GridProps={{ xs: 6, md: 3, lg: 3 }}
      label="Site Longitude"
      body={siteView?.site_longitude || "-"}
    />

  </PageSection>

  );
}