import React from 'react';
import { Container } from '@mui/material';
import { Outlet } from 'react-router-dom';
import NavCascader from './NavCascader';
import { MPortal } from '../../@material-extend';
import { routeConfig } from '../../../configs';

export default function CustomerAdminLayout(props) {
    return (
        <>
            <MPortal portalId={routeConfig.portal_navbar_center.id}>
                <NavCascader />
            </MPortal>
            <Container maxWidth="xl">
                {/* Route Children */}
                <Outlet />
            </Container>
        </>
    );
}
