import React from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import MenuList from '@mui/material/MenuList';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreVertIcon from '@mui/icons-material/MoreVert';

MoreMenu.propTypes = {
    onClick: PropTypes.func,
    GridProps: PropTypes.object,
    data: PropTypes.object,
    vertical: PropTypes.bool,
    tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    placement: PropTypes.oneOf(['bottom-end', 'bottom-start', 'bottom', 'left-end', 'left-start', 'left', 'right-end', 'right-start', 'right', 'top-end', 'top-start', 'top']),
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        data: PropTypes.object,
        icon: PropTypes.element,
        onClick: PropTypes.func,
    })),
}

/**
 * 
 * @param {{
 *  onClick: () => void,
 *  GridProps: {},
 *  data: {},
 *  vertical: boolean,
 *  sx: {},
 *  iconSx: {},
 *  component: React.ComponentType,
 *  tooltip: string | {title: string, placement: string},
 *  placement: "bottom-start" | "bottom-end" | "bottom" | "top-start" | "top-end" | "top" | "right-start" | "right-end" | "right-center" | "left-start" | "left-end" | "left-center",
 *  options: {
 *      label: string,
 *      data: object,
 *      icon: React.ReactNode,
 *      onClick: () => void,
 * }[],
 * }} props 
 * @returns 
 */
export default function MoreMenu({ onClick, options, sx, iconSx, component, tooltip, data, placement, GridProps = {}, vertical, ...componentProps }) {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        event.stopPropagation();
        event.preventDefault();

        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    function createOptionOnClick(item) {
        return (event) => {
            handleClose(event);
            if (item.onClick) {
                item.onClick(item.data || data || event);
            }
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);

    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const RenderComponent = component || IconButton;

    let result = <RenderComponent
        children={vertical ? <MoreVertIcon sx={iconSx} /> : <MoreHorizIcon sx={iconSx} />}
        {...componentProps}
        ref={anchorRef}
        sx={sx}
        id="composition-button"
        fontSize="small"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        size={componentProps.size || "small"}
    />;

    if (tooltip) {

        let tooltipProps = typeof tooltip === 'string' ?
            {
                placement: "top",
                title: tooltip
            } :
            {
                placement: "top",
                ...tooltip,
            };

        if (tooltipProps.tooltip) {
            delete tooltipProps.tooltip;
        }

        result = <Tooltip {...tooltipProps} children={result} />
    }


    return (
        <Grid item {...GridProps}>

            {result}
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement={placement}
                transition
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'right-end' ? 'left bottom' : 'left top',
                        }}
                    >
                        <Paper elevation={6}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    onKeyDown={handleListKeyDown}
                                >
                                    {options.map((option, index) => option.hidden
                                        ? <></>
                                        : (
                                            <MenuItem key={index}
                                                disabled={option.disabled}
                                                onClick={createOptionOnClick(option)}
                                            >
                                                {option.icon && <ListItemIcon children={option.icon} sx={{ mr: 0 }} />}
                                                {option.label && <ListItemText children={option.label} secondary={option.subtitle} />}
                                            </MenuItem>
                                        ))}

                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Grid>
    );
}
