import Page from '../../templates/Page';
import { BusinessUnitForm } from '../../forms';
import { useContext } from 'react';
import CustomerAdminContext from '../../../contexts/CustomerAdminContext';
import { routeConfig } from '../../../configs';
import PageSection from '../../templates/PageSection';

export default function EditBusinessUnit(props) {
  const { entities, navigateTo } = useContext(CustomerAdminContext);
  const formData = entities.active_business;
  return (
    <Page route={routeConfig.business_edit}
      onBack={() => navigateTo(routeConfig.business_view)}
    >
      <PageSection title="Update Business Details" >
        <BusinessUnitForm form_data={formData} />
      </PageSection>
    </Page>
  );
}