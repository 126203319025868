import React from 'react';
import { Grid, Typography, Divider } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import PropTypes from 'prop-types';

MCard.propTypes = {
  sx: PropTypes.object,
  height: PropTypes.number,
  variant: PropTypes.oneOf(['outlined', 'elevation']),
  title: PropTypes.string,
  titleVariant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'subtitle1', 'subtitle2', 'body1', 'body2', 'caption', 'button', 'overline']),
  titleSx: PropTypes.object,
  subtitle: PropTypes.string,
  subtitleVariant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'subtitle1', 'subtitle2', 'body1', 'body2', 'caption', 'button', 'overline']),
  children: PropTypes.node,
  cardActions: PropTypes.node,
  GridProps: PropTypes.object,
  direction: PropTypes.oneOf(["row", "column"]),
  justifyContent: PropTypes.oneOf(["flex-start", "flex-end", "center", "space-between", "space-around", "space-evenly"]),
  alignItems: PropTypes.oneOf(["flex-start", "flex-end", "center", "baseline", "stretch"]),
  fullHeight: PropTypes.bool,
}

/**
 * # MCard
 * Styled card component for consistency
 * @param {*} props 
 * @returns 
 */
export default function MCard({
  GridProps,
  title,
  titleVariant = "h6",
  height = 240,
  titleSx,
  cardActions,
  subtitle,
  subtitleVariant = "body2",
  subtitleSx,
  fullHeight = true,
  direction,
  justifyContent,
  alignItems,
  ...props }) {

  const content = (<Card
    {...props}
    sx={{
      height: height,
      position: "relative",
    }}
  >
    <Grid container sx={{ py: 1, px: 2 }} direction="column">
      <Typography
        sx={titleSx}
        variant={titleVariant}
        children={title}
      />
      <Typography
        variant={subtitleVariant}
        sx={{ color: "text.secondary", ...subtitleSx }}
        children={subtitle}
      />
    </Grid>
    <Divider />
    <Grid
      container
      item
      className="noScrollBar"
      direction={direction}
      justifyContent={justifyContent}
      alignItems={alignItems}
      sx={{ height: fullHeight ? height - 68 : "auto", maxHeight: height - 68, overflow: "auto", ...props.sx }}
    >

      {props.children}
    </Grid>

    <CardActions sx={{ position: "absolute", bottom: 0 }} children={cardActions}>
    </CardActions>
  </Card >);

  if (GridProps) {
    return <Grid item
      {...GridProps}
      sx={{
        // pb: 5,
        ...GridProps.sx
      }}
      children={content} />
  }

  return content;
}
