import { useEffect, useContext } from 'react';
import { useForm } from "react-hook-form";
import { routeConfig } from '../../configs';
import { SupplierContext, LookupContext } from '../../contexts';
import { useNavigateTo } from '../../helpers';
import { MForm, MFormSelect, MFormTextField } from '../@material-extend';

const defaultValues = {
  currency_code: "",
  erp_supplier_id: "",
  language_used: "",
  supplier_name: "",
  third_party: "",
  unit_of_measure: ""
}

export function SupplierForm(props) {
  const { params, upsertEntity } = useContext(SupplierContext);
  const [, navigateTo] = useNavigateTo();
  const lookup = useContext(LookupContext);

  const form = useForm({
    defaultValues: defaultValues,
  });

  function onSubmit(data) {
    return upsertEntity(data).then(() => {
      navigateTo(routeConfig.supplier);
    })
  }

  useEffect(() => {
    if (params?.supplier_uuid && !props.form_data?.supplier_uuid) {
      form.setValue("supplier_uuid", params.supplier_uuid);
    }
  }, [form, params?.supplier_uuid, props.form_data])

  useEffect(() => {
    if (props.form_data) {
      form.reset(props.form_data)
    }
  }, [props.form_data, form])

  return (
    <MForm
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      form={form}
    >
      <MFormTextField
        autoFocus
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        name="supplier_name"
        label="Supplier Name"
        fullWidth
        required
      />

      <MFormTextField
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        name="erp_supplier_id"
        label="Enter/Edit Supplier ERP ID"
        fullWidth
      />

      <MFormSelect
        onControlEnter={form.handleSubmit(onSubmit)}
        GridProps={{
          xs: 12,
          md: 6,
        }}
        control={form.control}
        name="third_party"
        includeAll="-- Unselect --"
        label="Is Supplier a 3rd Party?"
        options={[{ value: true, label: 'Yes' }, { value: false, label: 'No' }]}
      />
      <MFormSelect
        onControlEnter={form.handleSubmit(onSubmit)}
        GridProps={{
          xs: 12,
          md: 6,
        }}
        control={form.control}
        options={lookup.values.unit_of_measure}
        includeAll="-- Unselect --"
        name="unit_of_measure"
        label="Select Supplier UOM"
      />

      <MFormSelect
        onControlEnter={form.handleSubmit(onSubmit)}
        GridProps={{
          xs: 12,
          md: 6,
        }}
        control={form.control}
        options={lookup.values.currency}
        includeAll="-- Unselect --"
        name="currency_code"
        label="Select Supplier Currency"
      />
      <MFormSelect
        onControlEnter={form.handleSubmit(onSubmit)}
        GridProps={{
          xs: 12,
          md: 6,
        }}
        control={form.control}
        options={lookup.values.language}
        includeAll="-- Unselect --"
        name="language_used"
        label="Select Supplier Language"
      />
    </MForm>
  );
}