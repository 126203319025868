/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from 'react';
import { useForm } from "react-hook-form";
import { MFormTextField, MFormSelect, MFormDatePicker, MForm } from '../../@material-extend';
import { LayoutContext, LookupContext, usePricingMinimumContext } from '../../../contexts';
import { dateHelper, useNavigateTo } from '../../../helpers';
import MDividerSection from '../../@material-extend/MDividerSection';
import { routeConfig } from '../../../configs';

export function PricingMinimumForm(props) {
  const lookup = useContext(LookupContext);
  const pricingMinimumContext = usePricingMinimumContext();
  const layout = useContext(LayoutContext);
  const [params, navigateTo] = useNavigateTo();

  const form = useForm({
    defaultValues: pricingMinimumContext.defaultValues,
  });

  const handleValidateForm = (data) => {
    let errorMessage = null;
    if (!data.pricing_minimum_description) {
      data.pricing_minimum_description = null;
      errorMessage = "Provide a pricing minimum description.";
    }
    else if (!data.minimum_type) {
      data.minimum_type = null;
      errorMessage = "Choose a minimum type.";
    }
    else if (!data.minimum_quantity) {
      data.minimum_quantity = null;
      errorMessage = "Provide a minimum quantity.";
    }
    else if (!data.annual_minimum_date) {
      data.annual_minimum_date = null;
      errorMessage = "Select an annual minimum date.";
    }
    else if (!data.pricing_minimum_effective_date) {
      data.pricing_minimum_effective_date = null;
      errorMessage = "Select a pricing minimum effective date.";
    }

    if (errorMessage) {
      layout.handleError(errorMessage);
      return false;
    }
    return true;
  }

  const onSubmit = (data) => {

    const isValidForm = handleValidateForm(data);
    if (!isValidForm) {
      return;
    }

    data.customer_contract_uuid = params.customer_contract_uuid;
    data.annual_minimum_date = dateHelper.formatDate(data.annual_minimum_date);
    data.pricing_minimum_effective_date = dateHelper.formatDate(data.pricing_minimum_effective_date);

    pricingMinimumContext.upsertEntity(data)
      .then(res => {
        console.log("Saved pricing minimum", res);
        navigateTo(routeConfig.site_view);
      }).catch((e) => {
        console.log("Error saved pricing minimum", e);
      });

  }

  return (<>
    <MForm
      form={form}
      onSubmit={onSubmit}
    >
      <MDividerSection
        position="form"
        above={{ title: "Minimum" }}
      >
        <MFormTextField
          onControlEnter={form.handleSubmit(onSubmit)}
          control={form.control}
          minRows={2}
          name="pricing_minimum_description"
          label="Pricing Minimum Description"
          multiline
          fullWidth
          required
        />
        <MFormSelect
          name="minimum_type"
          label="Minimum Type"
          onControlEnter={form.handleSubmit(onSubmit)}
          includeAll="-- Unselect --"
          control={form.control}
          options={lookup.values.minimum_type}
          GridProps={{
            xs: 12,
            md: 6,
          }}
        />
        <MFormTextField
          name="minimum_quantity"
          label="Minimum Quantity"
          type="number"
          onControlEnter={form.handleSubmit(onSubmit)}
          control={form.control}
          GridProps={{
            xs: 12,
            md: 6,
          }}
        />
        <MFormDatePicker
          name="annual_minimum_date"
          label="Annual Minimum Date"
          clearable
          required
          onControlEnter={form.handleSubmit(onSubmit)}
          control={form.control}
          GridProps={{
            xs: 12,
            md: 6,
          }}
        />
        <MFormDatePicker
          name="pricing_minimum_effective_date"
          label="Pricing Minimum Effective Date"
          clearable
          required
          onControlEnter={form.handleSubmit(onSubmit)}
          control={form.control}
          GridProps={{
            xs: 12,
            md: 6,
          }}
        />
      </MDividerSection>
    </MForm>
  </>
  );
}