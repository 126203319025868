import React from 'react';
import PageSection from '../../../templates/PageSection';
import {
  MButton,
} from '../../../@material-extend';
import { useNavigateTo } from '../../../../helpers';
import PageText from '../../../templates/PageText';
import { routeConfig } from '../../../../configs';
import Restricted from '../../../Restricted';
import { permissions } from '../../../../configs/permissionConstants';

/**
 * @param {{
 *    isLoading: boolean;
 *    siteView: {};
 * }} props
 * @returns 
 */
export default function SiteTankDetailSection({ isLoading, siteView }) {
  const [, navigateTo] = useNavigateTo();

  const handleEditSection = () => {
    navigateTo(routeConfig.site_edit_tank_details);
  }

  return (<PageSection
    title="Tank Details"
    omitContainer
    endActions={
      <Restricted to={[permissions.WRITE_TANK_DETAILS]}>
        <MButton
          GridProps
          collapseXs
          size="small"
          icon="edit"
          variant="outlined"
          color="primary"
          disabled={isLoading}
          tooltip={{ title: "Tank Details" }}
          onClick={handleEditSection}
          children="Edit Tank Details"
        />
      </Restricted>
    }
    spacing={2}
    collapsible
  >
    <PageText
      label="Tank Description"
      GridProps={{ xs: 12, md: 12, lg: 12 }}
      body={siteView?.tank_description || "-"}
    />
    <PageText
      label="Tank Capacity"
      GridProps={{ xs: 6, lg: 6 }}
      body={siteView?.tank_capacity_gallons ? `${siteView?.tank_capacity_gallons} Gal.` : "-"}
    />
    <PageText
      label="Tank Quantity"
      GridProps={{ xs: 6, lg: 6 }}
      body={siteView?.tank_quantity || "-"}
    />
    <PageText
      label="Tank Supplier"
      GridProps={{ xs: 6, lg: 6}}
      body={siteView?.tank_supplier_description || "-"}
    />
    <PageText
      label="Tank Type"
      GridProps={{ xs: 6, lg: 6 }}
      body={siteView?.tank_type_description || "-"}
    />
  </PageSection>)
}
