/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { Grid, Paper, Typography, Collapse, useScrollTrigger } from '@mui/material';
import PropTypes from 'prop-types';
import { MIconButton } from '../@material-extend';
import { LayoutContext } from '../../contexts';
import { PageContext } from '../../contexts/PageContext';

PageSection.propTypes = {
    gap: PropTypes.number,
    spacing: PropTypes.number,
    GridProps: PropTypes.object,
    direction: PropTypes.string,
    collapsible: PropTypes.bool,
    title: PropTypes.string,
    label: PropTypes.string,
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    sx: PropTypes.object,
    headerSx: PropTypes.object,
    onBack: PropTypes.func,
    onBackTooltip: PropTypes.string,
    children: PropTypes.node,
    omitContainer: PropTypes.bool,
    startActions: PropTypes.node,
    endActions: PropTypes.node,
    action: PropTypes.node,
    justifyContent: PropTypes.oneOf(["flex-start", "flex-end", "center", "space-between", "space-around", "space-evenly"]),
    alignItems: PropTypes.oneOf(["flex-start", "flex-end", "center", "stretch", "baseline"]),
    denseY: PropTypes.bool,
}

/**
 * 
 * @param {{
 *      GridProps: {},
 *      gap: number,
 *      spacing: number,
 *      direction: string,
 *      collapsible: boolean,
 *      title: string,
 *      label: string,
 *      subtitle: string | React.ReactNode,
 *      sx: {},
 *      headerSx: {},
 *      onBack: () => void,
 *      onBackTooltip: string,
 *      omitContainer: boolean,
 *      startActions: React.ReactNode,
 *      endActions: React.ReactNode,
 *      action: React.ReactNode,
 *      justifyContent: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly",
 *      alignItems: "flex-start" | "flex-end" | "center" | "stretch" | "baseline",
 *      denseY: boolean,
 *      onScrollIntoView: (isInView: boolean) => void,
 * }} props 
 * @returns 
 */
export function PageSection(props) {
    const { windowDimensions, scrollTo, setPageState, pageState, isLoading } = useContext(LayoutContext);
    const pageContext = useContext(PageContext);
    const expanded = windowDimensions.isLgUp || pageContext.sections[props.title]?.expanded || pageContext.sections[props.title]?.expanded === undefined;
    const showCollapsible = (props.collapsible && props.children && windowDimensions.isMobile);
    const [scrollThresholdY, setScrollThresholdY] = useState();
    // const scrollOffset = 0;
    const scrollOffset = -120;

    useLayoutEffect(() => {
        if (isLoading) {
            return;
        }
        const element = document.getElementById(props.title);
        if (element) {
            var topOfElement = element.getBoundingClientRect().top + scrollOffset;
            setScrollThresholdY(topOfElement);
        } else {
            console.log(`unable to locate`, props.title)
        }
    }, [isLoading]);

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: scrollThresholdY,
    });

    // This adds pagesection to LayoutContext 
    useEffect(() => {
        if (trigger !== pageState.windowScrollPast[props.title]) {
            setPageState(prevState => ({
                ...prevState,
                windowScrollPast: {
                    ...prevState.windowScrollPast,
                    [props.title]: trigger
                },
            }));
        }
    }, [trigger])

    // Adds page section to PageContext 
    useLayoutEffect(() => {
        pageContext.mountSection({ id: props.title, title: props.title, subtitle: props.subtitle, expanded: true });
        return () => pageContext.unmountSection(props.title);
    }, []);

    const handleExpand = useCallback(() => {
        pageContext.handleExpandSection(props.title);
        if (!expanded) {
            setTimeout(() => {
                scrollTo(props.title);
            }, 150);
        }
    }, [pageContext, props.title]
    );


    return (
        <Grid
            container
            direction="column"
            component={Paper}
            variant="outlined"
            {...props.GridProps}
            item
            sx={{
                pt: windowDimensions.isMobile ? .5 : 1,
                pb: windowDimensions.isMobile ? .5 : 2,
                // mb: windowDimensions.isMobile ? 0 : 1,
                // px: windowDimensions.isMobile ? 0 : .5,
                ...props.GridProps?.sx,
            }}
        >
            {/* -------------------- Section Header -------------------- */}
            {(showCollapsible || props.startActions || props.endActions || props.title || props.subtitle || props.onBack) && <Grid container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                onClick={showCollapsible ? handleExpand : undefined}
                sx={{
                    cursor: showCollapsible ? "pointer" : "default",
                    px: 2,
                    py: 1,
                    // py: 1.5,
                    ...props.headerSx
                }}
            >

                {/* -------------------- Title -------------------- */}
                <Grid
                    xs={(showCollapsible || props.endActions) ? 6 : 12}
                    item
                    container
                    direction="column"
                >
                    {props.label && typeof props.label === "string" ? <Typography
                        align="left"
                        children={props.label}
                        variant={"subtitle1"}

                    /> : props.label}
                    <Grid
                        item
                        container
                        alignItems="center"
                    >

                        {(props.startActions || props.onBack) && <Grid item children={<>
                            {props.onBack ?

                                <MIconButton
                                    icon="back"
                                    size="small"
                                    tooltip={props.onBackTooltip}
                                    disableRipple
                                    onClick={props.onBack}
                                />
                                : <></>
                            }
                            {props?.startActions}
                        </>} />}
                        <Grid item>
                            <Typography
                                id={props.title}
                                align="left"
                                children={props.title}
                                variant={"h3"}

                            />
                        </Grid>
                    </Grid>
                    {props.subtitle && typeof props.subtitle === "string" ? <Typography
                        align="left"
                        children={props.subtitle}
                        variant={"subtitle1"}
                    /> : props.subtitle}
                </Grid>

                {/* -------------------- Collapse Action -------------------- */}
                {(showCollapsible || props.endActions) && <Grid
                    xs={6}
                    item
                    container
                    justifyContent="flex-end"
                    sx={{ height: 1, pr: 1 }}
                    gap={1}
                >
                    {props.endActions}
                    {showCollapsible && <MIconButton
                        rotateDegrees={!expanded ? 0 : 180}
                        onClick={evt => { evt.stopPropagation(); handleExpand() }}
                        aria-expanded={expanded}
                        sx={{ ml: props.endActions ? 0 : 1 }}
                        aria-label="expand section"
                        size="small"
                        tooltip={expanded ? "Collapse" : "Expand"}
                        GridProps={{ item: true }}
                        icon="expand"
                    />}
                </Grid>}
            </Grid>
            }
            {/* -------------------- Collapsible Content -------------------- */}
            {(!!props.children || !!props.action) && <Collapse in={expanded}>

                {/* -------------------- Section Actions -------------------- */}
                {props.action
                    && <Grid container
                        item
                        xs={12}
                        direction="row"
                        justifyContent={"flex-end"}
                        sx={{
                            pr: windowDimensions.isMobile ? 1.5 : 2,
                            pb: windowDimensions.isMobile ? .5 : 1.5,
                            pt: windowDimensions.isMobile ? .5 : 1.5,
                        }}
                        alignItems="center"
                        children={props.action}
                    />
                }

                {/* -------------------- Section Content -------------------- */}
                <Grid container sx={{
                    px: windowDimensions.isMobile ? 2 : 2.5,
                    py: windowDimensions.isMobile ? 1 : 1.5,
                }}>

                    {props.omitContainer || !props.children
                        ? <Grid container
                            direction={props.direction}
                            gap={props.gap}
                            spacing={props.spacing}
                            justifyContent={props.justifyContent}
                            alignItems={props.alignItems}
                            sx={props.sx}
                            children={props.children}
                        />
                        : <Grid
                            container
                            item
                            xs={12}
                            direction={props.direction || "column"}
                            component={Paper}
                            variant="outlined"
                            gap={props.gap}
                            spacing={props.spacing}
                            justifyContent={props.justifyContent}
                            alignItems={props.alignItems}
                            sx={{
                                px: windowDimensions.isMobile ? 1 : 2,
                                py: props.denseY ? 1 : 2,
                                bgcolor: "background.default",
                                width: 1,
                                ...props.sx
                            }}
                            children={props.children}
                        />}
                </Grid>

            </Collapse >}
        </Grid >
    )
}
export default React.memo(PageSection);