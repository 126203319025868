import { useForm } from "react-hook-form";
import { useEffect } from 'react';
import { routeConfig } from '../../configs';
import { MForm, MFormTextField } from '../@material-extend';
import { useParentCompanyContext } from "../../contexts";
import { useNavigateTo } from "../../helpers";

const defaultValues = {
  parent_company_name: "",
};

export function ParentCompanyForm(props) {
  const parentCompanyContext = useParentCompanyContext();
  const [, navigateTo] = useNavigateTo();

  const form = useForm({
    defaultValues: defaultValues
  });

  function onSubmit(data) {
    data.parent_company_name = data.parent_company_name.trim()
    parentCompanyContext.upsertEntity(data).then(res => {
      navigateTo(routeConfig.parent_view, { parent_company_uuid: res.parent_company_uuid });
    });
  }

  useEffect(() => {
    if (props.form_data) {
      form.reset(props.form_data)
    }
  }, [form, props.form_data])

  return (<MForm
    defaultValues={defaultValues}
    onSubmit={onSubmit}
    form={form}
    FormProps={{ style: { width: "100%" } }}
  >
    <MFormTextField
      control={form.control}
      autoFocus
      onControlEnter={form.handleSubmit(onSubmit)}
      name="parent_company_name"
      label="Parent Company Name"
      fullWidth
      required
    />

  </MForm>
  );
}