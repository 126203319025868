/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useContext } from 'react';
import { useForm } from "react-hook-form";
import { routeConfig } from '../../../configs';
import { MForm, MFormTextField, MFormCheckbox, MFormSelect } from '../../@material-extend';
import { LookupContext, useSiteContext } from '../../../contexts';
import { useNavigateTo } from '../../../helpers';
import { Backdrop, CircularProgress, Grid } from '@mui/material';
import Restricted from '../../Restricted';
import { permissions } from '../../../configs/permissionConstants';

const defaultValues = {
  po_number: null,
  po_number_dynamic: false,
  billing_notes: "",
  po_number_type: "Delivery"
}

export function SiteBillingInformationForm(props) {
  const { form_data } = props
  const lookup = useContext(LookupContext);
  const siteContext = useSiteContext();
  const [params, navigateTo] = useNavigateTo();
  const [showBackdrop, setShowBackdrop] = useState(false);

  const form = useForm({
    defaultValues: defaultValues,
  });

  function onSubmit(data) {

    setShowBackdrop(true);
    siteContext.upsertEntity(data)
      .then(newSiteSuccess => {
        console.log("Saved Site", newSiteSuccess);
        navigateTo(routeConfig.site_view);
        setShowBackdrop(false);
      }).catch(() => {
        setShowBackdrop(false);
      });
  }

  useEffect(() => {
    if (params.business_unit_uuid && !props.form_data?.business_unit_uuid) {
      form.setValue("business_unit_uuid", params.business_unit_uuid);
    }
  }, [form, params.business_unit_uuid, props.form_data]);

  useEffect(() => {
    if (!!form_data) {
      form.reset(props.form_data)
    }
  }, [props.form_data])

  return (<MForm
    defaultValues={defaultValues}
    onSubmit={onSubmit}
    form={form}
  >
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={showBackdrop}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <Restricted to={[permissions.WRITE_BILLING_INFORMATION]}>

      <MFormSelect
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        name="po_number_type"
        includeAll="-- Unselect --"
        options={lookup.values.po_number_type}
        label="PO Number Type"
        GridProps={{
          xs: 12,
          md: 6,
          sx: { pr: 10 }
        }}
      />

      <MFormCheckbox
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        name="po_number_dynamic"
        row
        label="Is PO Number dynamic?"
        GridProps={{
          xs: 12,
          md: 6,
          sx: { pr: 2 }
        }}
      />
      <MFormTextField
        name="po_number"
        label="PO Number"
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        GridProps={{
          xs: 12,
          md: 12,
        }}
      />
      <MFormTextField
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        name="billing_notes"
        label="Billing Notes"
        multiline
        minRows={2}
        GridProps={{ xs: 12, md: 12, sx: { pr: 2 } }}
      />
      <Grid item xs={12} sx={{ p: 2 }} />
    </Restricted>

  </MForm >

  );
}