import Page from '../../templates/Page';
import { SiteContactForm } from '../../forms';
import { useContext } from 'react';
import CustomerAdminContext from '../../../contexts/CustomerAdminContext';
import { routeConfig } from '../../../configs';
import PageSection from '../../templates/PageSection';

export default function UpsertSiteContact() {
  const { entities, navigateTo } = useContext(CustomerAdminContext);
  const formData = entities.active_site_contact;

  const route = !formData
    ? routeConfig.site_contact_add
    : routeConfig.site_contact_edit;

  return (
    <Page route={route}
      onBack={() => navigateTo(routeConfig.site_view)}
    >
      <PageSection title="" denseY>
        <SiteContactForm form_data={formData} />
      </PageSection>
    </Page>
  );
}