import React, { useState } from "react"
// material ui
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  TextField,
} from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
// zustand
import { create } from 'zustand'

const useConfirmDialogStore = create(set => ({
  message: "",
  onSubmit: undefined,
  close: () => set({ onSubmit: undefined }),
  getReason: false
}))

export const confirmDialog = (message, onSubmit, getReason) => {
  useConfirmDialogStore.setState({
    message,
    onSubmit,
    getReason
  })
}

const ConfirmDialog = () => {
  const { message, onSubmit, close, getReason } = useConfirmDialogStore()
  const [reasonInput, setReasonInput] = useState('');
  const handleReasonInputChange = event => {
      setReasonInput(event.target.value);
  };
  return (
    <Dialog open={Boolean(onSubmit)} onClose={close} maxWidth="sm" fullWidth>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <DialogTitle>Confirm the action</DialogTitle>
        <IconButton onClick={close}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent>
        {message}
        { getReason &&
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="name"
                  name="reason"
                  label="Reason"
                  type="text"
                  fullWidth
                  variant="standard"
                  onChange= {handleReasonInputChange}
                /> 
}
      </DialogContent>
      <DialogActions >
        <Button color="grey" variant="contained" onClick={close}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            if (onSubmit) {
              onSubmit(reasonInput)
            }
            setReasonInput('')
            close()
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
