import { useController } from 'react-hook-form'
import React from 'react';
import PropTypes from 'prop-types';
import MDateTimePicker from './MDateTimePicker';

MFormDateTimePicker.propTypes = {
    GridProps: PropTypes.object,
    control: PropTypes.object.isRequired,
    sx: PropTypes.object,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    multiline: PropTypes.bool,
    minRows: PropTypes.number,
    required: PropTypes.bool,
    fullWidth: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({ lable: PropTypes.string, value: PropTypes.string })),
    autoFocus: PropTypes.bool,
    onControlEnter: PropTypes.func,
    FormHelperTextProps: PropTypes.object,
    includeAll: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}

function MFormDateTimePicker({ control, defaultValue, name, GridProps = { xs: 12 }, required, ...rest }) {

    const { field: { value, onChange } } = useController({
        control,
        name,
        defaultValue,
        rules: {
            // required: required,
        }
    })

    return <MDateTimePicker
        {...rest}
        name={name}
        GridProps={GridProps}
        value={value}
        onChange={onChange}
    />;


};




export default MFormDateTimePicker