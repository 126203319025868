import Page from '../../templates/Page';
import { RegionalCompanyForm } from '../../forms';
import { useContext } from 'react';
import CustomerAdminContext from '../../../contexts/CustomerAdminContext';
import { routeConfig } from '../../../configs';
import PageSection from '../../templates/PageSection';

export default function EditRegionalCompany(props) {
  const { entities, navigateTo } = useContext(CustomerAdminContext);

  return (
    <Page route={routeConfig.region_edit}
      onBack={() => navigateTo(routeConfig.region_view)}
    >
      <PageSection title="Update regional company details">
        <RegionalCompanyForm form_data={entities.active_region} />
      </PageSection>
    </Page>
  );
}