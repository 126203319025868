import { Grid } from "@mui/material";
import React from "react";
import LinearProgress from '@mui/material/CircularProgress';

const LoadingSpinner = () => (
  <div className="spinner">
    <Grid container sx={{ minHeight: 550, height:"100%", width: "100%" }} justifyContent={"center"} alignItems={"center"}>
      <Grid item>
      <LinearProgress />
      </Grid>
    </Grid>
  </div>
);

export default LoadingSpinner;