import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Grid, TextField } from '@mui/material';
import * as React from 'react';


function MDateTimePicker(props) {
  const { GridProps, value, helperText, ...rest } = props;

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  const content = (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        value={value === "" ? null : value}
        renderInput={({ error, ...params }) => {
          return <TextField
            onKeyDown={onKeyDown}
            helperText={helperText}
            margin="dense"
            size={props.size}
            fullWidth={props.fullWidth || !!GridProps}
            sx={{
              bgcolor: "background.paper",
              borderRadius: 1,
            }}
            {...params}
          />
        }}
        {...rest}
        onError={(reason, value) => {
          if (!!reason)
            console.log(`Mui DateTimePicker - [Error:${reason}] - value:`, value);
        }}
      />
    </LocalizationProvider>
  );

  if (props.GridProps) {
    return <Grid
      item
      {...props.GridProps}
      children={content}
    />
  }
  return content
}

export default MDateTimePicker;