import React, { useContext } from 'react';
import { MList } from '../@material-extend';
import { routeConfig } from '../../configs';
import { MCard } from '../@material-extend';
import CustomerAdminContext from '../../contexts/CustomerAdminContext';
import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigateTo } from '../../helpers';
import { useAuth0 } from '@auth0/auth0-react';
import { confirmDialog } from '../ConfirmDialog';


BusinessUnitList.propTypes = {
  filterItems: PropTypes.func,
}

/**
 * All Business Units.
 * Business Unit Fields:
 * - created_at: "2022-01-31T22:22:10.716192"
 * - business_unit_name: "parent company one - UPDATED"
 * - business_unit_uuid: "ff9121c5-5a81-48bf-b7e6-b96de9737801"
 * - updated_at: "2022-01-31T22:22:43.359807"
 */
export function BusinessUnitList(props) {
  const context = useContext(CustomerAdminContext)
  const [, navigateTo] = useNavigateTo();
  const { getAccessTokenSilently } = useAuth0();

  const handleClickItem = (item) => {
    const params = context.getHierarchyParams(item);
    navigateTo(routeConfig.business_view, params)
  }
  const items = props.filterItems ? context.entities.business.filter(props.filterItems) : context.entities.business;

  const deleteContact = async (item) => {
    const token = await getAccessTokenSilently();
    context.deleteEntity(token, item);
  }

  return (
    <MCard
      title="Business Units"
    >
      <MList
        dense
        onClick={handleClickItem}
        items={items.map(item => ({
          ...item,
          data: item,
          id: item.business_unit_uuid,
          title: item.business_unit_name,
          selected: context.params.business_unit_uuid === item.business_unit_uuid,
          secondaryAction: context.dev_control.show_delete_icon_on_lists && <IconButton
            onClick={(item) => {
              confirmDialog("Do you want to delete the contact?", deleteContact(item));
            }}
          />,
        }))}
      />
    </MCard>);
}
