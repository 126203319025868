/* eslint-disable react-hooks/exhaustive-deps */
import createEntityContext from '../helpers/createEntityContext';

const EntityContext = createEntityContext({
    entityType: "supplier_contract_rate",
    defaultEntity: {
        supplier_contract_rate_uuid: "",
        supplier_contract_rate_effective_date: "",
        supplier_contract_rate: null,
        supplier_contract_uuid: "",
        supplier_contract_charge_type: "",
        supplier_contract_rate_type: "",
        unit_of_measure: "",
        supplier_contract_rate_description: "",
    },
});

export const SupplierContractRateContext = EntityContext.Context;
export const SupplierContractRateContextProvider = EntityContext.Provider;
export const useSupplierContractRateContext = EntityContext.useContext;