/* eslint-disable react-hooks/exhaustive-deps */
import { ButtonGroup } from '@mui/material';
import { useContext, useState } from 'react';
import Page from '../../templates/Page';
import PageSection from '../../templates/PageSection';
import { routeConfig } from '../../../configs';
import { MButton } from '../../@material-extend';
import { LayoutContext, RepresentativeContext } from '../../../contexts';
import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigateTo } from '../../../helpers';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckedIcon from '../../CheckedIcon';
import Restricted from '../../Restricted';
import { permissions } from '../../../configs/permissionConstants';

export default function RepresentativeTable() {
  const defaultColumns = [
    {
      field: "actions",
      disableColumnMenu: true,
      disableReorder: true,
      headerName: '',
      widthMultiplier: 3,

      renderCell: (params) => (<ButtonGroup
        color="inherit"
      >
        <MButton
          onClick={() => navigateTo(routeConfig.representative_view, { nitrocrete_representative: params.id })}
          size="small"
          aria-label="view"
          children="View"
          tooltip="View Nitrocrete Representative"
        />

      </ButtonGroup>

      ),
    },
    // {
    //   field: 'nitrocrete_representative',
    //   description: 'Representative ID',
    //   headerName: 'Representative ID',
    //   widthMultiplier: 4,
    // },
    {
      field: 'nitrocrete_representative_description',
      headerName: 'Name',
      widthMultiplier: 4,
    },
    {
      field: 'nitrocrete_representative_email',
      headerName: 'Email',
      widthMultiplier: 4,
    },
    {
      field: 'nitrocrete_representative_active',
      description: 'Active?',
      headerName: 'Active?',
      widthMultiplier: 1.5,
      renderCell: (params) => {
        return (
          <CheckedIcon checked={params.value} />
        )
      },
    },

  ];
  const [, navigateTo] = useNavigateTo();

  const {
    windowDimensions: { height },
    constants,
    isLoading,
  } = useContext(LayoutContext);

  const { entities, queryHandler } = useContext(RepresentativeContext);
  const [columns, setColumns] = useState([]);
  const [pageSize, setPageSize] = useState(100);

  const getColumnWidth = (colMultiplier) => {
    const colUnitWidth = 50;
    return colUnitWidth * colMultiplier;
  }

  const composeTableColumns = () => {
    return defaultColumns.map(fullCol => {
      const { widthMultiplier, ...col } = fullCol;
      return {
        ...col,
        width: getColumnWidth(widthMultiplier),
      }
    }) || []
  }

  React.useEffect(() => {
    const newColumns = composeTableColumns();
    setColumns(newColumns);
    queryHandler.fetch();
  }, []);

  return (
    <Page
      hideHeader
      fullWidth
    >

      {/* ----------------------------- Filter Section ---------------------------- */}
      <PageSection
        title={"Manage Representatives"}
        direction="row"
        headerSx={{ mt: 1 }}
        onBack={() => navigateTo(routeConfig.dashboard)}
        onBackTooltip={"Back to Dashboard"}

        endActions={
          <Restricted to={[permissions.WRITE_NITROCRETE_REPRESENTATIVES]}>
            <MButton
              sx={{ mr: 1 }}
              startIcon={<AddCircleIcon />}
              variant="outlined"
              collapseXs
              onClick={() => navigateTo(routeConfig.representative_manual_entry)}
              children="Add Representative"
            />
          </Restricted>
        }
      >
      </PageSection>
      <div style={{
        display: 'flex',
        width: "100%",
        overflowX: "auto",
        height: height - constants.APP_BAR_HEIGHT
      }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            loading={!!isLoading}
            rows={entities}
            columns={columns}
            columnBuffer={25}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 25, 50, 100]}
            onPageSizeChange={setPageSize}
          />

        </div>
      </div>
    </Page >
  );
}