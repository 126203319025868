import React from "react";
import PropTypes from "prop-types";
import { usePermission } from "../contexts/PermissionContext";

Restricted.propTypes = {
  to: PropTypes.array,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ])
};

export default function Restricted({ to, children }) {
  const [allowed] = usePermission(to);
  if (allowed) {
    return (<>{children}</>);
  }
  return (<></>);
}