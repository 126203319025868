/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import Page from '../../templates/Page';
import PageSection from '../../templates/PageSection';
import { routeConfig } from '../../../configs';
import { MButton, MDivider } from '../../@material-extend';
import { RepresentativeContext } from '../../../contexts';
import { useNavigateTo } from '../../../helpers';
import PageText from '../../templates/PageText';
import Restricted from '../../Restricted';
import { ButtonGroup } from '@mui/material';
import { permissions } from '../../../configs/permissionConstants';


export default function Representative() {

  const {
    activeEntity,
    setActiveEntity,
    upsertEntity,
  } = useContext(RepresentativeContext);

  const [params, navigateTo] = useNavigateTo();
  const nitrocrete_representative = params.nitrocrete_representative;

  const handleToggleActive = () => {
    let isActive = !activeEntity.nitrocrete_representative_active
    return upsertEntity({ ...activeEntity, nitrocrete_representative_active: isActive}).then(() => {
      setActiveEntity(nitrocrete_representative);
    })
  }

  useEffect(() => {
    setActiveEntity(nitrocrete_representative);
  }, []);

  return (
    <Page
      route={routeConfig.representative_view}
      gap={1}
      onBack={() => navigateTo(routeConfig.representative_table)}
    >
      <PageSection
        title="Details"
        spacing={1}
        collapsible
        omitContainer
      >
        <PageText
          label="Name"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.nitrocrete_representative_description}
        />
        <PageText
          label="ID"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.nitrocrete_representative}
        />
        <PageText
          label="Email"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.nitrocrete_representative_email}
        />
        <PageText
          label="Active?"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.nitrocrete_representative_active ? "True" : "False"}
        />
        <MDivider
          variant='list-middle'
          direction='row'
        />

      </PageSection>

      <PageSection
        title={activeEntity?.nitrocrete_representative_active ? "True" : "False"}
        label='Representative Active'
        endActions={<ButtonGroup sx={{ mr: 2 }}>
          <Restricted to={[permissions.WRITE_NITROCRETE_REPRESENTATIVES]}>
            <MButton
              onClick={handleToggleActive}
              color="primary"
              children="Toggle Active"
              tooltip={"Toggle Active"}
            />
          </Restricted>
        </ButtonGroup>}
        collapsible
      />
    </Page >
  );
}