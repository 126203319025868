import Page from '../../../templates/Page';
import { SupplierContractRateForm } from '../../../forms/SiteForm/SiteSupplierContractRateForm';
import { routeConfig } from '../../../../configs';
import { PageSection } from '../../../templates';
import { useContext } from 'react';
import { CustomerAdminContext } from '../../../../contexts';

export default function AddSupplierContractRate() {
  const { navigateTo } = useContext(CustomerAdminContext);

  return (
    <Page
      route={routeConfig.supplier_contract_add_rate}
      onBack={() => navigateTo(routeConfig.site_view)}
    >
      <PageSection>
        <SupplierContractRateForm />
      </PageSection>
    </Page>
  );
} 