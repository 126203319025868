

const makeRemoveCharactersMatchingRegex = (reg) => (value) => {
    if (!value) {
        return '';
    }
    const stringWithoutCharactersMatchingRegex = value.replace(reg, '');
    return stringWithoutCharactersMatchingRegex;
};

const normalizeNumeric = makeRemoveCharactersMatchingRegex(/[^0-9]/g);
// const numberWithCommas = (x) => {
//     if (x == undefined) {
//         return x;
//     }
//     return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
// };

// const numberRoundedWithCommas = (x) => {
//     if (x == undefined) {
//         return x;
//     }
//     return Number(x)
//         .toFixed(0)
//         .toString()
//         .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
// };


/**
 * # Strings
 * Your one-stop shop for string manipulation.
 */
export const StringParser = {
    regex: {
        white_space: /\s/g,
        letters: /[A-Za-z]+$/,
        letters_upper: /[A-Z]+/,
        letters_lower: /[a-z]+/,
        mongo_id: /^[0-9a-fA-F]{24}$/,
        email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        person_name: /^[A-Za-z]+([ '’‘-][A-Za-z]+)*$/,
        number: /[0-9]+/,
    },
    readingCase: (val) => {
        var firstPass = val.replace(/([A-Z])/g, " $1").replace("_", " ");
        return firstPass.charAt(0).toUpperCase() + firstPass.slice(1);
    },
    normalizeNumeric: makeRemoveCharactersMatchingRegex(/[^0-9]/g),

    normalizePhone: (value) => {

        //null values
        if (!value || value === '0') {
            return '';
        }

        if (value.length > 0 && value[0] === '(') {
            return value;
        }

        let onlyNums = value.replace(/[^\d]/g, '');
        if (onlyNums[0] === '1') {
            // Remove first digit(s) if it is a 1
            onlyNums = onlyNums.replace(/^1+/, '');
        }

        const cleaned = ('' + onlyNums).replace(/\D/g, '')
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3]
        }
        return cleaned;
    },
    normalizeZipCodeUSA: (value) => {
        let zip = '';
        if (value) {
            zip = normalizeNumeric(value);
            if (value.length > 5) {
                zip = zip.substr(0, 5);
            }
        }
        return zip;
    },
    normalizeZipCode: (value) => {
        let zip = '';
        if (value) {
            zip = value;
            if (value.length > 10) {
                zip = zip.substr(0, 10);
            }
        }
        return zip;
    },
    normalizeDollar: (value) => {
        return value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    /**
     * 
     * @param {*} value 
     * @param {{decimalPlaces: number}} opts 
     * @returns 
     */
    formatNumerical: (value, opts) => {
        return Number(value).toFixed(opts?.decimalPlaces || 2);
    },
    formatCurrency: (value, precision = 2) => {
        if (!value) {
            return value;
        }

        const numberValues = value.replace(/[^\d.]/g, '');
        const values = numberValues.split('.');
        if (values.length > 1) {
            return "$" + StringParser.normalizeDollar(values[0]) + '.' + values[1].substr(0, precision);
        }
        return "$" + StringParser.normalizeDollar(values[0]);
    },

    truncateString: (value, maxLength = 80) => {
      if (!value) {
          return value;
      }
      return value.length > maxLength ? 
          `${value.slice(0, maxLength - 3)}...` : value;
  },

}