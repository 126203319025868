/* eslint-disable react-hooks/exhaustive-deps */
import createEntityContext from '../helpers/createEntityContext';

const EntityContext = createEntityContext({
    entityType: "supplier_contract",
    defaultEntity: {
        supplier_contract_uuid: "",
        supplier_contract_description: "",
        supplier_contract_execution_date: "",
        supplier_contract_term_length: null,
        first_fill_date: "",
        account_number: "",
        supplier_contract_number: "",
        supplier_contract_status: "",
        site_uuid: ""
    },
});

export const SupplierContractContext = EntityContext.Context;
export const SupplierContractContextProvider = EntityContext.Provider;
export const useSupplierContractContext = EntityContext.useContext;