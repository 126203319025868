/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from 'react';
import { useForm } from "react-hook-form";
import { MFormSelect, MFormTextField, MForm, MFormDatePicker } from '../../@material-extend';
import { LayoutContext, LookupContext, usePricingFeeContext } from '../../../contexts';
import { dateHelper, useNavigateTo } from '../../../helpers';
import { routeConfig } from '../../../configs';

export function SitePricingFeeForm(props) {
  const lookup = useContext(LookupContext);
  const layout = useContext(LayoutContext);
  const [params, navigateTo] = useNavigateTo();
  const pricingFeeContext = usePricingFeeContext();

  const form = useForm({
    defaultValues: pricingFeeContext.defaultValues,
  });

  const handleValidateForm = (data) => {
    let errorMessage = null;
    if (!data.pricing_fee_description) {
      data.pricing_fee_description = null;
      errorMessage = "Provide a pricing fee description.";
    }
    else if (!data.fee_type) {
      data.fee_type = null;
      errorMessage = "Choose a fee type.";
    }
    else if (!data.pricing_fee) {
      data.pricing_fee = null;
      errorMessage = "Provide a pricing fee amount.";
    }
    else if (!data.pricing_fee_effective_date) {
      data.pricing_fee_effective_date = null;
      errorMessage = "Select a fee effective date.";
    }

    if (errorMessage) {
      layout.handleError(errorMessage);
      return false;
    }
    return true;
  }

  const onSubmit = (data) => {

    const isValidForm = handleValidateForm(data);
    if (!isValidForm) {
      return;
    }

    data.customer_contract_uuid = params.customer_contract_uuid;
    data.pricing_fee_effective_date = dateHelper.formatDate(data.pricing_fee_effective_date);

    pricingFeeContext.upsertEntity(data)
      .then(res => {
        console.log("Saved pricing fee", res);
        navigateTo(routeConfig.site_view);
      }).catch((e) => {
        console.log("Error saved pricing fee", e);
      });

  }

  return (
    <MForm
      form={form}
      onSubmit={onSubmit}
    >
      <MFormTextField
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        minRows={2}
        name="pricing_fee_description"
        label="Pricing Fee Description"
        multiline
        fullWidth
        required
      />
      <MFormSelect
        name="fee_type"
        label="Fee Type"
        onControlEnter={form.handleSubmit(onSubmit)}
        includeAll="-- Unselect --"
        control={form.control}
        options={lookup.values.fee_type}
        GridProps={{
          xs: 12,
          md: 6,
        }}
      />
      <MFormTextField
        name="pricing_fee"
        label="Amount"
        normalize='currency'
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        GridProps={{
          xs: 12,
          md: 6,
        }}
      />
      <MFormDatePicker
        name="pricing_fee_effective_date"
        label="Pricing Fee Effective Date"
        clearable
        required
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        GridProps={{
          xs: 12,
          md: 6,
        }}
      />
    </MForm>
  );
}