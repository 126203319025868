/* eslint-disable react-hooks/exhaustive-deps */
import createEntityContext from '../helpers/createEntityContext';

const EntityContext = createEntityContext({
    entityType: "business_unit_contact",
    defaultEntity: {
        business_unit_contact_email: "",
        business_unit_contact_first_name: "",
        business_unit_contact_last_name: "",
        business_unit_contact_mobile_phone: "",
        business_unit_contact_office_phone: "",
        business_unit_contact_title: "",
        business_unit_contact_uuid: "",
        business_unit_uuid: "",
    },
});

export const BusinessUnitContactContext = EntityContext.Context;
export const BusinessUnitContactContextProvider = EntityContext.Provider;
export const useBusinessUnitContactContext = EntityContext.useContext;
