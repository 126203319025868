/* eslint-disable react-hooks/exhaustive-deps */
import createEntityContext from '../helpers/createEntityContext';

const EntityContext = createEntityContext({
    entityType: "pricing_rate",
    upsertMode: "create",
    defaultEntity: {
        pricing_rate_description: "",
        tank_delivery_rate: null,
        usage_rate: null,
        effective_date: "",
        pricing_model: ""
    },
});

export const PricingRateContext = EntityContext.Context;
export const PricingRateContextProvider = EntityContext.Provider;
export const usePricingRateContext = EntityContext.useContext;