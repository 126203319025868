import { Box, Container, Typography } from '@mui/material';
import Page from '../templates/Page';



export default function ScheduleApp() {
  return (
    <Page title="Schedule">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }} >
          <Typography variant="h4">Schedule App</Typography>
        </Box>
      </Container>
    </Page>
  );
}