/* eslint-disable react-hooks/exhaustive-deps */
import createEntityContext from '../helpers/createEntityContext';

const EntityContext = createEntityContext({
    entityType: "pricing_fee",
    defaultEntity: {
        pricing_fee: null,
        pricing_fee_description: "",
        fee_start_date: "",
        fee_type: "",
        pricing_rate_uuid: "",
        pricing_fee_effective_date: "",
    },
});

export const PricingFeeContext = EntityContext;
export const PricingFeeContextProvider = EntityContext.Provider;
export const usePricingFeeContext = EntityContext.useContext;