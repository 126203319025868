import Page from '../../templates/Page';
import { BusinessUnitContactForm } from '../../forms';
import { useContext } from 'react';
import CustomerAdminContext from '../../../contexts/CustomerAdminContext';
import { routeConfig } from '../../../configs';
import PageSection from '../../templates/PageSection';

export default function UpsertBusinessContact() {
  const { entities, navigateTo } = useContext(CustomerAdminContext);
  const formData = entities.active_business_unit_contact;

  const route = !formData
    ? routeConfig.business_contact_add
    : routeConfig.business_contact_edit;

  return (
    <Page route={route}
      onBack={() => navigateTo(routeConfig.business_view)}
    >
      <PageSection title="" denseY>
        <BusinessUnitContactForm form_data={formData} />
      </PageSection>
    </Page>
  );
}