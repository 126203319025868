import { useContext } from 'react';
import Page from '../../templates/Page';
import { CustomerAdminContext } from '../../../contexts/CustomerAdminContext';
import { routeConfig } from '../../../configs';
import { SupplierForm } from '../../forms/SupplierForm';
import { PageSection } from '../../templates';

export default function AddSupplier() {
  const customerAdminContext = useContext(CustomerAdminContext);
  const navigateToSuppliers = () => {
    customerAdminContext.navigateTo(routeConfig.supplier)
  }

  return (
    <Page
      route={routeConfig.supplier_add}
      onBack={navigateToSuppliers}
    >
      <PageSection>
        <SupplierForm />
      </PageSection>
    </Page>
  );
} 