import React from 'react';
import PageSection from '../../../templates/PageSection';
import {
  MButton,
} from '../../../@material-extend';
import { useNavigateTo } from '../../../../helpers';
import PageText from '../../../templates/PageText';
import { routeConfig } from '../../../../configs';
import Restricted from '../../../Restricted';
import { permissions } from '../../../../configs/permissionConstants';

/**
 * @param {{
 *    isLoading: boolean;
 *    siteView: {};
 * }} props
 * @returns 
 */
export default function SiteBillingInformationSection({ isLoading, siteView }) {
  const [, navigateTo] = useNavigateTo();

  const handleEditSection = () => {
    navigateTo(routeConfig.site_edit_billing_information);
  }

  return (<PageSection
    title="Billing Information"
    omitContainer
    endActions={
      <Restricted to={[permissions.WRITE_BILLING_INFORMATION]}>
        <MButton
          GridProps
          collapseXs
          size="small"
          icon="edit"
          variant="outlined"
          color="primary"
          disabled={isLoading}
          tooltip={{ title: "Billing Information" }}
          onClick={handleEditSection}
          children="Edit Billing Information"
        />
      </Restricted>
    }
    spacing={2}
    collapsible
  >

    <PageText
      label="PO Number Type"
      GridProps={{ xs: 6, }}
      body={siteView?.po_number_type_description || "-"}
    />
    <PageText
      label="Is PO Number Dynamic?"
      GridProps={{ xs: 6, }}
      body={siteView?.po_number_dynamic ? "Yes" : "No"}
    />
    <PageText
      label="PO Number"
      GridProps={{ xs: 12, }}
      body={siteView?.po_number || "-"}
    />

    <PageText
      label="Billing Notes"
      GridProps={{ xs: 12, }}
      body={siteView?.billing_notes || "-"}
    />

  </PageSection>)
}
