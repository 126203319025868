import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

/**
 * Easy way to manage query options for a component.
 * @template T
 * @param {{
 *     initialValues: T;
 *     makeQuery: (queryObject: Record<string, string>, endpointPath?: string) => Promise<any>;
 *     onSuccess: (data: any) => void;
 *     onError: (data: any) => void;
 *     prepareQuery: (queryOptionsObject: T) => Record<string, string>;
 *     prepareQueryParam: (key: keyof T, value: any) => string;
 * }} options list of param keys and their options.
 * @returns {{
 *   values: T,
 *   options: Record<keyof T, any[]>;
 *   createValueChangeHandler: <K extends keyof T>(key: K): (value: T[K]) => void;
 *   createEventChangeHandler: <K extends keyof T>(key: K): (event: any) => void;
 *   setParamOptions: (key: keyof T, options: any[]) => void;
 *   isDirty: boolean;
 *   reset: () => void;
 *   fetch: (endpointPath?: string) => Promise<any>;
 * }}
 */
export function useQueryHandler(options) {
    const { initialValues = {}, makeQuery, onSuccess, onError, prepareQueryParam } = options;
    const [paramValues, setParamValues] = React.useState(initialValues);
    const [cachedParamValues, setCachedParamValues] = React.useState(initialValues);
    const [paramOptions, setParamOptions] = React.useState(getInitialParamOptions(initialValues));
    const { getAccessTokenSilently } = useAuth0();

    /**
     * Creates a value setter that accepts the new value to set.
     * @template {keyof T} K
     * @param {K} key 
     * @param {any[]} options
     * @returns void
     */
    function updateParamOptions(key, options) {
        setParamOptions(prevState => {
            const newState = {
                ...prevState,
                [key]: options,
            }
            return newState;
        });
    }

    async function makeRequest(params = "") {
        if(typeof params === "object"){
            params = "";
        }
        let queryObject = {};
        Object.keys(paramValues).forEach(key => {
            if (paramValues[key] !== undefined && paramValues[key] !== " ") {
                queryObject[key] = prepareQueryParam ? prepareQueryParam(key, paramValues[key]) : paramValues[key];
                if (typeof queryObject[key] == "boolean") {
                  paramValues[key] =  queryObject[key] 
                }

            }
        })
        setCachedParamValues(paramValues);
        const token = await getAccessTokenSilently();

        return new Promise((resolve, reject) => {
            return makeQuery(token, queryObject, params).then((res) => {
                if (onSuccess) onSuccess(res);
                resolve(res);
            }, (err) => {
                if (onError) onError(err);
                reject(err);
            });

        });
    };

    /**
     * Creates a value setter that accepts the new value to set.
     * @template {keyof T} K
     * @param {K} key 
     * @returns {{(value: T[K]): void}}
     */
    function createValueChangeHandler(key) {

        return (value) => {
            setParamValues(prevState => {
                const newState = {
                    ...prevState,
                    [key]: value,
                };
                if (value === undefined) {
                    delete newState[key];
                }
                return newState;
            });
        }
    }
    /**
     * Creates a value setter that accepts an event with `target.value` to set.
     * Creates a value setter that accepts the new value to set.
     * @template {keyof T} K
     * @param {K} key 
     * @returns {{(event: any): void}}
     */
    function createEventChangeHandler(key) {
        return (event) => {
            setParamValues(prevState => {
                const newState = {
                    ...prevState,
                    [key]: event?.target?.value,
                };
                if (event?.target?.value === undefined) {
                    delete newState[key];
                }
                return newState;
            });
        }
    }

    return {
        values: paramValues,
        options: paramOptions,
        createValueChangeHandler,
        createEventChangeHandler,
        setParamOptions: updateParamOptions,
        isDirty: paramValues !== cachedParamValues,
        reset: () => setParamValues(cachedParamValues),
        fetch: makeRequest,
    };
}

const getInitialParamOptions = (initialValues) => {
    const newState = {};
    Object.keys(initialValues || {})?.forEach(key => {
        newState[key] = [];
    });
    return newState
}



/**
 * 
 * @template {Record<string, {defaultValue: string, options?: string[]}>} T
 * @param {T} queryParams
 * @returns {{
 *   values: Record<keyof T, string>;
 *   fetch: (endpointPath?: string) => void;
 *   options: Record<keyof T, string[]>;
 *   isDirty: boolean;
 *   reset: () => void;
 *   createValueChangeHandler: <K extends keyof T, V extends T[K]>(key: K ) => (value: V) => void;
 *   createEventChangeHandler: <K extends keyof T, V extends T[K]>(key: K)=> (event) => void;
 *   setParamOptions: <K extends keyof T, V extends T[K]>(key: K, options: string[]) => void;
 * }}
 */
export function createDefaultQueryHandler(queryParams) {
    const paramKeys = Object.keys(queryParams);
    let values = {};
    let options = {};
    paramKeys.forEach(k => {
        values[k] = queryParams[k].defaultValue;
        options[k] = queryParams[k].options;
    })


    return {
        values: values,
        fetch: () => { },
        options: options,
        isDirty: false,
        reset: () => { },
        /**
        * Set's the param's value from a value
        * @param {"import_status"|"start_date"|"end_date"} key 
        * @returns 
        */
        createValueChangeHandler: (key) => (value) => { alert("not setup for " + key + ": " + value) },
        /**
        * Set's the param's value from an event
        * @param {"import_status"|"start_date"|"end_date"} key 
        * @returns 
        */
        createEventChangeHandler: (key) => (event) => { alert("not setup for " + key + ": " + event.target.value) },
        /**
         * Set's the param's options
         * @param {"import_status"|"start_date"|"end_date"} key 
         * @param {*} options 
         */
        setParamOptions: (key, options) => { alert("not setup for " + key + ": " + options) },
    }
}