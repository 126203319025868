import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { alpha, styled } from "@mui/material/styles";
import { AppBar, Box, IconButton, LinearProgress, Toolbar } from '@mui/material';
import { MHidden } from '../../@material-extend';
import { useContext } from 'react';
import { LayoutContext } from '../../../contexts';
import { routeConfig } from '../../../configs';

const CustomAppBar = styled(AppBar)(({ theme, constants }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(2px)',
  WebkitBackdropFilter: 'blur(2px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.12),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${constants.DRAWER_WIDTH}px)`
  }
}));
export default function NavbarLayout() {
  const { openSidebar, constants, pageState, isLoading } = useContext(LayoutContext)

  return (
    <CustomAppBar constants={constants} >
      {isLoading && <LinearProgress color='secondary' sx={{
        // mx: 0, mb: 0, mt: .5,
        m: 0,

      }} />}
      <Toolbar
        sx={theme => ({
          minHeight: constants.APPBAR_MOBILE,
          padding: !!pageState?.dense ? undefined : theme.spacing(0, 1),
        })}
      >
        <MHidden width="lgUp">
          <IconButton onClick={openSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>
        <div id={routeConfig.portal_navbar_left.id}></div>
        <Box id={routeConfig.portal_navbar_center.id} sx={{ flexGrow: 1 }} />
        <div id={routeConfig.portal_navbar_right.id}></div>
      </Toolbar>

    </CustomAppBar>
  );
}