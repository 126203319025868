/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { routeConfig } from '../../../configs';
import { MForm, MFormTextField, MFormCheckbox } from '../../@material-extend';
import { useSiteContext } from '../../../contexts';
import { useNavigateTo } from '../../../helpers';
import { Backdrop, CircularProgress, Grid } from '@mui/material';
import Restricted from '../../Restricted';
import { permissions } from '../../../configs/permissionConstants';
import { useSiteView } from '../../../helpers/useViews';


const defaultValues = {
  tank_tricock_capacity: null,
  access_instructions: null,
  delivery_restricted: false,
}

export function SiteTankAccessForm(props) {
  const { form_data } = props
  const siteContext = useSiteContext();
  const [params, navigateTo] = useNavigateTo();
  const [showBackdrop, setShowBackdrop] = useState(false);
  const siteView = useSiteView();

  const form = useForm({
    defaultValues: defaultValues,
  });

  function onSubmit(data) {

    setShowBackdrop(true);
    siteContext.upsertEntity(data)
      .then(newSiteSuccess => {
        console.log("Saved Site", newSiteSuccess);
        navigateTo(routeConfig.site_view);
        setShowBackdrop(false);
      }).catch(() => {
        setShowBackdrop(false);
      });
  }

  useEffect(() => {
    if (params.business_unit_uuid && !props.form_data?.business_unit_uuid) {
      form.setValue("business_unit_uuid", params.business_unit_uuid);
    }
  }, [form, params.business_unit_uuid, props.form_data]);

  useEffect(() => {
    if (!!form_data) {
      form.reset(props.form_data)
    }
  }, [props.form_data])

  return (<MForm
    defaultValues={defaultValues}
    onSubmit={onSubmit}
    form={form}
  >
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={showBackdrop}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    {/* ----------------------------- Tank Supplier ----------------------------- */}
    <Restricted to={[permissions.WRITE_TANK_ACCESS]}>
      <MFormTextField
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        name="access_instructions"
        label="Access Instructions"
        multiline
        minRows={2}
        GridProps={{ xs: 12, md: 7, sx: { pr: 2 } }}
      />

      <MFormCheckbox
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        name="delivery_restricted"
        row
        label="Is Access Restricted?"
        GridProps={{
          xs: 12,
          md: 4,
        }}
      />
      <MFormTextField
      name="tank_tricock_capacity"
      label={'Tricock Capacity (' + siteView?.unit_of_measure + ')'}
      onControlEnter={form.handleSubmit(onSubmit)}
      type="number"
      control={form.control}
      GridProps={{
        xs: 12,
        md: 4,
      }}
    />
      <Grid item xs={12} sx={{ p: 2 }} />
    </Restricted>

  </MForm >

  );
}