import * as React from 'react';
import { Autocomplete, FormHelperText, Grid, InputLabel, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';

MAutoComplete.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  includeAll: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  GridProps: PropTypes.object,
}

export default function MAutoComplete({ options = [], label, includeAll, sx, inputProps, GridProps = { xs: 12 }, value, onChange, isOptionEqualToValue, variant, ...props }) {

  const handleChange = (event, newValue) => {
    if (onChange) {         
        const item = options.find(o => o.id === newValue?.site_uuid && o.label === newValue?.name);
        let site_uuid =  (item === undefined) ? String(' ') :  String(item.site_uuid);
        onChange(String(site_uuid));
    }
  };

  const defaultValue = typeof includeAll === "string" ? '' : " ";
  const items = includeAll ? [{ value: defaultValue, label: typeof includeAll === "string" ? includeAll : 'All' }, ...options] : options || [];

  return <Grid item {...GridProps} >
    <FormControl
      sx={{ width: '100%', mt: 1, }}
    >

      <InputLabel id={`select-label-${label}`}>{label}</InputLabel>
      <Autocomplete 
        options={items}
        defaultValue={'All'}
        sx={{
          ...sx,
 
          // mb: .5,
          backgroundColor: "#fff",
          borderRadius: 1,
        }}
        fullWidth
        renderInput={(params) => <TextField {...params} label={"Select Site"} variant="outlined" />}
        onChange={handleChange}
        required
        disableClearable
        isOptionEqualToValue={isOptionEqualToValue}
      />

      {props?.helperText && <FormHelperText>{props?.helperText}</FormHelperText>}
    </FormControl>
  </Grid>;


}