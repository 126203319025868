import { ListItemButton, ListItemText, ListItemAvatar, Avatar, Box, Skeleton } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import React, { useContext } from "react";
import { LayoutContext } from "../../contexts";
import { MDisplay } from "./MDisplay";
import { MLink } from "./MLink";


export const MListItem = React.memo((props) => {

    const muiProps = MDisplay.mui(props)
    const textProps = MDisplay.unwrap(props);

    const { isLoading } = useContext(LayoutContext);




    let ItemAvatar = !props.avatar
        ? undefined
        : React.isValidElement(props.avatar) && typeof props.avatar !== "string"
            ? <Box sx={{ mr: 2 }}>{props.avatar}</Box>//<ListItemAvatar sx={{ flexGrow: 0 }}>{props.avatar}</ListItemAvatar>
            : (<ListItemAvatar sx={{ flexGrow: 0 }}>
                <Avatar>
                    {props.avatar}
                </Avatar>
            </ListItemAvatar>);



    const ItemText = <ListItemText id={`li-${props.id}-text`}
        primary={textProps.title && isLoading ? <Skeleton /> : textProps.title}
        secondary={textProps.subtitle && isLoading ? <Skeleton /> : textProps.subtitle}
    />;

    const ItemColumns = <>{ItemAvatar}{ItemText}{props.icon}</>;
    const renderLink = React.useMemo(
        () => React.forwardRef((itemProps, ref) => <MLink to={props.path} inputRef={ref} {...itemProps} />),
        [props.path],
    );

    const ItemContent = props.path
        ? <ListItemButton
            selected={props.isSelected}
            component={renderLink}
            children={ItemColumns}
            sx={{
                width: 1,
                '&.Mui-selected': {
                    backgroundColor: 'red',
                },
                ...props.sx,
            }}
        />
        : <ListItemButton selected={props.isSelected} {...muiProps}>{ItemColumns}</ListItemButton>;

    const handleClick = () => {
        if (props.onClick)
            props.onClick(props);
    }

    return (<ListItem
        key={props.id}
        {...muiProps}
        disablePadding
        onClick={props.onClick && handleClick}
        sx={{
            width: 1,
            ...props.sx,
        }}
        children={ItemContent}
    />
    );
});