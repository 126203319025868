import Page from '../../templates/Page';
import { RegionalCompanyForm } from '../../forms';
import { useContext } from 'react';
import CustomerAdminContext from '../../../contexts/CustomerAdminContext';
import BackIcon from '@mui/icons-material/ArrowBackIos';
import { IconButton } from '@mui/material';
import { routeConfig } from '../../../configs';
import PageSection from '../../templates/PageSection';

export default function AddRegionalCompany(props) {
  const { navigateTo } = useContext(CustomerAdminContext);

  return (
    <Page route={routeConfig.region_add}
      startActions={<>
        <IconButton
          onClick={() => navigateTo(routeConfig.parent_view)}
          children={<BackIcon />}
        />
      </>}
    >
      <PageSection title="Enter Regional Company Details">
        <RegionalCompanyForm />
      </PageSection>
    </Page>
  );
}