import Page from '../../templates/Page';
import { routeConfig } from '../../../configs';
import PageSection from '../../templates/PageSection';
import { useNavigateTo } from '../../../helpers';
import { RepresentativeForm } from '../../forms/RepresentativeForm';

export default function AddRepresentative(props) {
  const [, navigateTo] = useNavigateTo();

  return (
    <Page
      route={routeConfig.representative_manual_entry}
      gap={1}
      onBack={() => navigateTo(routeConfig.representative_table)}
    >
      <PageSection>
        <RepresentativeForm />
      </PageSection>
    </Page>
  );
}