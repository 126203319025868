/* eslint-disable react-hooks/exhaustive-deps */
import createEntityContext from '../helpers/createEntityContext';

const EntityContext = createEntityContext({
    entityType: "nitrocrete_representative",
    defaultEntity: {
      nitrocrete_representative: "",
      nitrocrete_representative_active: "",
      nitrocrete_representative_description: "",
      nitrocrete_representative_email: "",
  },
});

export const RepresentativeContext = EntityContext.Context;
export const RepresentativeContextProvider = EntityContext.Provider;
export const useRepresentativeContext = EntityContext.useContext;