/* eslint-disable react-hooks/exhaustive-deps */
import createEntityContext from '../helpers/createEntityContext';

const EntityContext = createEntityContext({
    entityType: "regional_company",
    defaultEntity: {
        regional_company_uuid: "",
        regional_company_name: "",
        parent_company_uuid: "",
    },
});

export const RegionalCompanyContext = EntityContext.Context;
export const RegionalCompanyContextProvider = EntityContext.Provider;
export const useRegionalCompanyContext = EntityContext.useContext;