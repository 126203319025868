import { useEffect, useContext, useState } from 'react';
import { useForm } from "react-hook-form";
import CustomerAdminContext from '../../contexts/CustomerAdminContext';
import { entityConfig } from '../../configs';
import { MForm, MFormDatePicker, MDivider, MFormSelect } from '../@material-extend';
import PropTypes from 'prop-types';
import { LayoutContext, useLookupContext } from '../../contexts';
import { useAuth0 } from '@auth0/auth0-react';
import { dateHelper } from '../../helpers';
import { usePermission } from "../../contexts/PermissionContext";
import { permissions } from '../../configs/permissionConstants';



const defaultValues = {
  nitrocrete_representative: "",
  effective_date: "",
  representative_assignment_uuid: "",
  site_uuid: "",
}

RepresentativeAssignmentForm.propTypes = {
  form_data: PropTypes.object,
}

export function RepresentativeAssignmentForm(props) {
  const { params, createEntity, updateEntity } = useContext(CustomerAdminContext);
  const lookup = useLookupContext();
  const { handleError } = useContext(LayoutContext);
  const [representatives, setRepresentatives] = useState([]);
  const form = useForm({
    defaultValues: defaultValues,
  });
  const { getAccessTokenSilently } = useAuth0();

  const [canWriteRepresentativeSales] = usePermission([permissions.WRITE_REPRESENTATIVE_SALES])
  const [canWriteRepresentativeOps] = usePermission([permissions.WRITE_REPRESENTATIVE_OPS])

  const [representitiveTypes, setRepresentativeTypes] = useState([]);

  async function onSubmit(data) {
    if (!data.effective_date) {
      handleError("Select an effective date for the representative.");
      props.onSaveComplete(undefined);
      return;
    }
    data.effective_date = dateHelper.formatDate(data.effective_date);
    const token = await getAccessTokenSilently();
    if (!props.form_data) {
      const { representative_assignment_uuid, ...createPayload } = data
      return createEntity(token, entityConfig.type.representative_assignment, createPayload)
    } else {
      return updateEntity(token, entityConfig.type.representative_assignment, props.form_data?.representative_assignment_uuid, data);
    }
  }

  useEffect(() => {
    if (!lookup.values.nitrocrete_representative.length) {
      return;
    }
    let reps = lookup.values.nitrocrete_representative.filter(item => item.nitrocrete_representative_active);
    setRepresentatives(reps)
  }, [lookup.values.nitrocrete_representative])

  useEffect(() => {
    if (params.site_uuid && !props.form_data?.site_uuid) {
      form.setValue("site_uuid", params.site_uuid);
    }
  }, [form, params.site_uuid, props.form_data])

  useEffect(() => {
    if (props.form_data) {
      if (props.form_data.effective_date) {
        props.form_data.effective_date = new Date(props.form_data.effective_date.replace(/-/g, '/'));
      }
      form.reset(props.form_data)
    }
  }, [props.form_data, form])

  useEffect(() => {
    if (!lookup.values.representative_type.length) {
      return;
    }
    let allowed_types = ["OTHER"];
    if (canWriteRepresentativeOps) {
      allowed_types.push("FIELD_OPS_MGR");
    };
    if (canWriteRepresentativeSales) {
      allowed_types.push("SALES_REP");
    };
    const representative_types = lookup.values.representative_type.filter(item => allowed_types.includes(item.representative_type)); 
    setRepresentativeTypes(representative_types);
  }, [lookup.values.representative_type, canWriteRepresentativeOps, canWriteRepresentativeSales])

  return (<MForm
    form={form}
    defaultValues={defaultValues}
    onSubmit={onSubmit}
  >
    <MDivider
      above={{ title: "Representative Assignment" }}
      direction="row"
      variant="form"
    /> 
    <MFormSelect
      autoFocus
      onControlEnter={form.handleSubmit(onSubmit)}
      control={form.control}
      GridProps={{
        xs: 6,
      }}
      name="representative_type"
      includeAll="-- Unselect --"
      options={representitiveTypes}
      label="Representative Type"
      required
    />
    <MFormSelect
      autoFocus
      onControlEnter={form.handleSubmit(onSubmit)}
      control={form.control}
      GridProps={{
        xs: 12,
      }}
      name="nitrocrete_representative"
      includeAll="-- Unselect --"
      options={representatives}
      label="Nitrocrete Representative"
      required
    />
    <MFormDatePicker
        name="effective_date"
        label="Effective Date"
        clearable
        required
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        GridProps={{
          xs: 12,
          md: 6,
        }}
      />
  </MForm>

  );
}