import React from 'react';
import { Paper, Grid, Typography, alpha, IconButton } from '@mui/material';
import { useTheme } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';

/**
 * # Display error message with dismiss button.
 * @param {*} props are `errorMessage` and `removeError`
 * @returns 
 */
export default function ErrorMessage(props) {
    const theme = useTheme();
    if (!props.message || !props.removeError) {
        throw new Error("Invalid proptypes for <ErrorMessage/>:" + props)
    }
    return (
        <Grid container
            component={Paper}
            variant="outlined"
            justifyContent="space-between"
            alignItems="center"
            sx={{
                bgcolor: alpha(theme.palette.error.main, 0.4),
                py: 1,
                px: 2,
                pl: 3,
                mb: 1,
            }}
        >
            <Grid item xs={11}>
                <Typography
                    children={props.message}
                />
            </Grid>
            <Grid item component={IconButton} onClick={props.removeError} xs={1}>
                <CloseIcon />
            </Grid>
        </Grid>);
}
