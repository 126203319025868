/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { MListItemCustom, MList } from '../@material-extend';
import { entityConfig } from '../../configs';
import PropTypes from 'prop-types';
import { Paper, Grid } from '@mui/material';
import MoreMenu from '../MoreMenu';
import { useLookupContext } from '../../contexts';
import { StringParser } from '../../helpers';
import Restricted from '../Restricted';
import { permissions } from '../../configs/permissionConstants';

ContactList.propTypes = {
  contacts: PropTypes.array,
  type: PropTypes.oneOf([entityConfig.type.business_unit_contact, entityConfig.type.site_contact]).isRequired,
  onSelect: PropTypes.func,
  transferList: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    hidden: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    subtitle: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.element,
  })),
}

export function ContactList(props) {
  const lookup = useLookupContext();
  const [items, setItems] = useState([{
    id: "add",
    sx: { p: 2 },
    left: { title: "No contacts", },
  }]);

  useEffect(() => {
    if (!props.contacts?.length || !props.type || !lookup.values.site_contact_type.length) {
      setItems([{
        id: "add",
        sx: { p: 2 },
        left: { title: "No contacts", },
      }]);
      return;
    }
    const newItems = props.contacts.map(contact => {

      const contactType = props.type === "site_contact"
        ? `Type: ${lookup.values.site_contact_type.find(x => x.value === contact.site_contact_type)?.label}`
        : undefined;

      return {
        ...contact,
        id: contact[`${props.type}_uuid`],
        left: {
          title: `${contact[`${props.type}_first_name`]} ${contact[`${props.type}_last_name`]}`,
          subtitles: [
            contactType,
            (contact[`${props.type}_email`] && `Email: ${contact[`${props.type}_email`]}`),
            (contact[`${props.type}_mobile_phone`] && `Mobile: ${StringParser.normalizePhone(contact[`${props.type}_mobile_phone`])}`),
            (contact[`${props.type}_office_phone`] && `Office: ${StringParser.normalizePhone(contact[`${props.type}_office_phone`])}`),
            (contact[`${props.type}_note`] && `Note: ${StringParser.truncateString(contact[`${props.type}_note`], 80)}`),

          ]
        },
        right: contact.site_contact_type === 'LN_DELIVERY' ? props.options && {
          children:
            <Grid container justifyContent="flex-end" alignItems={"center"}>
              <Restricted to={[permissions.WRITE_SUPPLIER_CONTACTS]}>
                <MoreMenu
                  data={contact}
                  GridProps={{ item: true, xs: 2 }}
                  options={props.options}
                />
              </Restricted>
            </Grid>
        }
        : props.options && {
          children:
            <Grid container justifyContent="flex-end" alignItems={"center"}>
              <Restricted to={[permissions.WRITE_SITE_CONTACTS, permissions.WRITE_SUPPLIER_CONTACTS]}>
                <MoreMenu
                  data={contact}
                  GridProps={{ item: true, xs: 2 }}
                  options={props.options}
                />
              </Restricted>
            </Grid>
        }
      }
    });
    if (newItems !== items) {

      setItems(newItems.length === 0 ? [{
        id: "add",
        sx: { p: 2 },
        left: { title: "No contacts", },
      }] : newItems);
    }

  }, [props.contacts, props.type, props.transferList, lookup.values.site_contact_type]);

  return (

    <MList
      dense
      divider
      items={items}
      component={item => <MListItemCustom
        component={Paper}
        elevation={3}
        GridProps={{ sx: { mb: 2, p: 2 } }}
        {...item}
        transferList={props.transferList}
        sx={{
          py: 1,
          px: 2,
          ...item.sx,
          bgcolor: "theme.background.paper",
          color: "theme.text.primary",
        }}
      />}
    />
  );
}