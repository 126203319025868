import { useEffect, useContext } from 'react';
import { useForm } from "react-hook-form";
import CustomerAdminContext from '../../contexts/CustomerAdminContext';
import { MForm, MFormTextField, MDivider } from '../@material-extend';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';

const defaultValues = {
  business_unit_contact_email: "",
  business_unit_contact_first_name: "",
  business_unit_contact_last_name: "",
  business_unit_contact_mobile_phone: "",
  business_unit_contact_office_phone: "",
  business_unit_contact_title: "",
  business_unit_contact_uuid: "",
  business_unit_uuid: "",
}

BusinessUnitContactForm.propTypes = {
  form_data: PropTypes.object,
}

export function BusinessUnitContactForm(props) {
  const { params, createEntity, updateEntity } = useContext(CustomerAdminContext)
  const form = useForm({
    defaultValues: defaultValues,
  });
  const { getAccessTokenSilently } = useAuth0();

  async function onSubmit(data) {
    const token = await getAccessTokenSilently();
    if (!props.form_data) {
      return createEntity(token, data)
    } else {
      return updateEntity(token, data);
    }
  }

  useEffect(() => {
    if (params.business_unit_uuid && !props.form_data?.business_unit_uuid) {
      form.setValue("business_unit_uuid", params.business_unit_uuid);
    }
  }, [form, params.business_unit_uuid, props.form_data])

  useEffect(() => {
    if (props.form_data) {
      form.reset(props.form_data)
    }
  }, [props.form_data, form])

  return (<MForm
    form={form}
    defaultValues={defaultValues}
    onSubmit={onSubmit}
  >

    <MDivider
      above={{ title: "Contact Name" }}
      variant="form"
      direction="row"
    />
    <MFormTextField
      GridProps={{
        xs: 12,
      }}
      autoFocus
      onControlEnter={form.handleSubmit(onSubmit)}
      control={form.control}
      name="business_unit_contact_first_name"
      label="First Name"
      required
    />
    <MFormTextField
      GridProps={{
        xs: 12,
      }}
      onControlEnter={form.handleSubmit(onSubmit)}
      control={form.control}
      name="business_unit_contact_last_name"
      label="Last Name"
      required
    />
    <MFormTextField
      control={form.control}
      onControlEnter={form.handleSubmit(onSubmit)}
      GridProps={{
        xs: 6,
        md: 3,
      }}
      name="business_unit_contact_title"
      label="Title"
      fullWidth
    />

    {/* ----------------------------- Contact Fields ----------------------------- */}
    <MDivider
      above={{ title: "Communication Methods" }}
      variant="form"
      direction="row"
    />
    <MFormTextField
      GridProps={{
        xs: 12,
      }}
      onControlEnter={form.handleSubmit(onSubmit)}
      control={form.control}
      name="business_unit_contact_email"
      label="Email Address"
      fullWidth
    />
    <MFormTextField
      GridProps={{
        xs: 12,
      }}
      onControlEnter={form.handleSubmit(onSubmit)}
      control={form.control}
      name="business_unit_contact_mobile_phone"
      label="Mobile Phone"
      normalize='phone'
      fullWidth
    />
    <MFormTextField
      control={form.control}
      onControlEnter={form.handleSubmit(onSubmit)}
      GridProps={{
        xs: 12,
      }}
      name="business_unit_contact_office_phone"
      label="Office Phone"
      normalize='phone'
      fullWidth
    />



  </MForm>

  );
}