/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from 'react';
import { useForm } from "react-hook-form";
import { MFormTextField, MFormSelect, MFormDatePicker, MForm } from '../../@material-extend';
import { LayoutContext, LookupContext, useSupplierContractRateContext } from '../../../contexts';
import { dateHelper, useNavigateTo } from '../../../helpers';
import MDividerSection from '../../@material-extend/MDividerSection';
import { routeConfig } from '../../../configs';

export function SupplierContractRateForm(props) {
  const lookup = useContext(LookupContext);
  const supplierContractRateContext = useSupplierContractRateContext();
  const layout = useContext(LayoutContext);
  const [params, navigateTo] = useNavigateTo();

  const form = useForm({
    defaultValues: supplierContractRateContext.defaultValues,
  });

  const handleValidateForm = (data) => {
    let errorMessage = null;
    if (!data.supplier_contract_rate_type) {
      data.supplier_contract_rate_type = null;
      errorMessage = "Choose a contract rate type.";
    }
    else if (!data.supplier_contract_rate_description) {
      data.supplier_contract_rate_description = null;
      errorMessage = "Provide a contract rate description.";
    }
    else if (!data.supplier_contract_charge_type) {
      data.supplier_contract_charge_type = null;
      errorMessage = "Choose a contract charge type.";
    }
    else if (!data.supplier_contract_rate) {
      data.supplier_contract_rate = null;
      errorMessage = "Provide a contract rate.";
    }
    else if (!data.unit_of_measure) {
      data.unit_of_measure = null;
      errorMessage = "Choose a unit of measure.";
    }
    else if (!data.supplier_contract_rate_effective_date) {
      data.supplier_contract_rate_effective_date = null;
      errorMessage = "Select a contract rate effective date.";
    }

    if (errorMessage) {
      layout.handleError(errorMessage);
      return false;
    }
    return true;
  }

  const onSubmit = (data) => {

    const isValidForm = handleValidateForm(data);
    if (!isValidForm) {
      return;
    }
    data.supplier_contract_uuid = params.supplier_contract_uuid;
    data.supplier_contract_rate_effective_date = dateHelper.formatDate(data.supplier_contract_rate_effective_date);

    supplierContractRateContext.upsertEntity(data)
      .then(res => {
        console.log("Saved supplier contract rate", res);
        navigateTo(routeConfig.site_view);
      }).catch((e) => {
        console.log("Error saved supplier contract rate", e);
      });

  }

  return (<>
    <MForm
      form={form}
      onSubmit={onSubmit}
    >
      <MDividerSection
        position="form"
        above={{ title: "Supplier Contract Rate" }}
      >
        <MFormTextField
          onControlEnter={form.handleSubmit(onSubmit)}
          control={form.control}
          minRows={2}
          name="supplier_contract_rate_description"
          label="Supplier Contract Rate Description"
          multiline
          fullWidth
          required
        />
        <MFormSelect
          name="supplier_contract_rate_type"
          label="Rate Type"
          onControlEnter={form.handleSubmit(onSubmit)}
          includeAll="-- Unselect --"
          control={form.control}
          options={lookup.values.supplier_contract_rate_type}
          GridProps={{
            xs: 12,
            md: 6,
          }}
        />
        <MFormSelect
          name="supplier_contract_charge_type"
          label="Charge Type"
          onControlEnter={form.handleSubmit(onSubmit)}
          includeAll="-- Unselect --"
          control={form.control}
          options={lookup.values.supplier_contract_charge_type}
          GridProps={{
            xs: 12,
            md: 6,
          }}
        />
        <MFormTextField
          name="supplier_contract_rate"
          label="Contract Rate"
          normalize='currency'
          onControlEnter={form.handleSubmit(onSubmit)}
          control={form.control}
          GridProps={{
            xs: 12,
            md: 6,
          }}
        />
                       <MFormSelect
          name="unit_of_measure"
          label="Unit of Measure"
          onControlEnter={form.handleSubmit(onSubmit)}
          includeAll="-- Unselect --"
          control={form.control}
          options={lookup.values.unit_of_measure}
          GridProps={{
            xs: 12,
            md: 6,
          }}
        />
        <MFormDatePicker
          name="supplier_contract_rate_effective_date"
          label="Rate Effective Date"
          clearable
          required
          onControlEnter={form.handleSubmit(onSubmit)}
          control={form.control}
          GridProps={{
            xs: 12,
            md: 6,
          }}
        />
      </MDividerSection>
    </MForm>
  </>
  );
}