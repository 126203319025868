/* eslint-disable react-hooks/exhaustive-deps */
import createEntityContext from '../helpers/createEntityContext';
export const SiteDefaultValues = {
    site_uuid: "",
    business_unit_uuid: "",

    // About Fields
    site_name: "",
    erp_site_id: "",
    channel: "",
    site_description: "",

    // Location Fields
    site_address1: "",
    site_address2: "",
    site_city: "",
    state_code: "",
    country_code: "",
    site_postal_code: "",
    territory: "",
    timezone_name: "",
    site_year: "",
    language_used: "",
    site_msa_city: "",
    msa_state_code: "",
    msa_country_code: "",
    site_latitude: "",
    site_longitude: "",

    // Status Fields
    site_status: "",
    system_status: "",
    tank_status: "",

    currency_code: "",
    po_number_type: "",

    site_status_update_by: "",

}
const EntityContext = createEntityContext({
    entityType: "site",
    defaultEntity: SiteDefaultValues
});

export const SiteContext = EntityContext;

export const SiteContextProvider = EntityContext.Provider;
export const useSiteContext = EntityContext.useContext;