/* eslint-disable react-hooks/exhaustive-deps */
import Page from '../../templates/Page';
import {
  SiteGeneralForm,
  SiteTankDetailsForm,
  SitePlantDetailsForm,
  SiteTankAccessForm,
  SiteBillingInformationForm
} from '../../forms';
import { routeConfig } from '../../../configs';
import PageSection from '../../templates/PageSection';
import { useNavigateTo } from '../../../helpers';
import { useSiteContext } from '../../../contexts';
import { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

const EditRoutes = [
  routeConfig.site_edit,
  routeConfig.site_edit_tank_details,
  routeConfig.site_edit_plant_details,
  routeConfig.site_edit_billing_information,
]

export default function EditBusinessSite() {
  const siteContext = useSiteContext();

  const [params, navigateTo] = useNavigateTo();
  const [pageTitle, setPageTitle] = useState("Update Site");
  const location = useLocation();


  useEffect(() => {
    siteContext.setActiveEntity(params.site_uuid);
  }, [params.site_uuid]);

  useEffect(() => {
    const newPath = EditRoutes.find(route => location.pathname.includes(route.path)) || routeConfig.site_edit;
    setPageTitle(newPath.title);
  }, [location]);


  return (
    <Page route={routeConfig.site_edit}
      title={"Edit Site"}
      tabTitle={pageTitle}
      onBack={() => navigateTo(routeConfig.site_view)}
    >
      <PageSection title={pageTitle}>
        <Routes>
          <Route index element={<SiteGeneralForm form_data={siteContext.activeEntity} siteContext={siteContext} />} />
          <Route path={routeConfig.site_edit_tank_details.path} element={<SiteTankDetailsForm form_data={siteContext.activeEntity} />} />
          <Route path={routeConfig.site_edit_plant_details.path} element={<SitePlantDetailsForm form_data={siteContext.activeEntity} />} />
          <Route path={routeConfig.site_edit_tank_access.path} element={<SiteTankAccessForm form_data={siteContext.activeEntity} />} />
          <Route path={routeConfig.site_edit_billing_information.path} element={<SiteBillingInformationForm form_data={siteContext.activeEntity} />} />
        </Routes>
      </PageSection>
    </Page>
  );
}