import { Grid, Skeleton, Typography } from '@mui/material';
import PropTypes from "prop-types";
import { useContext } from 'react';
import { LayoutContext } from '../../contexts';
/**
 * 
 * @param {{
 *  showLoading: boolean;
 *  isLoading?: boolean;
 *  label: string;
 *  labelSx: {};
 *  labelVariant: string;
 *  title: string;
 *  titleSx: {};
 *  titleVariant: string;
 *  body: string;
 *  bodySx: {};
 *  bodyVariant: string;
 * }} props 
 * @returns 
 */
function PageText(props) {

    const layoutContext = useContext(LayoutContext);

    
    const isLoading = props.isLoading !== undefined ? props.isLoading : layoutContext.isLoading;

    // const isLoading = props.isLoading;

    return <Grid
        item
        container
        direction="column"
        spacing={0}
        {...props.GridProps}
    >
        {props.label && <Typography
            children={isLoading ? <Skeleton /> : props.label}
            align={props.align}
            sx={{ color: "#696969", ...props.sx, ...props.labelSx }}
            variant={props.labelVariant || "caption"}
        />}
        {props.title && <Typography
            children={isLoading ? <Skeleton /> : props.title}
            align={props.align}
            sx={{ color: "text.primary", ...props.sx, ...props.titleSx }}
            variant={props.titleVariant || "h4"}
        />}
        {props.body && (typeof props.body === "string" || typeof props.body === "number") ? <Typography
            children={isLoading ? <Skeleton /> : props.body}
            align={props.align}
            sx={{ color: "text.primary", ...props.sx, ...props.bodySx }}
            variant={props.bodyVariant || "body2"}
        /> : props.body}

    </Grid>
}

PageText.propTypes = {
    sx: PropTypes.object,
    label: PropTypes.string,
    labelSx: PropTypes.object,
    title: PropTypes.string,
    align: PropTypes.oneOf(['inherit', 'left', 'center', 'right', 'justify']),
    titleSx: PropTypes.object,
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
    bodySx: PropTypes.object,
    GridProps: PropTypes.object,
}

export default PageText;