import Page from '../../templates/Page';
import { routeConfig } from '../../../configs';
import PageSection from '../../templates/PageSection';
import { useNavigateTo } from '../../../helpers';
import { UsageEventForm } from '../../forms/UsageEventForm';

export default function AddUsageEvent(props) {
  const [, navigateTo] = useNavigateTo();

  return (
    <Page
      route={routeConfig.usage_manual_entry}
      gap={1}
      onBack={() => navigateTo(routeConfig.usage_table)}
    >
      <PageSection>
        <UsageEventForm />
      </PageSection>
    </Page>
  );
}