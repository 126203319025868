import { styled } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import NavbarLayout from "./NavbarLayout";
import SidebarLayout from "./SidebarLayout";
import { useContext } from 'react';
import { LayoutContext } from '../../../contexts';
import { CustomerAdminContextProvider } from '../../../contexts/CustomerAdminContext';

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

// -----------------

export default function MainLayout() {
  const { constants } = useContext(LayoutContext);


  return (
    <CustomerAdminContextProvider>
      <RootStyle>
        <NavbarLayout />
        <SidebarLayout />
        <div style={{
          flexGrow: 1,
          overflow: 'auto',
          minHeight: '100%',
          paddingTop: constants.APP_BAR_HEIGHT,
          marginBottom: 100,
        }}>
          <Outlet />
        </div>
      </RootStyle >
    </CustomerAdminContextProvider>
  );
};


