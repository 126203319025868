import Page from '../../templates/Page';
import CustomerAdminContext from '../../../contexts/CustomerAdminContext';
import DeleteIcon from '@mui/icons-material/Delete';
import PageSection from '../../templates/PageSection';
import PageText from '../../templates/PageText';
import { routeConfig, entityConfig } from '../../../configs';
import { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { BusinessSiteList } from '../../lists';
import { MButton, MDivider, MIconButton, MBreadcrumbs } from '../../@material-extend';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BusinessUnitContactList from '../../lists/BusinessUnitContactList';
import MoreMenu from '../../MoreMenu';
import { useAuth0 } from '@auth0/auth0-react';
import Restricted from '../../Restricted';
import { permissions } from '../../../configs/permissionConstants';
import { confirmDialog } from '../../../components/ConfirmDialog';
import { useNavigateTo } from '../../../helpers';

export default function BusinessUnit() {
  const { getAccessTokenSilently } = useAuth0();
  const { entities, deleteEntity, getChildUuids, navigateTo, setActiveEntity } = useContext(CustomerAdminContext);
  const [params] = useNavigateTo();

  const [routeTitle, setRouteTitle] = useState("Loading");
  const [childUuids, setChildUuids] = useState([]);

  const handleAddSite = () => {
    navigateTo(routeConfig.site_add);
  }
  const handleAddContact = () => {
    navigateTo(routeConfig.business_contact_add);
  }

  useEffect(() => {
    if (entities.active_business) {
      setRouteTitle(entities.active_business.business_unit_name);
      const childIds = getChildUuids(entities.active_business);
      setChildUuids(childIds);
    }
    else if (params.business_unit_uuid && !entities.active_business) {
      setActiveEntity(entityConfig.type.business_unit, params.business_unit_uuid)
    }
    else {
      setRouteTitle("Loading...");
    }
  }, [entities.active_business, getChildUuids, params.business_unit_uuid, setActiveEntity]);

  const handleDelete = async () => {
    const token = await getAccessTokenSilently();
    deleteEntity(token, entityConfig.type.business_unit, entities.active_business?.business_unit_uuid).then(() => {
      navigateTo(routeConfig.region_view);
    })
  }

  return (
    <Page
      route={routeConfig.business_view}
      title={routeTitle}
      collapsible
      showPageNav
      subtitle={<MBreadcrumbs
        items={[
          { label: "Parent Company", onClick: () => navigateTo(routeConfig.parent_view) },
          { label: "Regional Company", onClick: () => navigateTo(routeConfig.region_view) },
          { label: "Business Unit", onClick: () => navigateTo(routeConfig.business_view), disabled: true },
        ]}
      />}
      gap={2}
      subtitleEndActions={
        <Restricted to={[permissions.WRITE_SITE_HIERARCHY]}>
          <MoreMenu
            color="primary"
            options={[
              {
                label: 'Delete Business Unit',
                icon: <DeleteIcon />,
                onClick: () => {
                  confirmDialog("Do you want to delete the business unit?", () => handleDelete())
                }
              },
            ]}
          />
        </Restricted>}
    >
      {/* ----------------------------- Details Section ---------------------------- */}
      <PageSection title="Details"
        collapsible
        endActions={
          <Restricted to={[permissions.WRITE_SITE_HIERARCHY]}>
            <MIconButton
              size="small"
              onClick={() => navigateTo(routeConfig.business_edit)}
              icon="edit"
              color="primary"
            />
          </Restricted>}

      >
        <PageText
          label="Business Unit Name"
          body={entities.active_business?.business_unit_name}
        />
      </PageSection >

      {/* ----------------------------- Hierarchy Section ---------------------------- */}
      < PageSection
        title="Hierarchy"
        direction="row"
        justifyContent="flex-start"
        collapsible
        endActions={
          <Restricted to={[permissions.WRITE_SITE_HIERARCHY]}>
            < MButton
              startIcon={< AddCircleIcon />}
              size="small"
              variant="outlined"
              onClick={handleAddSite}
              children="Add Site"
            />
          </Restricted>}
      >
        <Grid item xs={12} md={4} xl={3} sx={{ px: .5 }}>
          <BusinessSiteList
            filterItems={(i) => childUuids.some(uuid => uuid === i.id)}
          />
        </Grid>
      </PageSection >

      {/* ----------------------------- Contacts Section ---------------------------- */}
      <PageSection
        title="Contacts"
        justifyContent='center'
        direction="row"
        collapsible
        endActions={
          <Restricted to={[permissions.WRITE_SITE_CONTACTS, permissions.WRITE_SUPPLIER_CONTACTS]}>
            <MButton
              startIcon={<AddCircleIcon />}
              variant="outlined"
              size="small"
              onClick={handleAddContact}
              children="Add Contact"
            />
          </Restricted>
        }
      >
        <Grid
          item
          xs={12}
          md={7}
          container
          direction="column"
        >
          <MDivider above={{ title: "Business Unit Contacts" }} sx={{ mb: 1 }} />
          <BusinessUnitContactList />
        </Grid>
      </PageSection >

    </Page >
  );
}