import { fetchWrapper } from '../helpers/fetchWrapper';

export const operationsCommentService =  {
  create
};

const baseUrl = `${process.env.REACT_APP_SERVER_URL}/api/operations_comment`;

function create(token, params) {
  return fetchWrapper.post(token, baseUrl, params);
}
