import React, { useEffect, useState } from 'react';
import { MList, MListItemCustom } from '../@material-extend';
import { Paper, Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import { useLookupContext } from '../../contexts';
import MoreMenu from '../MoreMenu';
import { StringParser } from '../../helpers';
import Restricted from '../Restricted';
import { permissions } from '../../configs/permissionConstants';
import { confirmDialog } from '../ConfirmDialog';

PricingFeeList.propTypes = {
  items: PropTypes.array,
  handleDeleteFee: PropTypes.func,
}

function PricingFeeList(props) {
  const [pricingFees, setPricingFees] = useState([])
  const lookup = useLookupContext();

  useEffect(() => {
    if (!lookup.values.fee_type.length) {
      return;
    }
    if (props.items) {
      const pricingFees = props.items;

      const newPricingFees =
        pricingFees.map(fee => {
          const displayFee = {
            ...fee,
            fee_type: lookup.byValue("fee_type", fee?.fee_type)?.label || "-",
            effective_date: fee?.pricing_fee_effective_date || "-",
            pricing_fee: StringParser.formatCurrency(fee?.pricing_fee) || "-",

          }
          return {
            id: displayFee.id,
            left: {
              title: displayFee.pricing_fee_description,
              subtitles: [
                (`Fee Type: ${displayFee.fee_type}`),
                (`Fee Amount: ${displayFee.pricing_fee}`),
                (`Effective Date: ${displayFee.effective_date}`),
              ]
            },
            right: {
              children: <Grid container justifyContent="flex-end" alignItems={"center"}>
                <Restricted to={[permissions.WRITE_CUSTOMER_CONTRACT_PRICING_FEES]}>
                  <MoreMenu
                    data={{ uuid: displayFee.pricing_fee_uuid }}
                    GridProps={{ item: true, xs: 2 }}
                    options={[
                      {
                        label: 'Delete Pricing Fee',
                        icon: <DeleteIcon sx={{ color: "error.main" }} />,
                        onClick: (data) => {
                          confirmDialog("You should NOT BE DELETING this data in the case of a new contract, rate increase, nor any other amendment.  NITROvision maintains a historical record of data.  The “effective date” is used to recognized the active contract data.  Do you want to delete the pricing fee?", () => props.handleDeleteFee(data))
                        }
                      },
                    ]}
                  />
                </Restricted>
              </Grid>
            }
          }
        });

      if (newPricingFees !== pricingFees) {
        setPricingFees(newPricingFees.length === 0 ? [{
          id: "add",
          sx: { p: 2 },
          left: { title: "  No pricing fees", },
        }] : newPricingFees);
      }
    }
  }, [props, lookup]);

  return (

    <MList
      dense
      items={pricingFees.length > 0 ? pricingFees : [{
        disableButton: true,
        sx: { p: 2 },
        left: { title: "  No pricing fees", },
      }]}
      component={item => <MListItemCustom
        component={Paper}
        elevation={5}
        GridProps={{ sx: { mb: 2, p: 2 } }}
        {...item}
        sx={{
          py: 1,
          px: 2,
          ...item.sx,
          bgcolor: "theme.background.paper",
          color: "theme.text.primary",
        }}
      />
      }
    />
  );
}

export default PricingFeeList;