/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import Page from '../../templates/Page';
import { routeConfig } from '../../../configs';
import { SupplierForm } from '../../forms/SupplierForm';
import { PageSection } from '../../templates';
import { useNavigateTo } from '../../../helpers';
import { SupplierContext } from '../../../contexts';


export default function EditSupplierPage() {
  const supplierContext = useContext(SupplierContext);
  const [params, navigateTo] = useNavigateTo();

  const navigateToSuppliers = () => {
    navigateTo(routeConfig.supplier)
  }

  useEffect(() => {
    supplierContext.setActiveEntity(params.supplier_uuid);
  }, []);

  return (
    <Page
      route={routeConfig.supplier_edit}
      onBack={navigateToSuppliers}
    >
      <PageSection>
        <SupplierForm form_data={supplierContext.activeEntity} />
      </PageSection>
    </Page>
  );
} 