/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from 'react';
import { useForm } from "react-hook-form";
import { MFormTextField, MFormDatePicker, MForm } from '../../@material-extend';
import { LayoutContext, useTakeOrPayTermContext } from '../../../contexts';
import { dateHelper, useNavigateTo } from '../../../helpers';
import MDividerSection from '../../@material-extend/MDividerSection';
import { routeConfig } from '../../../configs';

export function SupplierTakeOrPayTermForm(props) {
  const takeOrPayTermContext = useTakeOrPayTermContext();
  const layout = useContext(LayoutContext);
  const [params, navigateTo] = useNavigateTo();

  const form = useForm({
    defaultValues: takeOrPayTermContext.defaultValues,
  });

  const handleValidateForm = (data) => {
    let errorMessage = null;
    if (!data.take_or_pay_term_description) {
      data.take_or_pay_term_description = null;
      errorMessage = "Provide a term description.";
    }
    else if (!data.minimum_quantity) {
      data.minimum_quantity = null;
      errorMessage = "Provide a minimum quantity.";
    }
    else if (!data.period) {
      data.period = null;
      errorMessage = "Provide a period in months.";
    }
    else if (!data.take_or_pay_term_effective_date) {
      data.take_or_pay_term_effective_date = null;
      errorMessage = "Select a term effective date.";
    }

    if (errorMessage) {
      layout.handleError(errorMessage);
      return false;
    }
    return true;
  }

  const onSubmit = (data) => {

    const isValidForm = handleValidateForm(data);
    if (!isValidForm) {
      return;
    }

    data.supplier_contract_uuid = params.supplier_contract_uuid;
    data.take_or_pay_term_effective_date = dateHelper.formatDate(data.take_or_pay_term_effective_date);

    takeOrPayTermContext.upsertEntity(data)
      .then(res => {
        console.log("Saved take or pay term", res);
        navigateTo(routeConfig.site_view);
      }).catch((e) => {
        console.log("Error saving take or pay term", e);
      });

  }

  return (<>
    <MForm
      form={form}
      onSubmit={onSubmit}
    >
      <MDividerSection
        position="form"
        above={{ title: "Take or Pay Term" }}
      >
        <MFormTextField
          onControlEnter={form.handleSubmit(onSubmit)}
          control={form.control}
          minRows={2}
          name="take_or_pay_term_description"
          label="Term Description"
          multiline
          fullWidth
          required
        />
        <MFormTextField
          name="minimum_quantity"
          label="Minimum Quantity"
          type="number"
          required
          onControlEnter={form.handleSubmit(onSubmit)}
          control={form.control}
          GridProps={{
            xs: 12,
            md: 6,
          }}
        />
        <MFormTextField
          name="period"
          label="Period (months))"
          type="number"
          required
          onControlEnter={form.handleSubmit(onSubmit)}
          control={form.control}
          GridProps={{
            xs: 12,
            md: 6,
          }}
        />
        <MFormDatePicker
          name="take_or_pay_term_effective_date"
          label="Term Effective Date"
          clearable
          required
          onControlEnter={form.handleSubmit(onSubmit)}
          control={form.control}
          GridProps={{
            xs: 12,
            md: 6,
          }}
        />
      </MDividerSection>
    </MForm>
  </>
  );
}