import Page from '../../../templates/Page';
import { SitePricingRateForm } from '../../../forms/SiteForm/SitePricingRateForm';
import { routeConfig } from '../../../../configs';
import { PageSection } from '../../../templates';
import { useContext } from 'react';
import { CustomerAdminContext } from '../../../../contexts';

export default function AddPricingMinimum() {
  const { navigateTo } = useContext(CustomerAdminContext);

  return (
    <Page
      route={routeConfig.customer_contract_add_rate}
      onBack={() => navigateTo(routeConfig.site_view)}
    >
      <PageSection>
        <SitePricingRateForm />
      </PageSection>
    </Page>
  );
} 