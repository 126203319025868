import Page from '../../templates/Page';
import { BusinessUnitForm } from '../../forms';
import { routeConfig } from '../../../configs';
import PageSection from '../../templates/PageSection';
import { useNavigateTo } from '../../../helpers';

export default function AddBusinessUnit(props) {
  const [, navigateTo] = useNavigateTo();
  return (
    <Page route={routeConfig.business_add}
      onBack={() => navigateTo(routeConfig.region_view)}
    >
      <PageSection title="Enter Business Unit Details">
        <BusinessUnitForm />
      </PageSection>
    </Page>
  );
}