/* eslint-disable react-hooks/exhaustive-deps */
import createEntityContext from '../helpers/createEntityContext';

const EntityContext = createEntityContext({
    entityType: "usage_event",
    defaultEntity: {
        site_id: 12345,
        usage_quantity: 0,
        usage_pulses: 0,
        tank_level_begin: 0,
        tank_level_end: 0,
        usage_begin_timestamp: "",
        usage_end_timestamp: "",
        usage_duration: 0,
        starting_pressure: 0,
        target_pressure: 0,
        level_selection: 0,
        target_upm: 0,
        actual_upm: 0,
        target_duration: 0,
        quantity_required: 0,
        milliseconds_per_pulse: 0,
        quantity_per_pulse: 0,
        dispensing_type: 2
    },
});

export const UsageEventContext = EntityContext.Context;
export const UsageEventContextProvider = EntityContext.Provider;
export const useUsageEventContext = EntityContext.useContext;