import React, { useContext, useEffect, useState } from 'react';
import CustomerAdminContext from '../../../contexts/CustomerAdminContext';
import { routeConfig } from '../../../configs';
import { ACascader } from '../../@ant';

const CascaderRouteParams = [
    routeConfig.parent_view.path,
    routeConfig.region_view.path,
    routeConfig.business_view.path,
    routeConfig.site_view.path
].join("");
/**
 * Customer admin cascade navigation
 * @returns 
 */
export default function NavCascader() {

    const { params, entities, navigateTo } = useContext(CustomerAdminContext);
    const [options, setOptions] = useState([]);
    const [cascaderValue, setCascaderValue] = useState(null);

    // Set selected cascader nav value in state when URL params change
    useEffect(() => {
        const newValue = Object.keys(params)
            .reduce((result, paramKey) => {
                const paramValue = params[paramKey];
                const isValidUrlParam = CascaderRouteParams.includes(paramKey);
                if (isValidUrlParam && paramValue) {
                    return result.concat(paramValue);
                }
                return result;
            }, []);
        setCascaderValue(newValue.length > 0 ? newValue.splice(0, 4) : null);
    }, [params])

    // Set nested cascader options in state when entities change
    useEffect(() => {
        const { parent, region, business, site } = entities;
        const newOptions = parent.map(p => ({
            value: p.parent_company_uuid,
            label: p.parent_company_name,
            children: region
                .filter(r => r.parent_company_uuid === p.parent_company_uuid)
                .map(r => ({
                    value: r.regional_company_uuid,
                    label: r.regional_company_name,
                    children: business
                        .filter(b => b.regional_company_uuid === r.regional_company_uuid)
                        .map(b => ({
                            value: b.business_unit_uuid,
                            label: b.business_unit_name,
                            children: site
                                .filter(s => s.business_unit_uuid === b.business_unit_uuid)
                                .map(s => ({
                                    value: s.site_uuid,
                                    label: s.site_name,
                                }))
                        }))
                }))
        }));
        if (newOptions !== options) {
            setOptions(newOptions);
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entities]);

    // navigate to route based on which params are available
    const handleCascaderChange = (value) => {
        // do not navigate upon reset, instead just clear the nav value 
        if (!value) {
            setCascaderValue(null);
            return;
        }

        const params = {
            parent_company_uuid: value[0],
            regional_company_uuid: value[1],
            business_unit_uuid: value[2],
            site_uuid: value[3],
        }
        if (!!params.site_uuid) {
            navigateTo(routeConfig.site_view, params);

        } else if (!!params.business_unit_uuid) {
            navigateTo(routeConfig.business_view, params);

        } else if (!!params.regional_company_uuid) {
            navigateTo(routeConfig.region_view, params);

        } else if (!!params.parent_company_uuid) {
            navigateTo(routeConfig.parent_view, params);
        }
    }

    function displayRender(label, selectedOptions) {
        const selected = label.pop();
        return selected;
    }

    return (<ACascader
        xs={10}
        md={6}
        lg={3}
        xl={2}
        value={cascaderValue}
        displayRender={displayRender}
        options={options}
        onChange={handleCascaderChange}
        placeholder={"Search for a parent company, regional company, business unit, or site..."}
    />);
}
