import { useForm } from "react-hook-form";
import { useEffect } from 'react';
import { routeConfig } from '../../configs';
import { MForm, MFormTextField } from '../@material-extend';
import { useNavigateTo } from "../../helpers";
import { useBusinessUnitContext } from "../../contexts";


const defaultValues = {
  business_unit_name: "",
  regional_company_uuid: "",
};

export function BusinessUnitForm(props) {
  const businessUnitContext = useBusinessUnitContext();
  const [params, navigateTo] = useNavigateTo();

  const form = useForm({
    defaultValues: defaultValues
  });

  function onSubmit(data) {
    data.business_unit_name = data.business_unit_name.trim()
    businessUnitContext.upsertEntity(data).then(res => {
      navigateTo(routeConfig.business_view, { business_unit_uuid: res.id });
    });
  }

  useEffect(() => {
    if (params.regional_company_uuid && !props.form_data?.regional_company_uuid) {
      form.setValue("regional_company_uuid", params.regional_company_uuid);
    }
  }, [params.regional_company_uuid, form, props.form_data])

  useEffect(() => {
    if (props.form_data) {
      form.reset(props.form_data)
    }
  }, [props.form_data, form])

  return (<MForm
    form={form}
    defaultValues={defaultValues}
    onSubmit={onSubmit}
  >
    <MFormTextField
      control={form.control}
      autoFocus
      onControlEnter={form.handleSubmit(onSubmit)}
      name="business_unit_name"
      label="Business Unit Name"
      fullWidth
      required
    />
  </MForm>

  );
}