import { useEffect, useContext } from 'react';
import { useForm } from "react-hook-form";
import { routeConfig } from '../../configs';
import { RepresentativeContext, LayoutContext } from '../../contexts';
import { useNavigateTo } from '../../helpers';
import { MForm, MFormTextField } from '../@material-extend';

const defaultValues = {
  nitrocrete_representative_active: true,
  nitrocrete_representative_description: null,
  nitrocrete_representative_email: null,
}

export function RepresentativeForm(props) {
  const { createEntity } = useContext(RepresentativeContext);
  const layout = useContext(LayoutContext);
  const [, navigateTo] = useNavigateTo();


  const form = useForm({
    defaultValues: defaultValues,
  });

  const handleValidateForm = (data) => {
    let errorMessage = null;

    if (!data.nitrocrete_representative_description) {
      errorMessage = "Provide a representative name"
    }
    else if (!data.nitrocrete_representative_email) {
      errorMessage = "Provide a representative email"
    }
    if (errorMessage) {
      layout.handleError(errorMessage);
      return false;
    }
    return true;
  }

  function onSubmit(data) {

    const isValidForm = handleValidateForm(data);
    if (!isValidForm) {
      return;
    }

    // representative active is set to true in form defaultValues

    if (data.nitrocrete_representative_description) {
      data.nitrocrete_representative_description = data.nitrocrete_representative_description.trim();
      data.nitrocrete_representative_email = data.nitrocrete_representative_email.trim();
      data.nitrocrete_representative = data.nitrocrete_representative_description.toUpperCase().replace(/ /g, "_");;
    }

    return createEntity(data).then(() => {
      navigateTo(routeConfig.representative_table);
    })
  }

  useEffect(() => {
    if (props.form_data) {
      form.reset(props.form_data)
    }
  }, [props.form_data, form])

  return (
    <MForm
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      form={form}
    >

      {/* ----------------------------- Detail Fields ----------------------------- */}
      <MFormTextField
        name="nitrocrete_representative_description"
        label="Representative Name (first last)"
        GridProps={{ item: true, xs: 6 }}
        required
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
      />
      <MFormTextField
        name="nitrocrete_representative_email"
        label="Representative Email"
        GridProps={{ item: true, xs: 6 }}
        required
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
      />
    </MForm>
  );
}